import { QueryClient } from "@tanstack/react-query";

import {
  accountsQueryKey,
  myAccountsQueryKey,
} from "services/account/constants";

export const updateAccounts = async ({
  queryClient,
}: {
  queryClient: QueryClient;
}): Promise<void> => {
  await Promise.all([
    queryClient.cancelQueries({ queryKey: [myAccountsQueryKey] }),
    queryClient.cancelQueries({ queryKey: [accountsQueryKey] }),
  ]);
  await Promise.all([
    queryClient.invalidateQueries({ queryKey: [myAccountsQueryKey] }),
    queryClient.invalidateQueries({ queryKey: [accountsQueryKey] }),
  ]);
};
