import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useDatabasesNames } from "services/databases/useDatabasesNames";

import { Tree } from "components/Tree/Tree";
import { TreeNodeType } from "components/Tree/types";
import { useViewDefinitions } from "components/ViewDefinitions/useViewDefinitions";

import { useCreateDatabase } from "../CreateMenu/useCreateDatabase";
import { EmptySearch } from "../EmptyState/EmptySearch";
import { DatabaseIcon, EmptyState } from "../EmptyState/EmptyState";
import { Panel } from "../Panel";
import { Search, useSearchHandlers } from "../Search";
import {
  ColumnLabel,
  DatabaseLabel,
  DefaultLabel,
  IndexLabel,
  InformationSchemaTableLabel,
  TableLabel,
  ViewLabel,
} from "./Labels";
import { useChildren } from "./useChildren";
import { useDeleteDatabase } from "./useDeleteDatabase";

import styles from "../styles.module.scss";

const EmptyLabel = () => {
  return <div className={styles.empty}>No objects found.</div>;
};

const labelMap = {
  database: DatabaseLabel,
  table: TableLabel,
  view: ViewLabel,
  external_table: TableLabel,

  information_schema_table: InformationSchemaTableLabel,
  index: IndexLabel,
  column: ColumnLabel,
  information_schema_column: ColumnLabel,
  external_table_column: ColumnLabel,
  view_column: ColumnLabel,
  empty: EmptyLabel,
};

export const DatabasesPanel = React.memo(() => {
  const { t } = useTranslation();
  const { createDatabaseMarkup, openCreateDatabase } = useCreateDatabase();
  const databases = useDatabasesNames();
  const { onChange, search } = useSearchHandlers();
  const { openViewDefinitions, viewDefinitionsMarkup } = useViewDefinitions();
  const { deleteDatabase, deleteDatabaseMarkup } = useDeleteDatabase();

  const data = useMemo(
    () =>
      databases.map(database => {
        const { catalogName } = database;
        return {
          type: "database",
          label: catalogName,
          value: catalogName,
          payload: database,
          children: [
            {
              label: "public",
              value: `${catalogName}_public`,
              children: [
                {
                  type: "tables",
                  label: "Tables",
                  value: `${catalogName}_tables`,
                  databaseName: catalogName,
                  children: [],
                },
                {
                  type: "external_tables",
                  label: "External tables",
                  databaseName: catalogName,
                  value: `${catalogName}_external`,
                  children: [],
                },
                {
                  type: "views",
                  label: "Views",
                  databaseName: catalogName,
                  value: `${catalogName}_views`,
                  children: [],
                },
              ],
            },
            {
              type: "information_schema",
              label: "information_schema",
              value: `${catalogName}_information_schema`,
              databaseName: catalogName,
              children: [
                {
                  type: "information_schema_views",
                  label: "Views",
                  databaseName: catalogName,
                  value: `${catalogName}_information_schema_views`,
                  children: [],
                },
              ],
            },
          ],
        };
      }),
    [databases]
  );

  const getChildren = useChildren();

  const actions = useMemo(() => {
    return {
      openViewDefinitions,
      deleteDatabase,
    };
  }, [openViewDefinitions, deleteDatabase]);

  const renderLabel = useCallback(
    (node: TreeNodeType, loading?: boolean) => {
      const { type } = node;
      const Label = labelMap[type as keyof typeof labelMap] || DefaultLabel;
      return (
        <Label
          node={node}
          loading={loading}
          actions={actions}
        />
      );
    },
    [actions]
  );

  if (!databases.length) {
    return (
      <>
        <Search
          onChange={onChange}
          value={search}
          testId="databases-search-input"
        />
        <EmptyState
          icon={<DatabaseIcon />}
          title={t("workspace.databases.empty")}
          subtitle={t("workspace.databases.empty_subtitle")}
          button={t("workspace.databases.empty_button")}
          onClick={openCreateDatabase}
          testId="databases-empty-list"
        />
        {createDatabaseMarkup}
      </>
    );
  }

  return (
    <>
      <Search
        onChange={onChange}
        value={search}
        testId="databases-search-input"
      />
      <Panel testId="databases-list-panel">
        <Tree
          data={data}
          getChildren={getChildren}
          renderLabel={renderLabel}
          searchKeyword={search}
          nodeClassName={styles.node}
          treeClassName={styles.tree}
          emptySearchState={
            <EmptySearch
              search={search}
              testId="empty-databases-search"
            />
          }
          emptyState={
            <EmptyState
              icon={<DatabaseIcon />}
              title={t("workspace.database.empty")}
              subtitle={t("workspace.database.empty_subtitle")}
              button={t("workspace.database.empty_button")}
              onClick={openCreateDatabase}
              testId="databases-empty-list"
            />
          }
        />
        {viewDefinitionsMarkup}
        {deleteDatabaseMarkup}
      </Panel>
    </>
  );
});
