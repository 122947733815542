import classNames from "classnames";
import moment from "moment";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import AutoSizer from "react-virtualized-auto-sizer";
import { ListChildComponentProps, VariableSizeList } from "react-window";
import { Formatter } from "utils/helpers/Format";

import { FileTree } from "services/fileObjects/getFilesByPath";

import { ReactComponent as ArrowDown } from "assets/icons/v2/ArrowDown.svg";
import { ReactComponent as ArrowUp } from "assets/icons/v2/ArrowUp.svg";

import Spinner from "components/Spinner";
import Tooltip from "components/Tooltip";
import { highlightText } from "components/helpers";

import { SUPPORTED_EXTENSIONS } from "../Steps/constants";
import { SupportedExtensions } from "../types";
import { ColumnAction } from "./ColumnAction";
import { sortItems } from "./helpers";
import { SortValues } from "./types";

import styles from "./styles.module.scss";

type Props = {
  items: FileTree[];
  selectedItems: Set<string>;
  loadingItems: Set<string>;
  handleOpenItem: (path: string) => void;
  toggleSelectItem: (path: string) => void;
  searchQuery?: string;
};

type HeaderProps = {
  onHeaderCellClick?: (newSort: SortValues) => void;
  sort?: SortValues;
};

const Header = (props: HeaderProps) => {
  const { onHeaderCellClick, sort } = props;
  const { t } = useTranslation();
  return (
    <div className={styles.header}>
      <div />
      <div
        className={classNames(styles.column__name, {
          [styles.clickable]: !!onHeaderCellClick,
        })}
        onClick={() => {
          if (onHeaderCellClick) {
            if (sort === SortValues.nameAsc) {
              return onHeaderCellClick(SortValues.nameDesc);
            }
            onHeaderCellClick(SortValues.nameAsc);
          }
        }}
      >
        <div className={styles.header_content}>
          {t("fileTable.header.name")}
          {sort === SortValues.nameAsc && <ArrowUp />}
          {sort === SortValues.nameDesc && <ArrowDown />}
        </div>
      </div>
      <div
        className={classNames(styles.column__type, {
          [styles.clickable]: !!onHeaderCellClick,
        })}
        onClick={() => {
          if (onHeaderCellClick) {
            if (sort === SortValues.typeAsc) {
              return onHeaderCellClick(SortValues.typeDesc);
            }
            onHeaderCellClick(SortValues.typeAsc);
          }
        }}
      >
        <div className={styles.header_content}>
          {t("fileTable.header.type")}
          {sort === SortValues.typeAsc && <ArrowUp />}
          {sort === SortValues.typeDesc && <ArrowDown />}
        </div>
      </div>
      <div
        className={classNames(styles.column__size, {
          [styles.clickable]: !!onHeaderCellClick,
        })}
        onClick={() => {
          if (onHeaderCellClick) {
            if (sort === SortValues.sizeAsc) {
              return onHeaderCellClick(SortValues.sizeDesc);
            }
            onHeaderCellClick(SortValues.sizeAsc);
          }
        }}
      >
        <div className={styles.header_content}>
          {t("fileTable.header.size")}
          {sort === SortValues.sizeAsc && <ArrowUp />}
          {sort === SortValues.sizeDesc && <ArrowDown />}
        </div>
      </div>
      <div
        className={classNames(styles.column__modified, {
          [styles.clickable]: !!onHeaderCellClick,
        })}
        onClick={() => {
          if (onHeaderCellClick) {
            if (sort === SortValues.modifiedAsc) {
              return onHeaderCellClick(SortValues.modifiedDesc);
            }
            onHeaderCellClick(SortValues.modifiedAsc);
          }
        }}
      >
        <div className={styles.header_content}>
          {t("fileTable.header.modified")}
          {sort === SortValues.modifiedAsc && <ArrowUp />}
          {sort === SortValues.modifiedDesc && <ArrowDown />}
        </div>
      </div>
    </div>
  );
};

const itemSize = () => 32;

export const FileTable = (props: Props) => {
  const {
    items,
    selectedItems,
    loadingItems,
    handleOpenItem,
    toggleSelectItem,
    searchQuery,
  } = props;
  const { t } = useTranslation();

  const [sort, setSort] = useState<SortValues>(SortValues.noSort);

  const sortedItems = sort ? sortItems(items, sort) : items;

  const onHeaderSort = useCallback(
    (newSort: SortValues) => {
      setSort(newSort);
    },
    [setSort]
  );

  const handleClick = (item: FileTree) => () => {
    const { type, objectName, objectType } = item;
    const isLoading = loadingItems.has(objectName);
    if (isLoading) {
      return;
    }
    if (objectType === "folder") {
      handleOpenItem(item.objectName);
    }
    if (type === "parent") {
      handleOpenItem(item.objectName);
    }

    if (item.objectType === "file") {
      toggleSelectItem(objectName);
    }
  };

  const renderNode = ({ index, style }: ListChildComponentProps) => {
    const item = sortedItems[index];
    const {
      url,
      name,
      type,
      objectName,
      objectType,
      objectBytes,
      lastModified,
    } = item;
    const isSelected = selectedItems.has(objectName);
    const isLoading = loadingItems.has(objectName);
    const isFolder = objectType === "folder" || type === "parent";

    const modifiedFormated = isFolder
      ? null
      : String(moment(lastModified).utc().format("YYYY/MM/DD"));

    const sizeFormated = isFolder
      ? null
      : Formatter.bytesFormatter(objectBytes!).formattedString;

    const isSupported =
      item.objectType === "file" &&
      SUPPORTED_EXTENSIONS.includes(item.extension as SupportedExtensions);

    return (
      <div
        className={classNames(styles.row, {
          [styles.folder]: isFolder,
        })}
        style={style}
        key={objectName}
        onClick={handleClick(item)}
        data-testid={`file-row-${index}`}
      >
        <ColumnAction
          item={item}
          index={index}
          toggleSelectItem={toggleSelectItem}
          isSelected={isSelected}
        />
        <Tooltip
          arrow={false}
          enterDelay={500}
          enterNextDelay={500}
          placement="top"
          classes={{ tooltip: styles.tooltip }}
          disableHoverListener={isFolder || !isSupported}
          title={
            <div className={styles.tooltip}>
              <div className={styles.tooltip__item}>
                <span className={styles.tooltip__key}>Path:</span>{" "}
                <span className={styles.tooltip__value}>{url}</span>
              </div>
              <div className={styles.tooltip__item}>
                <span className={styles.tooltip__key}>Size:</span>{" "}
                <span className={styles.tooltip__value}>{sizeFormated}</span>
              </div>
            </div>
          }
        >
          <div
            className={styles.column__name}
            data-testid={`file-row-name-tooltip-${index}`}
          >
            <div
              className={classNames(styles.object_name, {
                [styles.is_loading]: isLoading,
              })}
            >
              {highlightText({
                searchWord: searchQuery,
                textToHighlight: name,
              })}
            </div>
            {isLoading && (
              <span className={styles.column__action}>
                <div className={styles.expandWrapper}>
                  <div className={styles.loading}>
                    <Spinner size={12} />
                  </div>
                </div>
              </span>
            )}
          </div>
        </Tooltip>
        <div className={styles.column__type}>{objectType}</div>
        <div className={styles.column__size}>{sizeFormated}</div>
        <div className={styles.column__modified}>{modifiedFormated}</div>
      </div>
    );
  };

  if (!sortedItems.length) {
    return (
      <div className={styles.wrapper} data-testid="wizard-file-table">
        <Header />
        <div className={styles.emptyState}>
          <div className={styles.emptyState__header}>
            {t("wizard.select_data.empty_table_title")}
          </div>
          <div className={styles.emptyState__body}>
            {t("wizard.select_data.empty_table_subtitle")}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.wrapper} data-testid="wizard-file-table">
      <Header
        sort={sort}
        onHeaderCellClick={onHeaderSort}
      />
      <div className={styles.list}>
        <AutoSizer
          style={{ height: "auto" }}
          disableWidth
        >
          {({ height = 300 }) => (
            <VariableSizeList
              height={height}
              width="auto"
              itemSize={itemSize}
              itemCount={sortedItems.length}
              itemData={sortedItems}
            >
              {renderNode}
            </VariableSizeList>
          )}
        </AutoSizer>
      </div>
    </div>
  );
};
