import classNames from "classnames";
import _orderBy from "lodash/orderBy";
import React from "react";

import { Role } from "services/rbac/types";

import { useViewActions } from "pages/govern/Roles/useViewActions";

import { useViewDefinitions } from "components/ViewDefinitions/useViewDefinitions";

import { EmptySearch } from "../EmptyState/EmptySearch";
import { RoleRecord } from "./RoleRecord";
import { OrderBy, OrderDirection } from "./types";
import { useDeleteRole } from "./useDeleteRole";

import styles from "./styles.module.scss";

type Props = {
  items: Role[];
  order: {
    orderBy: OrderBy;
    orderDirection: OrderDirection;
  };
  openViewDefinitions: ReturnType<
    typeof useViewDefinitions
  >["openViewDefinitions"];
  handleView: ReturnType<typeof useViewActions>["handleView"];
  search: string;
};

export const RoleList = (props: Props) => {
  const { search, items, order, openViewDefinitions, handleView } = props;
  const { deleteRole, deleteRoleMarkup } = useDeleteRole();

  const filtered = items.filter(item => {
    return item.roleName.toLowerCase().includes(search.toLowerCase());
  });

  const sorted = _orderBy(
    filtered,
    [
      (role: Role) => {
        if (order.orderBy === OrderBy.created) {
          return role.created;
        }

        return role.roleName.toLowerCase();
      },
    ],
    [order.orderDirection]
  );

  return (
    <div
      className={classNames(styles.section, {
        [styles.flex]: !!search && !sorted.length,
      })}
    >
      {sorted.map(role => {
        return (
          <RoleRecord
            role={role}
            key={role.roleName}
            openViewDefinitions={openViewDefinitions}
            deleteRole={deleteRole}
            handleView={handleView}
          />
        );
      })}
      {!!search && !sorted.length && (
        <EmptySearch
          search={search}
          testId="empty-roles-search"
        />
      )}
      {deleteRoleMarkup}
    </div>
  );
};
