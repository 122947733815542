import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "@tanstack/react-query";

import { createWorkspaceEngine } from "services/engines/createWorkspaceEngine";
import { useWorkspaceEngines } from "services/engines/useWorkspaceEngines";
import { workspaceEnginesQueryKey } from "services/engines/constants";

import ContextMenuDivider from "components/ContextMenu/ContextMenuDivider";
import ContextMenuItem from "components/ContextMenu/ContextMenuItem";
import {
  CreateEngine,
  Fields,
  engineCost,
  initialEngine,
  schema,
} from "components/CreateEngine/CreateEngine";
import { OutlinedSelect } from "components/OutlinedSelect/OutlinedSelect";
import { StatusMessageType } from "components/StatusMessageQueue/StatusMessageQueueProvider";
import useStatusMessageQueue from "components/StatusMessageQueue/hooks/useStatusMessageQueue";
import { Step } from "components/Wizard/Step/Step";

import { CREATE_NEW_ENGINE, SelectEngineStep } from "../types";

import styles from "./styles.module.scss";

type Props = {
  onPrevStep: () => void;
  onClose: () => void;
  onSubmit: (data: {
    engine: Fields | { engineName: string };
    type?: string;
  }) => void;
  initialData: SelectEngineStep;
  activeStepIndex: number;
  updateEngine: (value: string) => void;
};

export const SelectEngine = (props: Props) => {
  const {
    onClose,
    onSubmit,
    initialData,
    onPrevStep,
    activeStepIndex,
    updateEngine,
  } = props;
  const { t } = useTranslation();
  const { data: engines = [] } = useWorkspaceEngines();
  const { putStatusMessage } = useStatusMessageQueue();
  const queryClient = useQueryClient();
  const [engine, setEngine] = useState<string | null>(
    initialData?.type === CREATE_NEW_ENGINE
      ? null
      : (initialData?.engine?.engineName as string)
  );

  const [isCreating, setIsCreating] = useState(false);

  useEffect(() => {
    if (initialData?.engine?.engineName) {
      setEngine(initialData.engine.engineName);
    }
  }, [initialData?.engine?.engineName]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
  } = useForm<Fields>({
    resolver: zodResolver(schema),
    defaultValues:
      initialData?.type === CREATE_NEW_ENGINE
        ? (initialData?.engine as Fields)
        : initialEngine,
  });

  const handleFormSubmit = async (data: Fields) => {
    try {
      setIsCreating(true);

      await createWorkspaceEngine(data);
      queryClient.removeQueries({ queryKey: [workspaceEnginesQueryKey] });
      updateEngine(data.engineName);

      onSubmit({
        type: CREATE_NEW_ENGINE,
        engine: data,
      });
    } catch (error) {
      putStatusMessage({
        message: (error as Error).message,
        type: StatusMessageType.Error,
      });
      await queryClient.invalidateQueries({ queryKey: [workspaceEnginesQueryKey] });
    } finally {
      setIsCreating(false);
    }
  };

  const handleFormSubmitExisted = async () => {
    try {
      onSubmit({
        engine: {
          engineName: engine as string,
        },
      });
    } catch (error) {
      putStatusMessage({
        message: (error as Error).message,
        type: StatusMessageType.Error,
      });
      await queryClient.invalidateQueries({ queryKey: [workspaceEnginesQueryKey] });
    }
  };

  const cost =
    Number(watch("clusters")) *
    Number(watch("nodes")) *
    engineCost[watch("type")];

  const items = [
    <ContextMenuItem
      key={CREATE_NEW_ENGINE}
      value={CREATE_NEW_ENGINE}
      text={t("wizard.select_engine.create_engine")}
      checked={engine === CREATE_NEW_ENGINE}
      testId="select-engine-step-create-new"
      checkedIconPlaceholder
      skipFilter
    />,
    <ContextMenuDivider key="divider" />,
    ...engines.map((item, index) => {
      const { engineName } = item;
      return (
        <ContextMenuItem
          value={engineName}
          key={engineName}
          checked={engineName === engine}
          checkedIconPlaceholder
          testId={`select-engine-step-engine-item-${index}`}
          text={engineName}
        />
      );
    }),
  ];

  const body = (
    <div className={styles.rows}>
      <div className={styles.row}>
        <div className={styles.label}>
          {t("wizard.select_engine.engine_toggle")}
        </div>
        <OutlinedSelect
          testId="wizard-engine-select"
          wrapperClassName={styles.inputRoot}
          controlledValue={engine || undefined}
          initialSelected={[engine as string]}
          searchOptions={{
            searchPlaceholder: t("wizard.select_engine.search_engine"),
          }}
          renderValue={([item]) => {
            if (item === CREATE_NEW_ENGINE) {
              return t("wizard.select_engine.create_engine");
            }
            return item;
          }}
          onSelect={([type]) => setEngine(type)}
        >
          {items}
        </OutlinedSelect>
      </div>

      {engine === CREATE_NEW_ENGINE && (
        <>
          <CreateEngine
            register={register}
            errors={errors}
            control={control}
            watch={watch}
            showStartEngineImmediately={false}
          />
          <div className={styles.cost}>
            <div>Cost per hour</div>
            <div className={styles.cost__value}>
              {cost}
              <span className={styles.cost__unit}> FBU</span>
            </div>
          </div>
        </>
      )}
    </div>
  );
  return (
    <Step
      title={t("wizard.select_engine.title")}
      subtitle={t("wizard.select_engine.subtitle")}
      body={body}
      onClose={onClose}
      secondaryActionTestId="close-ingestion-wizard"
      onPrevStep={onPrevStep}
      activeStepIndex={activeStepIndex}
      disabledSubmit={isCreating || !engine}
      isLoading={isCreating}
      onSubmit={
        engine === CREATE_NEW_ENGINE
          ? handleSubmit(handleFormSubmit)
          : handleFormSubmitExisted
      }
    />
  );
};
