import { useSuspenseQuery } from "@tanstack/react-query";

import { authService } from "services/auth";

export const useLoggedInUser = () => {
  const { data } = useSuspenseQuery({
    queryKey: ["isLoggedIn"],
    queryFn: () => {
      return authService.isLoggedIn();
    },
  });

  return {
    isLoggedIn: data,
  };
};
