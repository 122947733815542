import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import Warning from "pages/DevelopWorkspace/Editor/Document/DocumentOutput/DocumentResults/ResultsControls/Warning/Warning";
import ExportMenu from "pages/DevelopWorkspace/Editor/Document/DocumentOutput/components/ExportMenu/ExportMenu";
import {
  ExecutionType,
  QueryStatement,
  WorkspaceDocument,
} from "pages/DevelopWorkspace/workspace.types";

import { MAX_DISPLAY_LENGTH } from "components/Datagrid/constant";
import TooltipMessage from "components/Tooltip/TooltipMessage/TooltipMessage";
import TooltipMessageDivider from "components/Tooltip/TooltipMessage/TooltipMessageDivider";

import styles from "./ResultsControls.module.scss";

interface ResultsControlsProps {
  queryStatement: QueryStatement;
  document: WorkspaceDocument;
  isProfile?: boolean;
}

const ResultsControls = (props: ResultsControlsProps) => {
  const { document, queryStatement, isProfile } = props;

  const { t } = useTranslation();
  const messages = useMemo(() => {
    const messages = [] as JSX.Element[];

    const results = queryStatement.result;

    if (!results) {
      return messages;
    }

    const duplicatedNamesSet = new Set();
    results.meta?.forEach((field: any) => {
      if (field.name !== field.displayName) {
        duplicatedNamesSet.add(field.displayName);
      }
    });

    if (duplicatedNamesSet.size) {
      const message = (
        <TooltipMessage
          title={t("queries.duplicated_message", {
            columnName: Array.from(duplicatedNamesSet).join(", "),
          })}
        />
      );

      if (messages.length) messages.push(<TooltipMessageDivider />);

      messages.push(message);
    }

    if (results.rows && results.rows >= MAX_DISPLAY_LENGTH) {
      if (messages.length) messages.push(<TooltipMessageDivider />);

      messages.push(<TooltipMessage title="Showing first 10 000 rows" />);
    }

    return messages;
  }, [queryStatement, t]);

  const getExportData = () => {
    const isExplain =
      queryStatement.executionType === ExecutionType.Explain ||
      document.execution?.executionType === ExecutionType.Explain;

    if (isExplain && queryStatement.explainType) {
      return {
        rows: [queryStatement.explain?.[queryStatement.explainType]] || [],
      };
    }
    if (isProfile) {
      return {
        rows: queryStatement.explain?.analyze
          ? [queryStatement.explain?.analyze]
          : [],
      };
    }

    return {
      rows: queryStatement.result?.data || [],
    };
  };

  const exportData = getExportData();
  return (
    <div className={styles.resultControls}>
      {!!messages.length && (
        <>
          <Warning messages={messages} />
          <div className={styles.divider} />
        </>
      )}
      {!!queryStatement.result?.meta?.length && (
        <div id="results-column-filter-icon" />
      )}
      <ExportMenu exportData={exportData} />
    </div>
  );
};

export default ResultsControls;
