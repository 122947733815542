import { useSuspenseQuery } from "@tanstack/react-query";

import { ORGANIZATION_REGEX, authService } from "services/auth";
import { ENV_TLD } from "services/auth/constants";

const LOGGED_OUT_PAGES_SUBDOMAINS = ["ui", "id", "staging", "staging-go", "go"];

const getNameFromToken = (token: string) => {
  try {
    const data = JSON.parse(atob(token.split(".")[1]));
    return data["https://firebolt.io/claims"]?.organization_name;
  } catch (e) {
    return null;
  }
};

const navigateToOrganization = (name: string) => {
  const url = `https://${name}.${ENV_TLD}${window.location.pathname}${window.location.search}`;
  window.location.assign(url);
};

const redirectToOrganization = async () => {
  const token = await authService.auth0client.getTokenSilently();
  if (token) {
    const name = getNameFromToken(token);
    if (name) {
      navigateToOrganization(name);
    }
  }
};

export const OrganizationRedirect = (props: React.PropsWithChildren) => {
  useSuspenseQuery({
    queryKey: ["initializeOrganization"],
    queryFn: async () => {
      try {
        const match = window.origin.match(ORGANIZATION_REGEX);
        if (!match || match[1] === "ui") {
          await redirectToOrganization();
          return null;
        }
        const token = await authService.auth0client.getTokenSilently();
        if (token) {
          const name = getNameFromToken(token);
          if (name && name !== match[1]) {
            navigateToOrganization(name);
            return null;
          }
        }
        return null;
      } catch (e) {
        console.log(e);
      }
    },
    gcTime: Infinity,
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });

  const match = window.origin.match(ORGANIZATION_REGEX);

  // prevent calls to BE until navigation to organization is complete
  if (!match || LOGGED_OUT_PAGES_SUBDOMAINS.includes(match[1])) return null

  const { children } = props;
  return <>{children}</>;
};
