import { useSuspenseQuery } from "@tanstack/react-query";

import { systemEngineEnvironment } from "services/environment/systemEngine";

export type Column = {
  columnName: string;
  dataType: string;
  isNullable: boolean;
};

export const useColumns = (database: string, tableName: string) => {
  const { data } = useSuspenseQuery<Column[], Error>({
    queryKey: [database, tableName, "columns"],
    queryFn: async () => {
      const result = await systemEngineEnvironment.execute<Column>(
        `select column_name, LOWER(data_type) as data_type, is_nullable from information_schema.columns where table_name = '${tableName}'`,
        { database }
      );

      const [response] = result;
      return response.rows;
    },
  });
  return data || [];
};
