import classNames from "classnames";
import _orderBy from "lodash/orderBy";
import React from "react";

import { User } from "services/users/user.types";

import { useViewDefinitions } from "components/ViewDefinitions/useViewDefinitions";

import { EmptySearch } from "../EmptyState/EmptySearch";
import { UserRecord } from "./UserRecord";
import { OrderBy, OrderDirection } from "./types";
import { useDeleteUser } from "./useDeleteUser";
import { useEditUser } from "./useEditUser";

import styles from "./styles.module.scss";

type Props = {
  items: User[];
  order: {
    orderBy: OrderBy;
    orderDirection: OrderDirection;
  };
  search: string;
  openViewDefinitions: ReturnType<
    typeof useViewDefinitions
  >["openViewDefinitions"];
};

export const UserList = (props: Props) => {
  const { search, items, order, openViewDefinitions } = props;
  const { openDeleteUser, deleteUserMarkup } = useDeleteUser();
  const { openEditUser, editUserMarkup } = useEditUser();

  const filtered = items.filter(item => {
    return item.userName.toLowerCase().includes(search.toLowerCase());
  });

  const sorted = _orderBy(
    filtered,
    [(user: User) => user.userName.toLowerCase()],
    [order.orderDirection]
  );

  return (
    <div
      className={classNames(styles.section, {
        [styles.flex]: !!search && !sorted.length,
      })}
    >
      {sorted.map(user => {
        return (
          <UserRecord
            user={user}
            key={user.userName}
            openViewDefinitions={openViewDefinitions}
            openDeleteUser={openDeleteUser}
            openEditUser={openEditUser}
          />
        );
      })}
      {deleteUserMarkup}
      {editUserMarkup}
      {!!search && !sorted.length && (
        <EmptySearch
          search={search}
          testId="empty-users-search"
        />
      )}
    </div>
  );
};
