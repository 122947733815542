import { QueryClient } from "@tanstack/react-query";
import {workspaceEnginesQueryKey} from "../../../../services/engines/constants";

export const updateEngines = async ({
  queryClient,
}: {
  queryClient: QueryClient;
}): Promise<void> => {
  try {
    await queryClient.cancelQueries({ queryKey: [workspaceEnginesQueryKey] });
    await queryClient.invalidateQueries({ queryKey: [workspaceEnginesQueryKey] });
  } catch (e) {
    console.error("Failed to update engines from WS: ", e);
  }
};
