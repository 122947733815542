import cn from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";

import { ReactComponent as CancelIcon } from "assets/icons/Cancel.svg";
import { ReactComponent as ErrorIcon } from "assets/icons/Exclamation.svg";
import { ReactComponent as SuccessIcon } from "assets/icons/SucessIcon.svg";

import { QueryStatementStatus } from "pages/DevelopWorkspace/workspace.types";

import Spinner from "components/Spinner";

import styles from "./StatusCell.module.scss";

const ErrorState = React.memo(() => {
  const { t } = useTranslation();

  return (
    <div
      data-testid="query-error-state"
      className={cn(styles.state, styles.queryError)}
    >
      <div className={styles.iconContainer}>
        <ErrorIcon className={styles.queryError__icon} />
      </div>

      <span className={styles.queryError__text}>{t("queries.error")}</span>
    </div>
  );
});

const CancelState = React.memo(() => {
  const { t } = useTranslation();
  return (
    <div
      data-testid="query-cancel-state"
      className={cn(styles.state, styles.queryCancel)}
    >
      <div className={styles.iconContainer}>
        <CancelIcon />
      </div>
      <span className={styles.queryCancel__text}>{t("queries.canceled")}</span>
    </div>
  );
});

const RunningState = React.memo(() => {
  const { t } = useTranslation();
  const statusText = t("queries.running");
  return (
    <div
      data-testid="query-running-state"
      className={cn(styles.state, styles.queryRunning)}
    >
      <div className={styles.iconContainer}>
        <Spinner
          thickness={6}
          size={18}
          color="colorSuccess"
        />
      </div>
      <span className={styles.queryRunning__text}>{statusText}</span>
    </div>
  );
});

const SuccessState = () => {
  const { t } = useTranslation();
  return (
    <div
      data-testid="query-success-state"
      className={cn(styles.state, styles.querySuccess)}
    >
      <div className={styles.iconContainer}>
        <SuccessIcon className={styles.querySuccess__icon} />
      </div>
      <span className={styles.querySuccess__text}>{t("queries.success")}</span>
    </div>
  );
};

const UnknownState = () => {
  return (
    <div
      data-testid="query-unknown-state"
      className={cn(styles.state, styles.queryRunning)}
    >
      <div className={styles.iconContainer}>
        <Spinner
          thickness={6}
          size={18}
          color="colorDisabled"
        />
      </div>
      <span className={styles.queryRunning__text} />
    </div>
  );
};

interface ResponseColumnInnerProps {
  status: QueryStatementStatus;
}
const StatusCell = (props: ResponseColumnInnerProps) => {
  const { status } = props;

  if (status === QueryStatementStatus.unknown) {
    return <UnknownState />;
  }

  if (status === QueryStatementStatus.running) {
    return <RunningState />;
  }

  if (status === QueryStatementStatus.error) {
    return <ErrorState />;
  }

  if (status === QueryStatementStatus.cancelled) {
    return <CancelState />;
  }
  return <SuccessState />;
};

export default StatusCell;
