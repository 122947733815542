import { useTranslation } from "react-i18next";

import { Step } from "components/Wizard/Step/Step";

import { PrivilegesTable } from "../PrivilegesTable/PrivilegesTable";
import { PrivilegesStepType } from "../types";

type Props = {
  onClose: () => void;
  onSubmit: (data: PrivilegesStepType) => void;
  onPrevStep: () => void;
  activeStepIndex: number;
};

export const PrivilegesStep = (props: Props) => {
  const { onClose, activeStepIndex, onPrevStep, onSubmit } = props;
  const { t } = useTranslation();

  const body = <PrivilegesTable />;

  const onFormSubmit = () => {
    onSubmit({
      privileges: [],
    });
  };

  return (
    <Step
      title={t("roles_wizard.privileges.title")}
      subtitle={t("roles_wizard.privileges.subtitle")}
      body={body}
      onClose={onClose}
      onSubmit={onFormSubmit}
      activeStepIndex={activeStepIndex}
      onPrevStep={onPrevStep}
    />
  );
};
