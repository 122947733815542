import { AuthService } from "services/auth";
import { WorkspaceEngine } from "services/engines/engine.types";

import { FIREBOLT_UI_USER_ENGINE_QUERY_LABEL } from "pages/DevelopWorkspace/services/constants";
import { buildEngineUrl } from "pages/DevelopWorkspace/services/helpers/buildEngineUrl";
import { FetchOptions } from "pages/DevelopWorkspace/services/types";

export const getHistoryQuery = async (
  engine: WorkspaceEngine,
  queryLabel: string,
  serverQueryId: string | undefined,
  fetchOptions: FetchOptions,
  authService: AuthService
) => {
  const token = await authService.getToken();
  if (!token) {
    throw new Error("Failed to fetch query ID: token is not available");
  }

  const { headers, signal } = fetchOptions;
  const engineURL = new URL(`https://${engine.url}`);
  const url = buildEngineUrl(engineURL, {
    query_label: FIREBOLT_UI_USER_ENGINE_QUERY_LABEL,
    auto_start_stop_control: "ignore",
  });

  const filterByQueryId = serverQueryId
    ? ` or query_id = '${serverQueryId}' `
    : "";
  const response = await fetch(url, {
    signal,
    method: "POST",
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
    body: `select * from information_schema.engine_query_history where query_label = '${queryLabel}'${filterByQueryId}
order by start_time desc limit 1`,
  });

  if (response.ok) {
    const json = await response.json();

    const res = json.data[0];

    if (!res) {
      return null;
    }

    const query = {
      status: res.status,
      queryLabel: res.query_label,
      queryId: res.query_id,
      durationUsec: res.duration_us,
      scannedRows: res.scanned_rows,
      scannedBytes: res.scanned_bytes,
      error: res.error_message,
    };

    return query;
  }
  throw new Error();
};
