import classNames from "classnames";
import React, { SyntheticEvent, useCallback, useContext } from "react";

import { ReactComponent as Filters } from "assets/icons/Filters.svg";
import { ReactComponent as ArrowDown } from "assets/icons/v2/ArrowDown.svg";

import { FiltersContext } from "components/Datagrid/context";

import styles from "./styles.module.scss";

export const Sort = React.memo<{
  column: any;
  index: number;
  appliedFilters: boolean;
}>(props => {
  const { column, index, appliedFilters } = props;
  const sortOrder = column?.sortConfig?.sortOrder;
  const { openFilters } = useContext(FiltersContext);

  const handleClick = useCallback(
    (e: SyntheticEvent) => {
      e.stopPropagation();
      openFilters({ column, index });
    },
    [openFilters, column, index]
  );

  const showFilter = !column._typeUpperCase.startsWith("ARRAY");

  return (
    <>
      <div
        className={styles.icon__wrapper}
        data-testid="datagrid-sort"
      >
        {sortOrder === "asc" && (
          <ArrowDown
            className={classNames(styles.icon__up, styles.icon__active)}
          />
        )}
        {sortOrder === "desc" && (
          <ArrowDown className={classNames(styles.icon__active)} />
        )}
        {showFilter && (
          <Filters
            className={classNames(styles.icon, {
              [styles.icon__active]: appliedFilters,
            })}
            onClick={handleClick}
            data-testid={`datagrid-filters-${column.name}`}
          />
        )}
      </div>
    </>
  );
});
