import { useSuspenseQuery } from "@tanstack/react-query";

import { authService } from "./index";

export const useClaims = () => {
  const { data } = useSuspenseQuery({
    queryKey: ["claims"],
    queryFn: () => {
      return authService.getFireboltClaims();
    },
  });
  return data;
};
