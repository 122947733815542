import { Popover } from "@mui/material";
import classNames from "classnames";
import React, { useContext, useEffect, useRef, useState } from "react";

import { ReactComponent as Alert } from "assets/icons/Alert.svg";
import { ReactComponent as DropdownArrowDown } from "assets/icons/DropdownArrowDownV2.svg";

import { useMenu } from "components/ActionMenu/useMenu";
import ContextMenu from "components/ContextMenu/ContextMenu";
import ContextMenuItemsGroup from "components/ContextMenu/ContextMenuItemsGroup";
import { InputDisabledContext } from "components/InputState/InputDisabledContext";
import { Search } from "components/LeftSidebar/Search";
import Tooltip from "components/Tooltip/Tooltip";

import styles from "./styles.module.scss";

export const MIN_WIDTH = 240;

type SearchOptions = {
  searchPlaceholder: string;
  noResultsText?: string;
};

const WithSearch = (props: {
  items?: React.ReactElement[];
  searchOptions?: SearchOptions;
  children: React.ReactElement;
}) => {
  const { children, items = [], searchOptions } = props;
  const [search, setSearch] = useState("");

  if (!searchOptions) {
    return <div>{React.cloneElement(children, { items })}</div>;
  }

  const withDividers = items.filter(item => {
    if ((item?.type as any)?.role === "Divider") {
      return true;
    }
    if (item.props.skipFilter) {
      return true;
    }
    return item.props.value.toLowerCase().includes(search.toLowerCase());
  });

  const withoutDividers = withDividers.filter(
    item => (item?.type as any)?.role !== "Divider"
  );

  const { noResultsText, searchPlaceholder } = searchOptions;

  return (
    <div>
      <Search
        onChange={setSearch}
        value={search}
        autoFocus={true}
        size="small"
        placeholder={searchPlaceholder}
      />
      <div className={styles.selectorItems}>
        {withoutDividers.length ? (
          React.cloneElement(children, { items: withDividers })
        ) : (
          <div className={styles.notFound}>{noResultsText}</div>
        )}
      </div>
    </div>
  );
};

const Select = (props: {
  handleSelect: (item: unknown) => void;
  items?: React.ReactElement[];
}) => {
  const { items = [], handleSelect } = props;

  const menuElements = items.reduce<React.ReactElement[]>(
    (acc, item, index) => {
      if (
        (item?.type as any)?.role === "Divider" &&
        index === items.length - 1
      ) {
        return acc;
      }

      if ((item?.type as any)?.role === "Divider") {
        return [...acc, item];
      }

      const element = React.cloneElement(item, {
        onClick: handleSelect(item.props),
      });

      return [...acc, element];
    },
    []
  );

  return (
    <ContextMenu>
      <ContextMenuItemsGroup
        isGroup
        maxHeight={400}
      >
        {menuElements}
      </ContextMenuItemsGroup>
    </ContextMenu>
  );
};

type Props = {
  className?: string;
  wrapperClassName?: string;
  multiple?: boolean;
  disabled?: boolean;
  allowUncheck?: boolean;
  searchOptions?: SearchOptions;
  initialSelected?: string[];
  controlledValue?: string;
  onSelect?: (items: string[]) => void;
  onClose?: () => void;
  renderValue?: (selectedItems: string[]) => React.ReactNode;
  renderMenu?: (handleClose: () => void) => React.ReactElement;
  children?: React.ReactElement[];
  error?: string;
  popoverProps?: any;
  testId?: string;
  noBorder?: boolean;
  alertTooltip?: string;
};

export const OutlinedSelect = (props: Props) => {
  const {
    className,
    multiple,
    allowUncheck = false,
    searchOptions,
    initialSelected = [],
    onSelect,
    renderValue,
    error,
    popoverProps,
    renderMenu,
    onClose,
    testId,
    wrapperClassName,
    controlledValue,
    noBorder,
    alertTooltip,
  } = props;
  const { menuElement, openMenu, closeMenu } = useMenu();
  const [focus, setFocus] = useState(false);
  const isDisabled = useContext(InputDisabledContext);
  const disabled = props.disabled || isDisabled;

  const selectRef = useRef<HTMLDivElement | null>(null);
  const menuMinWidth = useRef<number>(0);
  const [selectedItems, setSelectedItem] = useState<string[]>(initialSelected);

  useEffect(() => {
    if (controlledValue !== undefined) {
      setSelectedItem(controlledValue ? [controlledValue] : []);
    }
  }, [controlledValue]);

  const setInitialWidth = () => {
    const { width = 0 } = selectRef.current?.getBoundingClientRect() || {};
    menuMinWidth.current = width;
  };

  const handleOpen = (event: React.MouseEvent) => {
    if (disabled) {
      return;
    }
    setInitialWidth();
    openMenu(event);
  };

  const handleKeyDown: React.KeyboardEventHandler = event => {
    if (disabled) {
      return;
    }
    if (event.key === "Enter" || event.key === "ArrowDown") {
      setInitialWidth();
      openMenu(event);
    }
  };

  const handleSelect = (item: unknown) => () => {
    const { value, disableClose } = item as {
      value: string;
      disableClose?: boolean;
    };
    let items = multiple ? selectedItems : [];

    if (multiple) {
      if (items.includes(value)) {
        items = items.filter(i => i !== value);
      } else {
        items = [...items, value];
      }
    } else {
      if (allowUncheck) {
        items = selectedItems.includes(value) ? [] : [value];
      } else {
        items = [value];
      }
      !disableClose && closeMenu();
    }

    setSelectedItem(items);
    onSelect && onSelect(items);
  };

  const value = renderValue
    ? renderValue(selectedItems)
    : selectedItems.join(", ");

  const handleClose = () => {
    closeMenu();
    onClose && onClose();
  };

  const handleFocus = () => setFocus(true);
  const handleBlur = () => setFocus(false);

  return (
    <div
      className={classNames(styles.wrapper, wrapperClassName, {
        [styles.error]: error,
        [styles.focus]: focus,
        [styles.active]: !!menuElement,
        [styles.noBorder]: noBorder,
      })}
      data-testid={testId}
    >
      <div
        className={classNames(styles.select, className, {
          [styles.disabled]: disabled,
          [styles.noBorder]: noBorder,
        })}
        onClick={handleOpen}
        onKeyDown={handleKeyDown}
        ref={selectRef}
        tabIndex={0}
        onFocus={handleFocus}
        onBlur={handleBlur}
      >
        <div className={styles.value}>{value}</div>
        <div className={styles.right}>
          {alertTooltip && (
            <Tooltip title={alertTooltip}>
              <div className={styles.alertTooltip}>
                <Alert />
              </div>
            </Tooltip>
          )}
          <DropdownArrowDown className={styles.dropdownIcon} />
        </div>
      </div>
      {!!error && <span className={styles.error__label}>{error}</span>}
      {!!menuElement && (
        <Popover
          open
          anchorEl={menuElement}
          classes={{ paper: styles.paper }}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          PaperProps={{
            style: {
              minWidth: menuMinWidth.current,
              width: Math.max(menuMinWidth.current, MIN_WIDTH),
              marginTop: 2,
            },
          }}
          {...popoverProps}
        >
          <WithSearch
            items={props.children}
            searchOptions={searchOptions}
          >
            {renderMenu ? (
              renderMenu(handleClose)
            ) : (
              <Select handleSelect={handleSelect} />
            )}
          </WithSearch>
        </Popover>
      )}
    </div>
  );
};
