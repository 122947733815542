import { AccountAdminIcon } from "components/Icons";

export enum SystemRole {
  AccountAdmin = "account_admin",
  SystemAdmin = "system_admin",
  SecurityAdmin = "security_admin",
  Public = "public",
}

export const RBAC_NAME_TYPE_MAP = {
  [SystemRole.AccountAdmin]: SystemRole.AccountAdmin,
  [SystemRole.SystemAdmin]: SystemRole.SystemAdmin,
  [SystemRole.SecurityAdmin]: SystemRole.SecurityAdmin,
  [SystemRole.Public]: SystemRole.Public,
};

export const UI_USER_ROLE_DESCRIPTION_MAP = {
  [SystemRole.AccountAdmin]:
    "roles_selector.system_role_description.account_admin",
  [SystemRole.SystemAdmin]:
    "roles_selector.system_role_description.system_admin",
  [SystemRole.SecurityAdmin]:
    "roles_selector.system_role_description.security_admin",
  [SystemRole.Public]: "roles_selector.system_role_description.public",
};

export const USER_ROLES_ICON_MAP = {
  [SystemRole.AccountAdmin]: <AccountAdminIcon />,
  [SystemRole.SystemAdmin]: <AccountAdminIcon />,
  [SystemRole.SecurityAdmin]: <AccountAdminIcon />,
  [SystemRole.Public]: <AccountAdminIcon />,
};

export const RBAC_ROLES_QUERY = "rbacRoles";
