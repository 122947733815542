import { Popover } from "@mui/material";
import classNames from "classnames";

import { ReactComponent as ChevronDown } from "assets/icons/ChevronDownSmall.svg";
import { ReactComponent as Questionmark } from "assets/icons/Questionmark.svg";

import { useMenu } from "components/ActionMenu/useMenu";
import ContextMenu from "components/ContextMenu/ContextMenu";
import ContextMenuItem from "components/ContextMenu/ContextMenuItem";
import CustomTooltip from "components/Tooltip";
import TooltipMessage from "components/Tooltip/TooltipMessage/TooltipMessage";

import styles from "./styles.module.scss";

interface ChipProps {
  title: string;
  className: string;
  inactive?: boolean; // means has no menu actions
  disabled?: boolean;
  inline?: boolean;
  inMenu?: boolean;
  disableReason?: {
    title: string;
    description: string;
  };
  items: {
    disabled?: boolean;
    title: string;
    action: () => void;
    testId?: string;
  }[];
  size?: "small" | "regular";
}

export const Chip = (props: ChipProps) => {
  const {
    items,
    disableReason,
    disabled,
    inactive,
    inline,
    inMenu,
    title,
    size = "regular",
    className,
  } = props;

  const { menuElement, openMenu, closeMenu } = useMenu();
  const menuItems = items.map(item => {
    const { disabled, title, action, testId } = item;

    const handleClick = (e: any) => {
      e.stopPropagation();
      closeMenu();
      action();
    };

    return (
      <ContextMenuItem
        key={title}
        text={title}
        onClick={handleClick}
        testId={testId}
        disabled={disabled}
      />
    );
  });
  return (
    <>
      <div
        className={classNames(styles.chip, className, {
          [styles.active]: !!menuElement,
          [styles.small]: size === "small",
          [styles.disabled]: disabled,
          [styles.inactive]: inactive,
          [styles.inline]: inline,
          [styles.inMenu]: inMenu,
        })}
        onClick={e => {
          if (!disabled && !inactive) {
            openMenu(e);
          }
        }}
        data-testid="status-chip"
      >
        <div className={styles.title}>{title}</div>

        {disableReason && !inactive && (
          <CustomTooltip
            title={
              disableReason ? (
                <TooltipMessage
                  title={disableReason.title}
                  description={disableReason.description}
                />
              ) : (
                ""
              )
            }
            placement="right"
          >
            <div className={styles.icon}>
              <Questionmark />
            </div>
          </CustomTooltip>
        )}

        {!disabled && !inactive && (
          <div className={styles.chevronIcon}>
            <ChevronDown />
          </div>
        )}
      </div>

      {!disabled && !inactive && menuElement && !!menuItems.length && (
        <Popover
          open={true}
          anchorEl={menuElement}
          onClose={() => {
            closeMenu();
          }}
          onClick={e => {
            e.stopPropagation();
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <ContextMenu>{menuItems}</ContextMenu>
        </Popover>
      )}
    </>
  );
};
