import Button, { ButtonTemplate } from "components/Button/Button";

import { ReactComponent as DatabaseIcon } from "./database.svg";
import { ReactComponent as EngineIcon } from "./engine.svg";

import styles from "./styles.module.scss";

type Props = {
  icon: React.ReactNode;
  title: string;
  subtitle: string;
  button: string;
  testId?: string;
  onClick: () => void;
};

export const EmptyState = (props: Props) => {
  const { icon, title, subtitle, button, onClick, testId } = props;
  return (
    <div
      className={styles.wrapper}
      data-testid={testId}
    >
      <div className={styles.inner}>
        <div>{icon}</div>
        <div className={styles.title}>{title}</div>
        <div className={styles.subtitle}>{subtitle}</div>
        <Button
          dataTestid={`${testId}-button`}
          text={button}
          template={ButtonTemplate.Primary}
          onClick={onClick}
        />
      </div>
    </div>
  );
};

export { EngineIcon, DatabaseIcon };
