import * as sqlynx from "@ankoh/sqlynx-core";
import { useRef, useState } from "react";
import * as React from "react";

import AppCrash from "components/ErrorBoundary/AppCrash";
import LoadingOverlap from "components/LoadingOverlap/LoadingOverlap";

const SQLYNX_MODULE_URL = new URL(
  "@ankoh/sqlynx-core/dist/sqlynx.wasm",
  import.meta.url
);

export const RESULT_OK = Symbol("RESULT_OK");
export const RESULT_ERROR = Symbol("RESULT_ERROR");

export type Result<ValueType> =
  | { type: typeof RESULT_OK; value: ValueType }
  | { type: typeof RESULT_ERROR; error: Error };

export interface InstantiationProgress {
  startedAt: Date;
  updatedAt: Date;
  bytesTotal: bigint;
  bytesLoaded: bigint;
}

export interface SchemaTable {
  tableName: string;
  columns: string[];
}

export interface SchemaDescriptor {
  databaseName: string;
  schemaName: string;
  tables: SchemaTable[];
}

const DESCRIPTOR_POOL_ID = 1;

interface Props {
  children: JSX.Element;
}

const PROGRESS_CONTEXT = React.createContext<InstantiationProgress | null>(
  null
);

interface SQLynxContextType {
  sqlynx: sqlynx.SQLynx;
  catalog: sqlynx.SQLynxCatalog | null;
  addDescriptor: (descriptor: SchemaDescriptor) => void;
  descriptors: SchemaDescriptor[];
  script: sqlynx.SQLynxScript | null;
}

const MODULE_CONTEXT = React.createContext<SQLynxContextType>(
  {} as SQLynxContextType
);

export const SQLynxLoader: React.FC<Props> = (props: Props) => {
  const [module, setModule] = React.useState<Result<sqlynx.SQLynx> | null>(
    null
  );
  const [progress, setProgress] = React.useState<InstantiationProgress | null>(
    null
  );

  const catalogRef = useRef<sqlynx.SQLynxCatalog | null>(null);
  const scriptRef = useRef<sqlynx.SQLynxScript | null>(null);

  const [descriptors, setDescriptors] = useState<SchemaDescriptor[]>([]);

  const addDescriptor = (descriptor: SchemaDescriptor) => {
    // check if descriptor already exists
    // if no, update state and add to catalog

    const foundDescriptor = descriptors.find(
      d =>
        d.databaseName === descriptor.databaseName &&
        d.schemaName === descriptor.schemaName
    );

    if (foundDescriptor) {
      return;
    }

    setDescriptors([...descriptors, descriptor]);

    if (catalogRef.current) {
      const schemaDescriptor = new sqlynx.proto.SchemaDescriptorT(
        descriptor.databaseName,
        descriptor.schemaName,
        descriptor.tables.map(table => {
          return new sqlynx.proto.SchemaTableT(
            0,
            table.tableName,
            table.columns.map(column => {
              return new sqlynx.proto.SchemaTableColumnT(column);
            })
          );
        })
      );
      catalogRef.current.addSchemaDescriptorT(
        DESCRIPTOR_POOL_ID,
        schemaDescriptor
      );
    }
  };

  React.useEffect(() => {
    const now = new Date();
    const internal: InstantiationProgress = {
      startedAt: now,
      updatedAt: now,
      bytesTotal: BigInt(0),
      bytesLoaded: BigInt(0),
    };
    // Fetch an url with progress tracking
    const fetchWithProgress = async (url: URL) => {
      // Try to determine file size
      const request = new Request(url);
      const response = await fetch(request);
      const contentLengthHdr = response.headers.get("content-length");
      const contentLength = contentLengthHdr
        ? parseInt(contentLengthHdr, 10) || 0
        : 0;

      const now = new Date();
      internal.startedAt = now;
      internal.updatedAt = now;
      internal.bytesTotal = BigInt(contentLength) || BigInt(0);
      internal.bytesLoaded = BigInt(0);
      const tracker = {
        transform(chunk: Uint8Array, ctrl: TransformStreamDefaultController) {
          const prevUpdate = internal.updatedAt;
          internal.updatedAt = now;
          internal.bytesLoaded += BigInt(chunk.byteLength);
          if (now.getTime() - prevUpdate.getTime() > 20) {
            setProgress(_ => ({ ...internal }));
          }
          ctrl.enqueue(chunk);
        },
      };
      const ts = new TransformStream(tracker);
      return new Response(response.body?.pipeThrough(ts), response);
    };
    const instantiate = async () => {
      try {
        const instance = await sqlynx.SQLynx.create(
          async (imports: WebAssembly.Imports) => {
            return await WebAssembly.instantiateStreaming(
              fetchWithProgress(SQLYNX_MODULE_URL),
              imports
            );
          }
        );

        setProgress(_ => ({
          ...internal,
          updatedAt: new Date(),
        }));

        const lnx = instance;

        const catalog = lnx.createCatalog();
        catalog.addDescriptorPool(DESCRIPTOR_POOL_ID, 10);

        scriptRef.current = lnx.createScript(catalog, 2);

        catalogRef.current = catalog;

        setModule({
          type: RESULT_OK,
          value: instance!,
        });
      } catch (e: any) {
        console.error(e);
        setModule({
          type: RESULT_ERROR,
          error: e!,
        });
      }
    };
    instantiate();
  }, []);

  if (!module) {
    return <LoadingOverlap isLoading={true} />;
  }

  if (module.type === RESULT_ERROR) {
    return <AppCrash />;
  }

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const contextValue = {
    sqlynx: module.value,
    catalog: catalogRef.current,
    addDescriptor,
    descriptors,
    script: scriptRef.current,
  };

  return (
    <PROGRESS_CONTEXT.Provider value={progress}>
      <MODULE_CONTEXT.Provider value={contextValue}>
        {props.children}
      </MODULE_CONTEXT.Provider>
    </PROGRESS_CONTEXT.Provider>
  );
};

export const useSQLynx = (): SQLynxContextType =>
  React.useContext(MODULE_CONTEXT);
