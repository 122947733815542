import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  useBeforeUnload,
  unstable_useBlocker as useBlocker,
  useSearchParams,
} from "react-router-dom";

import Document from "pages/DevelopWorkspace/Editor/Document/Document";
import TabsBar from "pages/DevelopWorkspace/Editor/TabsBar/TabsBar";
import { useDocuments } from "pages/DevelopWorkspace/contexts/DocumentsContext/hooks/useDocuments";
import { isDocumentRunning } from "pages/DevelopWorkspace/helpers/isDocumentRunning";
import useDocumentsExecution from "pages/DevelopWorkspace/services/useDocumentsExecution";
import useSchemaUpdater from "pages/DevelopWorkspace/services/useSchemaUpdater";

import { ConfirmationModal } from "components/ConfirmationModal/ConfirmationModal";
import { useLoadData } from "components/LeftSidebar/CreateMenu/useLoadData";

import styles from "./Editor.module.scss";

export const Editor = () => {
  const { state } = useDocuments();
  const { t } = useTranslation();
  useDocumentsExecution();
  useSchemaUpdater();
  const { openLoadData, loadDataMarkup } = useLoadData();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get("init") === "loadData") {
      searchParams.delete("init");
      setSearchParams(searchParams);
      openLoadData();
    }
  }, [searchParams, openLoadData, setSearchParams]);

  useBeforeUnload(event => {
    if (state.documents.some(document => isDocumentRunning(document))) {
      event.preventDefault();
    }
  });

  const blocker = useBlocker(() => {
    return state.documents.some(document => isDocumentRunning(document));
  });

  const { documents, activeDocumentId } = state;

  const activeDocument = documents.find(
    document => document.id === activeDocumentId
  );

  const renderDocument = () => {
    if (!activeDocument) {
      return <div>No active document</div>;
    }

    return <Document document={activeDocument} />;
  };

  return (
    <>
      <div className={styles.root}>
        {!!documents.length && (
          <>
            <TabsBar />

            {renderDocument()}
          </>
        )}
        {blocker.state === "blocked" && (
          <ConfirmationModal
            onClose={() => {
              blocker.reset();
            }}
            onConfirm={() => {
              blocker.proceed();
            }}
            title={t("running_query_blocker.title")}
            message={t("running_query_blocker.message")}
            confirmButtonOptions={{
              text: t("running_query_blocker.confirm"),
            }}
            cancelButtonOptions={{
              text: t("running_query_blocker.cancel"),
            }}
          />
        )}
        {loadDataMarkup}
      </div>
    </>
  );
};
