import { useState } from "react";

import { IngestionWizard } from "components/IngestionWizard/IngestionWizard";

export const useLoadData = () => {
  const [open, setOpen] = useState<boolean>(false);

  const openLoadData = () => {
    setOpen(true);
  };

  const closeCreateUser = () => {
    setOpen(false);
  };

  const loadDataMarkup = open ? (
    <IngestionWizard onClose={closeCreateUser} />
  ) : null;

  return {
    loadDataMarkup,
    openLoadData,
  };
};
