import { TFunction } from "i18next";

import { RbacActionObject, SqlRbacAction } from "services/rbac/action";

const headerTooltip = {
  [RbacActionObject.ENGINE]: {
    [SqlRbacAction.ENGINE_USAGE]: "roles.header_tooltip.engine_usage",
    [SqlRbacAction.ENGINE_OPERATE]: "roles.header_tooltip.engine_operate",
    [SqlRbacAction.ENGINE_MODIFY]: "roles.header_tooltip.engine_modify",
  },
  [RbacActionObject.DATABASE]: {
    [SqlRbacAction.DATABASE_USAGE]: "roles.header_tooltip.database_usage",
    [SqlRbacAction.DATABASE_MODIFY]: "roles.header_tooltip.database_modify",
  },
};

const actionTooltip = {
  // engine
  [SqlRbacAction.ENGINE_CREATE]: "roles.action_tooltip.engine_create",
  [SqlRbacAction.ENGINE_USAGE]: "roles.action_tooltip.engine_usage",
  [SqlRbacAction.ENGINE_OPERATE]: "roles.action_tooltip.engine_operate",
  [SqlRbacAction.ENGINE_MODIFY]: "roles.action_tooltip.engine_modify",
  // any engine
  [SqlRbacAction.ENGINE_USAGE_ANY]: "roles.action_tooltip.engine_usage_any",
  [SqlRbacAction.ENGINE_MODIFY_ANY]: "roles.action_tooltip.engine_modify_any",
  [SqlRbacAction.ENGINE_OPERATE_ANY]: "roles.action_tooltip.engine_operate_any",
  // database
  [SqlRbacAction.DATABASE_CREATE]: "roles.action_tooltip.database_create",
  [SqlRbacAction.DATABASE_USAGE]: "roles.action_tooltip.database_usage",
  [SqlRbacAction.DATABASE_MODIFY]: "roles.action_tooltip.database_modify",
  // any database
  [SqlRbacAction.DATABASE_USAGE_ANY]: "roles.action_tooltip.database_usage_any",
  [SqlRbacAction.DATABASE_MODIFY_ANY]:
    "roles.action_tooltip.database_modify_any",
};

export const getHeaderTooltip = (
  t: TFunction,
  objectType: RbacActionObject,
  action: SqlRbacAction
) => {
  const key =
    headerTooltip[objectType as keyof typeof headerTooltip][
      action as keyof (typeof headerTooltip)[typeof objectType]
    ];

  return t(key);
};

export const getActionTooltip = (t: TFunction, action: SqlRbacAction) => {
  const key = actionTooltip[action as keyof typeof actionTooltip];
  return t(key);
};

const engineToggles = [
  {
    type: SqlRbacAction.ENGINE_CREATE,
    label: "roles.toggles.engine_create",
  },
  {
    type: SqlRbacAction.ENGINE_MODIFY_ANY,
    label: "roles.toggles.engine_modify",
    resource: "*",
  },
  {
    type: SqlRbacAction.ENGINE_OPERATE_ANY,
    label: "roles.toggles.engine_operate",
    resource: "*",
  },
  {
    type: SqlRbacAction.ENGINE_USAGE_ANY,
    label: "roles.toggles.engine_usage",
    resource: "*",
  },
];

const databaseToggles = [
  {
    type: SqlRbacAction.DATABASE_CREATE,
    label: "roles.toggles.database_create",
  },
  {
    type: SqlRbacAction.DATABASE_MODIFY_ANY,
    label: "roles.toggles.database_modify",
    resource: "*",
  },
  {
    type: SqlRbacAction.DATABASE_USAGE_ANY,
    label: "roles.toggles.database_usage",
    resource: "*",
  },
];

export const formatTabMessages = (tab: string) => {
  switch (tab) {
    case "engine":
      return {
        toggleTypes: engineToggles,
        tabHeader: "roles.tabs.engine.header",
        tabDescription: "roles.tabs.engine.description",
      };

    case "database":
      return {
        toggleTypes: databaseToggles,
        tabHeader: "roles.tabs.database.header",
        tabDescription: "roles.tabs.database.description",
      };
    default:
      return {
        toggleTypes: [],
        tabHeader: "",
        tabDescription: "",
      };
  }
};
