import { Dialog, IconButton } from "@mui/material";
import { useTranslation } from "react-i18next";
import { unstable_useBlocker as useBlocker } from "react-router-dom";
import { FIREBOLT_DOCS_URL } from "utils/constants";

import { ConfirmationModal } from "components/ConfirmationModal/ConfirmationModal";
import { CrossIcon } from "components/Icons";

import styles from "./styles.module.scss";

type Props = {
  title: string;
  subtitle: string;
  onClose: () => void;
  header?: React.ReactNode;
  body: React.ReactNode;
};

export const Wizard = (props: Props) => {
  const { title, subtitle, onClose, header, body } = props;
  const { t } = useTranslation();
  const blocker = useBlocker(true);
  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      onClose={onClose}
      scroll="body"
      open
    >
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <div className={styles.header__items}>
            <div className={styles.header__inner}>
              <div className={styles.header__title}>{title}</div>
              <div className={styles.header__subtitle}>
                {subtitle}{" "}
                <a
                  className={styles.link}
                  href={FIREBOLT_DOCS_URL}
                  target="_blank"
                  rel="noopener noreferrer"
                  tabIndex={-1}
                  data-testid="firebolt-docs-link"
                >
                  {t("workspace.load_data.subtitle_link")}
                </a>
              </div>
            </div>
            {header}
          </div>
          <IconButton
            onClick={onClose}
            edge="start"
            size="small"
            className={styles.close}
          >
            <CrossIcon data-testid="close-modal-cross" />
          </IconButton>
        </div>
        <div className={styles.body}>{body}</div>
      </div>
      {blocker.state === "blocked" && (
        <ConfirmationModal
          onClose={() => {
            blocker.reset();
          }}
          onConfirm={() => {
            blocker.proceed();
          }}
          title={t("wizard.blocker.title")}
          message={t("wizard.blocker.message")}
          confirmButtonOptions={{
            text: t("wizard.blocker.confirm"),
          }}
          cancelButtonOptions={{
            text: t("wizard.blocker.cancel"),
          }}
        />
      )}
    </Dialog>
  );
};
