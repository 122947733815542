import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "@tanstack/react-query";

import {
  EditEngineInput,
  editWorkspaceEngine,
} from "services/engines/editWorkspaceEngine";
import { WorkspaceEngine } from "services/engines/engine.types";
import { workspaceEnginesQueryKey } from "services/engines/constants";

import { StatusMessageType } from "components/StatusMessageQueue/StatusMessageQueueProvider";
import useStatusMessageQueue from "components/StatusMessageQueue/hooks/useStatusMessageQueue";

import { EngineModal } from "../../EngineModal/EngineModal";

export const useEditEngine = () => {
  const [editEngine, setEditEngine] = useState<WorkspaceEngine | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const queryClient = useQueryClient();

  const { t } = useTranslation();

  const { putStatusMessage } = useStatusMessageQueue();

  const openEditEngine = (engine: WorkspaceEngine) => {
    setEditEngine(engine);
  };

  const closeEditEngine = () => {
    if (isLoading) {
      return;
    }
    setEditEngine(null);
  };

  const confirmEdit = async (engine: EditEngineInput) => {
    try {
      setIsLoading(true);
      await editWorkspaceEngine({
        engine,
        initialEngine: editEngine as WorkspaceEngine,
      });
      await queryClient.cancelQueries({ queryKey: [workspaceEnginesQueryKey] });

      queryClient.setQueryData<WorkspaceEngine[] | undefined>(
        [workspaceEnginesQueryKey],
        workspaceEngines => {
          return (workspaceEngines || []).map(workspaceEngine => {
            if (
              workspaceEngine.engineName === engine.engineName ||
              workspaceEngine.engineName === editEngine?.engineName
            ) {
              return { ...workspaceEngine, ...engine };
            }
            return workspaceEngine;
          });
        }
      );

      putStatusMessage({
        message: t("workspace.edit_modal.success"),
        type: StatusMessageType.Success,
      });
      setIsLoading(false);
      setEditEngine(null);
    } catch (error: any) {
      console.log(error);
      setIsLoading(false);
      putStatusMessage({
        message: error.message,
        type: StatusMessageType.Error,
      });
      await queryClient.invalidateQueries({ queryKey: [workspaceEnginesQueryKey] });
    }
  };

  const editEngineMarkup = editEngine ? (
    <EngineModal
      onClose={closeEditEngine}
      onSubmit={confirmEdit}
      isLoading={isLoading}
      engine={editEngine}
      title={t("workspace.edit_engine.title")}
      primaryButton="workspace.edit_engine.submit"
    />
  ) : null;

  return {
    editEngineMarkup,
    openEditEngine,
  };
};
