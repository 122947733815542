import { useSuspenseQuery } from "@tanstack/react-query";

import { getLogins } from "services/login/getLogins";
import { Login } from "services/login/login.types";

import { LOGINS_QUERY_KEY } from "pages/configure/Logins/constants";

export const useLogins = () => {
  const { data, isLoading, error } = useSuspenseQuery<Login[], Error>({
    queryKey: LOGINS_QUERY_KEY,
    queryFn: () => getLogins(),
    retry: false,
  });
  return { data: data || [], isLoading, error };
};
