import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { ReactComponent as ScriptIcon } from "assets/icons/Documentation2.svg";

import DialogComponent from "components/DialogComponent";
import {
  StatusMessagePosition,
  StatusMessageType,
} from "components/StatusMessageQueue/StatusMessageQueueProvider";
import useStatusMessageQueue from "components/StatusMessageQueue/hooks/useStatusMessageQueue";

import styles from "./UploadFileModal.module.scss";

interface Props {
  closeModal: () => void;
  handleUpload: (files: any[]) => void;
  title: string;
}

const UploadFileModal = (props: Props) => {
  const { closeModal, handleUpload, title } = props;
  const { putStatusMessage } = useStatusMessageQueue();
  const inputFileRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [selectedFile] = useState("");

  const { t } = useTranslation();

  const handleFiles = (filesList: any) => {
    const files: any[] = Array.from(filesList);
    let error: boolean = false;
    files.forEach(file => {
      if (file.size > 1024 ** 2) {
        putStatusMessage({
          message: `${file.name} is larger than 1MB`,
          type: StatusMessageType.Error,
          options: {
            id: null,
            insertToPosition: StatusMessagePosition.Top,
            autoRemove: false,
          },
        });

        error = true;
      }
    });

    if (error) return;

    handleUpload(files);
  };

  const onFileUpload = (event: any) => {
    handleFiles(event.target.files);
  };

  const catchEvent = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const onUploadClick = () => {
    if (inputFileRef && inputFileRef.current) {
      // @ts-ignore
      inputFileRef.current.click();
    }
  };

  const handleDragOver = (e: any) => {
    catchEvent(e);
    e.dataTransfer.dropEffect = "copy";
  };

  const handleDrop = (e: any) => {
    catchEvent(e);
    setIsDragging(false);
    handleFiles(e.dataTransfer.files);
  };

  const handleDragEnter = (e: any) => {
    catchEvent(e);
    setIsDragging(true);
  };

  const handleDragLeave = (e: any) => {
    catchEvent(e);
    setIsDragging(false);
  };

  return (
    <DialogComponent
      openModal={true}
      closeModal={closeModal}
      title={title}
      style={styles.paper}
      classes={{
        dialogTitleCn: styles.dialogTitle,
      }}
      testIds={{
        contentWrapper: "import-scripts-modal",
      }}
      showCloseIcon
    >
      <div
        className={styles.content}
        data-testid="import-script-container"
      >
        <div
          className={styles.dragArea}
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
        >
          <ScriptIcon className={styles.icon} />
          <div
            data-testid="drag-area-text"
            className={styles.text}
          >
            {isDragging ? t("upload.dragging") : t("upload.drag_text")}
          </div>
          {!isDragging && (
            <div
              onClick={onUploadClick}
              data-testid="upload-from-pc"
              className={styles.browseFromPc}
            >
              {t("upload.browse")}
            </div>
          )}
        </div>
        <input
          type="file"
          onChange={onFileUpload}
          value={selectedFile}
          ref={inputFileRef}
          className={styles.fileInput}
          data-testid="file-input"
          accept=".sql"
          multiple
        />
      </div>
    </DialogComponent>
  );
};

export default UploadFileModal;
