import { useTranslation } from "react-i18next";

import { ReactComponent as SearchIcon } from "./search.svg";

import styles from "./styles.module.scss";

type Props = {
  search: string;
  testId?: string;
};

export const EmptySearch = (props: Props) => {
  const { search, testId } = props;
  const { t } = useTranslation();
  const message = `"${search}"`;
  return (
    <div
      className={styles.wrapper}
      data-testid={testId}
    >
      <div className={styles.inner}>
        <SearchIcon />
        <div className={styles.title}>{t("workspace.search.no_results")}</div>
        <div className={styles.subtitle}>
          {t("workspace.search.empty")}
          <span className={styles.search}>{message}</span>.
        </div>
      </div>
    </div>
  );
};
