import { useLDClient } from "launchdarkly-react-client-sdk";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";

import { MyAccount } from "services/account/account.types";
import { getAccounts } from "services/account/getAccounts";
import { authService } from "services/auth";
import { systemEngineEnvironment } from "services/environment/systemEngine";
import { SqlRbacAction } from "services/rbac/action";
import { getPrivileges } from "services/rbac/getPrivileges";
import { getUserByLogin } from "services/users/getUserByLogin";
import { isOrganizationApprovedQueryKey } from "services/organization/constants";
import { myAccountsQueryKey } from "services/account/constants";

import { useCurrentParamsAccount } from "components/Account/useCurrentParamsAccount";
import { accessManager } from "components/App/accessManager";
import LoadingOverlap from "components/LoadingOverlap";
import { MainSidebarSkeleton } from "components/MainSidebar/MainSidebarSkeleton";

import { AccountContext } from "./AccountContext";

import styles from "../App/styles.module.scss";

const globalDataKeys = [isOrganizationApprovedQueryKey, myAccountsQueryKey];

export const AccountProvider = (props: React.PropsWithChildren) => {
  const currentAccount = useCurrentParamsAccount();
  const queryClient = useQueryClient();
  const client = useLDClient();
  const { children } = props;
  const [isInitializingAccount, setIsInitializingAccount] = useState(true)

  const initializeAccount = useCallback(
    async (nextAccount: MyAccount) => {
      if (systemEngineEnvironment.account_id === nextAccount.id) {
        return null;
      }
      systemEngineEnvironment.setAccountContext(nextAccount.id);

      await systemEngineEnvironment.initializeApiEndpoint(nextAccount);
      const accounts = await getAccounts(nextAccount.accountName);

      const custom = {
        organizationName: authService.organizationName as string,
        organizationID: authService.organization?.organizationID as string,
      };
      if (accounts.length) {
        const [account] = accounts;
        Object.assign(custom, { created: account.created });
      }
      await client?.identify({
        name: nextAccount.accountName,
        custom,
        key: nextAccount.id,
      });

      const auth0user = await authService.auth0client.getUser();
      const loginName = auth0user?.email;

      const [privileges, user] = await Promise.all([
        getPrivileges(loginName as string),
        getUserByLogin(loginName as string),
      ]);

      const systemEnginePrivilege = {
        objectType: "engine",
        objectName: "system",
        privilegeType: SqlRbacAction.ENGINE_USAGE,
      };

      await accessManager.initAccessManager(
        [...privileges, systemEnginePrivilege],
        nextAccount.accountName,
        user
      );

      queryClient.removeQueries({
        predicate: (query) => {
          return !globalDataKeys.some(queryKey => (
            query.queryKey.includes(queryKey)
          ))
        }
      });

      // @ts-ignore
      window?.pendo?.identify({
        visitor: {
          id: auth0user?.email,
          email: auth0user?.email,
        },
        account: {
          id: nextAccount.id,
          accountName: nextAccount.accountName,
          businessTier: authService.organizationName,
        },
      });

      // @ts-ignore
      window?.rudderanalytics?.identify({
        email: auth0user?.email,
        accountId: nextAccount.id,
        organizationName: authService.organizationName
      });

      setIsInitializingAccount(false);
      return null;
    },
    [client, queryClient]
  );

  useEffect(() => {
    initializeAccount(currentAccount)
  }, [initializeAccount, currentAccount]);

  const getAccount = useCallback(() => {
    return currentAccount!;
  }, [currentAccount]);

  const context = useMemo(() => {
    return {
      getAccount,
    };
  }, [getAccount]);


  if (isInitializingAccount) {
    return (
      <div className={styles.app}>
        <MainSidebarSkeleton />
        <div className={styles.content}>
          <LoadingOverlap isLoading />
        </div>
      </div>
    );
  }

  return (
    <AccountContext.Provider value={context}>
      {children}
    </AccountContext.Provider>
  );
};
