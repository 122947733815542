import { useSuspenseQuery } from "@tanstack/react-query";
import { Navigate, matchPath } from "react-router-dom";
import { GUEST_ROUTES } from "utils/routes.constants";

import { authService } from "services/auth";
import { ENV_REDIRECT } from "services/auth/constants";

type Props = {
  children: React.ReactNode;
};

const USER_CONNECTION_ERROR =
  /User (.+)\|(.+)\|(.+) is not part of the (.+) organization/;

const USER_CONNECTION_ERROR_TYPE = "invalid_grant";

type Auth0Error = {
  error: string;
  error_description: string;
};

export const LoginHandle: React.FC<Props> = props => {
  const { children } = props;

  const handleCallback = async () => {
    let navigateTo = null;
    try {
      const searchParams = new URLSearchParams(window.location.search);
      const code = searchParams.get("code");
      const state = searchParams.get("state");
      const error = searchParams.get("error");
      const connection = searchParams.get("connection");
      const organization = searchParams.get("organization");
      if ((code || error) && state) {
        const appState = await authService.handleRedirectCallback();
        const redirect = appState?.redirectPath ?? "/";
        // initiate onboarding only after redirect from login route
        navigateTo = {
          to: redirect,
          options: {
            replace: true,
          },
        };
      } else if (connection && organization) {
        await authService.auth0client.loginWithRedirect({
          authorizationParams: {
            connection,
            organization,
            redirect_uri: `https://${ENV_REDIRECT}${GUEST_ROUTES.LOGIN_HANDLE}`,
          },
        });
      } else {
        navigateTo = { to: GUEST_ROUTES.LOGIN };
      }
    } catch (error) {
      if ((error as Auth0Error)?.error === USER_CONNECTION_ERROR_TYPE) {
        const match = (error as Auth0Error)?.error_description?.match(
          USER_CONNECTION_ERROR
        );
        if (match) {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const [_match, _type, connection, _user, organization] = match;
          await authService.auth0client.loginWithRedirect({
            authorizationParams: {
              connection,
              organization,
              redirect_uri: `https://${ENV_REDIRECT}${GUEST_ROUTES.LOGIN_HANDLE}`,
            },
          });
          return;
        }
      }
      navigateTo = {
        to: `${GUEST_ROUTES.LOGIN_ERROR}?message=${error}`,
      };
    }

    return { navigateTo };
  };

  const { data } = useSuspenseQuery({
    queryKey: ["handleLogin"],
    queryFn: async () => {
      return await handleCallback();
    },
    gcTime: Infinity,
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });

  if (!matchPath(GUEST_ROUTES.LOGIN_HANDLE, window.location.pathname)) {
    return <>{children}</>;
  }

  if (data?.navigateTo) {
    const { to, options } = data.navigateTo;
    return (
      <Navigate
        to={to}
        replace={options?.replace}
      />
    );
  }

  return null;
};
