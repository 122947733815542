import { useSuspenseQuery } from "@tanstack/react-query";

import { getUsers } from "services/users/getUsers";

import { useCurrentAccount } from "components/Account/useCurrentAccount";

import { User } from "./user.types";
import { usersQueryKey } from "./constants";

export const useUsers = () => {
  const { getAccount } = useCurrentAccount();
  const currentAccount = getAccount();
  const { data } = useSuspenseQuery<User[], Error>({
    queryKey: [usersQueryKey],
    queryFn: async () => {
      if (!currentAccount) {
        return [];
      }
      return await getUsers(currentAccount.id);
    },
  });

  return data || [];
};
