import classNames from "classnames";
import { useTranslation } from "react-i18next";

import { ReactComponent as InfoIcon } from "assets/icons/Info.svg";

import CustomTooltip from "components/Tooltip";

import { HeaderColumn } from "./types";

import styles from "../styles.module.scss";

type HeaderProps = {
  columns: HeaderColumn[];
};

export const Header = (props: HeaderProps) => {
  const { columns } = props;
  const { t } = useTranslation();
  return (
    <div className={classNames(styles.header, styles.existing)}>
      {columns.map(column => {
        return (
          <div
            className={classNames(styles.headerCol, styles[column.name], {
              [styles.disabled]: column.disabled,
            })}
            key={column.name}
          >
            <div className={styles.text}>{t(column.displayName)}</div>
            {!!column.info && (
              <CustomTooltip title={t(column.info)}>
                <div className={styles.iconContainer}>
                  <InfoIcon />
                </div>
              </CustomTooltip>
            )}
          </div>
        );
      })}
    </div>
  );
};
