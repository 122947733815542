import { Fields as CreateEngineFields } from "components/CreateEngine/CreateEngine";

import { FormatingFields } from "./FormatData/schema";
import { ExistingTableMapData } from "./MapData/ExistingTable/types";
import { WizardMapData } from "./MapData/Table/types";

export const CREATE_NEW_ENGINE = "__FIREBOLT_INTERNAL_NEW_ENGINE__";

export const CREATE_NEW_DATABASE = "__FIREBOLT_INTERNAL_NEW_DATABASE__";
export const CREATE_NEW_TABLE = "__FIREBOLT_INTERNAL_NEW_TABLE__";
export const SELECT_EXISTED_DATABASE =
  "__FIREBOLT_INTERNAL_SELECT_EXISTED_DATABASE__";

export const NEW_COLUMN = {
  name: "column",
  type: "text",
  included: false,
  additionalColumn: true,
  data: [] as unknown[],
  dataPreview: "",
};

export enum IngestionStep {
  selectAWS = "selectAWS",
  selectData = "selectData",
  formatData = "formatData",
  mapData = "mapData",
  selectDestination = "selectDestination",
  selectEngine = "selectEngine",
  review = "review",
  previewScript = "previewScript",
}

export const SUPPORTED_TYPES = ["csv", "tsv", "parquet"];
export type SupportedTypes = "csv" | "parquet" | "tsv";
export type SupportedQuotes = "DOUBLE_QUOTE" | "SINGLE_QUOTE";
export type SupportedExtensions =
  | "csv"
  | "csv.gz"
  | "csv.snp"
  | "csv.snappy"
  | "gz.csv"
  | "snp.csv"
  | "snappy.csv"
  | "parquet"
  | "parquet.gz"
  | "parquet.snp"
  | "parquet.snappy"
  | "gz.parquet"
  | "snp.parquet"
  | "snappy.parquet";

export type AuthType = "secret" | "iam";

export type SelectAWSStep = {
  authType: AuthType;
  key: string;
  secret: string;
  iamRole: string;
  storageUrl: string;
};

export type SelectEngineStep = {
  type?: string;
  engine?:
    | CreateEngineFields
    | {
        engineName: string;
      };
};

export type DataFile = {
  name: string;
  extension: SupportedTypes;
  url: string;
  compression: string;
};

export type SelectDataStep = {
  selectedFiles?: Set<string>;
  files: DataFile[];
};

export type SelectDestinationStep = {
  type: string | null;
  database?: string;
  table?: string;
  createPayload?: {
    databaseName?: string;
    tableName?: string;
  };
};

export type PreviewData = {
  meta: {
    name: string;
    type: string;
  }[];
  data: Record<string, unknown>[];
  error: any;
};

export type FormatDataStep = {
  useDefaultErrorSettings: boolean;
  useDefaultFormatSettings: boolean;
  extension: SupportedTypes;
  format: FormatingFields["formatSettings"];
  error: {
    errorFile: string;
    authType: "secret" | "iam";
    key: string;
    secret: string;
    // maxErrors: string;
    maxErrorsPerFile: number;
    // onErrorAction: string;
  };
  previewData: PreviewData;
};

export type MapDataStep = {
  data: WizardMapData[];
  primaryIndexes: number[];
  autoPrimaryIndex: boolean;
};

export type MapExistingTableStep = {
  data: ExistingTableMapData[];
  mapType: MapType | null;
};

export type WizardDataType = {
  [IngestionStep.selectAWS]: SelectAWSStep;
  [IngestionStep.selectEngine]?: SelectEngineStep;
  [IngestionStep.selectData]?: SelectDataStep;
  [IngestionStep.selectDestination]?: SelectDestinationStep;
  [IngestionStep.formatData]?: FormatDataStep;
  [IngestionStep.mapData]?: MapDataStep | MapExistingTableStep;
  [IngestionStep.review]?: undefined;
  [IngestionStep.previewScript]?: undefined;
};

export type InitialDataType =
  | SelectAWSStep
  | SelectEngineStep
  | SelectDataStep
  | SelectDestinationStep
  | FormatDataStep;

export enum MapType {
  name = "name",
  index = "index",
}
