import { Suspense, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { Engine } from "services/engines/engine.types";
import { useWorkspaceEngines } from "services/engines/useWorkspaceEngines";
import { RbacActionObject, SqlRbacAction } from "services/rbac/action";

import { engineActions } from "pages/govern/Roles/RbacAction";
import { usePrivilegesState } from "pages/govern/Roles/usePrivilegesContext";
import { useReadOnly } from "pages/govern/Roles/useReadOnly";

import { useCurrentParamsAccount } from "components/Account/useCurrentParamsAccount";
import Checkbox from "components/Checkbox";
import { EmptyEngineIcon } from "components/Icons";
import LoadingOverlap from "components/LoadingOverlap";
import SearchInput from "components/SearchInput/SearchInput";
import { Cell, Table } from "components/Table";
import { highlightText } from "components/helpers";

import { HeaderLabel } from "../HeaderLabel";
import {
  ADD_ENGINE_RESOURCE_PRIVILEGE,
  ADD_ENGINE_RESOURCE_PRIVILEGE_BULK,
  DELETE_ENGINE_RESOURCE_PRIVILEGE,
  DELETE_ENGINE_RESOURCE_PRIVILEGE_BULK,
} from "../privilegesReducer";
import { getHeaderTooltip } from "../utils";

import styles from "../styles.module.scss";

const EnginesTable = (props: { searchQuery: string }) => {
  const { searchQuery } = props;

  const { privilegesState, privilegesDispatch } = usePrivilegesState();
  const { readOnly, affectedOnly, isActionDisabled } = useReadOnly();
  const { data = [] } = useWorkspaceEngines();
  const { t } = useTranslation();
  const currentAccount = useCurrentParamsAccount();

  const { engine, account } = privilegesState;

  const accountPrivileges = account[currentAccount.accountName] || {};
  const wildCardPrivileges = account["*"] || {};

  const enginePrivileges = Object.entries<
    Record<string, Record<SqlRbacAction, unknown>>
  >(engine).reduce<Record<SqlRbacAction, unknown>[]>((acc, entry) => {
    const [key, privilege] = entry;
    if (key === "*") {
      return acc;
    }
    return [...acc, privilege];
  }, []);

  const getEngines = () => {
    if (affectedOnly) {
      return data.filter(en => {
        const privileges = engine[en.engineName] || {};
        return engineActions.some(action => {
          return (
            privileges[action] ||
            accountPrivileges[action] ||
            wildCardPrivileges[action]
          );
        });
      });
    }
    return data;
  };

  const engines = getEngines();

  const sortedEngines = useMemo(() => {
    return [...engines].sort((a, b) => {
      return a.engineName.localeCompare(b.engineName);
    });
  }, [engines]);

  const filtered = sortedEngines.filter(en => {
    return en.engineName.toLowerCase().includes(searchQuery.toLowerCase());
  });

  const usagePrivileges = enginePrivileges.filter(
    privilege => privilege[SqlRbacAction.ENGINE_USAGE]
  );

  const operatePrivileges = enginePrivileges.filter(
    privilege => privilege[SqlRbacAction.ENGINE_OPERATE]
  );

  const modifyPrivileges = enginePrivileges.filter(
    privilege => privilege[SqlRbacAction.ENGINE_MODIFY]
  );

  const checkedByType = {
    [SqlRbacAction.ENGINE_USAGE]: usagePrivileges.length === engines.length,
    [SqlRbacAction.ENGINE_MODIFY]: modifyPrivileges.length === engines.length,
    [SqlRbacAction.ENGINE_OPERATE]: operatePrivileges.length === engines.length,
  };

  const checkedAllByType = {
    [SqlRbacAction.ENGINE_USAGE]: Boolean(
      accountPrivileges[SqlRbacAction.ENGINE_USAGE_ANY] ||
        wildCardPrivileges[SqlRbacAction.ENGINE_USAGE_ANY]
    ),
    [SqlRbacAction.ENGINE_MODIFY]: Boolean(
      accountPrivileges[SqlRbacAction.ENGINE_MODIFY_ANY] ||
        wildCardPrivileges[SqlRbacAction.ENGINE_MODIFY_ANY]
    ),
    [SqlRbacAction.ENGINE_OPERATE]: Boolean(
      accountPrivileges[SqlRbacAction.ENGINE_OPERATE_ANY] ||
        wildCardPrivileges[SqlRbacAction.ENGINE_OPERATE_ANY]
    ),
  };

  const indeterminateByType = {
    [SqlRbacAction.ENGINE_USAGE]:
      !checkedByType[SqlRbacAction.ENGINE_USAGE] &&
      !checkedAllByType[SqlRbacAction.ENGINE_USAGE] &&
      Boolean(usagePrivileges.length),
    [SqlRbacAction.ENGINE_MODIFY]:
      !checkedByType[SqlRbacAction.ENGINE_MODIFY] &&
      !checkedAllByType[SqlRbacAction.ENGINE_MODIFY] &&
      Boolean(modifyPrivileges.length),
    [SqlRbacAction.ENGINE_OPERATE]:
      !checkedByType[SqlRbacAction.ENGINE_OPERATE] &&
      !checkedAllByType[SqlRbacAction.ENGINE_OPERATE] &&
      Boolean(operatePrivileges.length),
  };

  const isDisabled = (action: SqlRbacAction) => {
    return (
      readOnly ||
      isActionDisabled("engine", action) ||
      checkedAllByType[action as keyof typeof checkedAllByType]
    );
  };

  const isHeaderChecked = (action: SqlRbacAction) => {
    return (
      indeterminateByType[action as keyof typeof indeterminateByType] ||
      checkedByType[action as keyof typeof checkedByType] ||
      checkedAllByType[action as keyof typeof checkedAllByType]
    );
  };

  const handleSelectBulk = (action: SqlRbacAction) => () => {
    const disabled = isDisabled(action);
    if (disabled) {
      return;
    }
    const indeterminate =
      indeterminateByType[action as keyof typeof indeterminateByType];
    const checked = checkedByType[action as keyof typeof checkedByType];
    const resources = filtered.map(en => en.engineName);

    const type =
      indeterminate || checked
        ? DELETE_ENGINE_RESOURCE_PRIVILEGE_BULK
        : ADD_ENGINE_RESOURCE_PRIVILEGE_BULK;

    privilegesDispatch({
      type,
      privilege: action,

      resources,
    });
  };

  const engineColumns = [
    {
      id: "modify",
      label: t("roles.engines.header.modify"),
      action: SqlRbacAction.ENGINE_MODIFY,
    },
    {
      id: "operate",
      label: t("roles.engines.header.operate"),
      action: SqlRbacAction.ENGINE_OPERATE,
    },
    {
      id: "usage",
      label: t("roles.engines.header.usage"),
      action: SqlRbacAction.ENGINE_USAGE,
    },
  ];

  const columns = [
    {
      id: "name",
      name: "Engine",
      width: 200,
      fillAvailableWidth: true,
      className: styles.cell__header,
    },
    ...engineColumns.map(({ id, label, action }) => {
      return {
        id,
        name: (
          <HeaderLabel
            label={label}
            checked={isHeaderChecked(action)}
            indeterminate={
              indeterminateByType[action as keyof typeof indeterminateByType]
            }
            handleSelect={handleSelectBulk(action)}
            disabled={isDisabled(action)}
            tooltip={getHeaderTooltip(t, RbacActionObject.ENGINE, action)}
            action={action}
          />
        ),
        width: 110,
        className: styles.cell__header,
      };
    }),
  ];

  const rowRenderer = ({ row }: { row: unknown }) => {
    const { engineName } = row as Engine;

    const enginePrivileges = privilegesState.engine[engineName] || {};

    const handleClick = (action: SqlRbacAction) => () => {
      const disabled = isDisabled(action);
      if (disabled) {
        return;
      }
      const checked = enginePrivileges[action];

      const type = checked
        ? DELETE_ENGINE_RESOURCE_PRIVILEGE
        : ADD_ENGINE_RESOURCE_PRIVILEGE;

      privilegesDispatch({
        type,
        privilege: action,
        resource: {
          id: engineName,
        },
      });
    };

    return (
      <div
        key={engineName}
        className={styles.row}
      >
        <Cell
          id="name"
          className={styles.cell}
          fillAvailableWidth
        >
          <span
            title={engineName}
            className={styles.cell__name}
          >
            {highlightText({
              searchWord: searchQuery,
              textToHighlight: engineName,
            })}
          </span>
        </Cell>
        {engineColumns.map(({ id, action }) => {
          return (
            <Cell
              key={id}
              id={id}
              className={styles.cell}
            >
              <Checkbox
                checked={
                  !!enginePrivileges[action] ||
                  checkedAllByType[action as keyof typeof checkedAllByType]
                }
                onClick={handleClick(action)}
                disabled={isDisabled(action)}
                testId={`engine-specific-role-${action}`}
              />
            </Cell>
          );
        })}
      </div>
    );
  };

  const getEmptyMessage = () => {
    if (searchQuery) {
      return t("roles.engines.empty_state");
    }
    if (affectedOnly) {
      return t("roles.engines.affected_empty_state");
    }
    return t("roles.engines.connected_empty_state");
  };

  if (!filtered.length) {
    const message = getEmptyMessage();
    return (
      <div className={styles.emptyState}>
        <EmptyEngineIcon />
        <div className={styles.emptyState__message}>{message}</div>
      </div>
    );
  }

  return (
    <Table
      rowRenderer={rowRenderer}
      rows={filtered as unknown[]}
      columns={columns}
      disableResize
    />
  );
};

export const EnginesList = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const { t } = useTranslation();

  const handleSearchInput = (value: string) => {
    setSearchQuery(value);
  };

  return (
    <>
      <div className={styles.search}>
        <SearchInput
          onChange={handleSearchInput}
          placeholder={t("roles.engines.search")}
          defaultValue={searchQuery}
          testId="engines-search-input"
          inputClassName={styles.search__input}
          debounceMs={300}
        />
      </div>
      <div
        className={styles.table}
        data-testid="engines-list"
      >
        <Suspense fallback={<LoadingOverlap isLoading />}>
          <EnginesTable searchQuery={searchQuery} />
        </Suspense>
      </div>
    </>
  );
};
