import { Paper, Popover } from "@mui/material";
import { useTranslation } from "react-i18next";

import { SupportAccessShortType } from "services/api/supportAccess";
import { useSupportAccessShort } from "services/api/supportAccess/useSupportAccessList";

import { ReactComponent as CloseIcon } from "assets/icons/Close.svg";
import { ReactComponent as RoleIcon } from "assets/icons/roleIcons/Role.svg";

import { useCurrentAccount } from "components/Account/useCurrentAccount";
import MaterialButton, { ButtonSize, ButtonTemplate } from "components/Button";
import { usePopover } from "components/Popover/usePopover";
import { SupportAgentStepsEnum } from "components/SupportAgent/supportAgentSteps";

import styles from "./styles.module.scss";

const SupportAccessConnectionContent = (props: {
  agent: SupportAccessShortType;
}) => {
  const { agent } = props;
  const { t } = useTranslation();
  const { getAccount } = useCurrentAccount();
  const account = getAccount();
  if (agent.expires_at) {
    const expire = new Date(agent.expires_at);

    const month = expire.toLocaleString("default", { month: "short" });
    return (
      <>
        {t("supportAgent.expire", {
          accountName: account.accountName,
          month,
          day: expire.getDate(),
          time: expire.toLocaleString("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          }),
        })}
      </>
    );
  }
  return (
    <>{t("supportAgent.connected", { accountName: account.accountName })}</>
  );
};

type Props = {
  openSupportAgentStep: (step: SupportAgentStepsEnum) => void;
};

export const SupportAccess = (props: Props) => {
  const { openSupportAgentStep } = props;
  const agent = useSupportAccessShort();
  const { anchorRef, open, openPopover, closePopover } = usePopover();

  const handleEdit = () => {
    openSupportAgentStep("menu");
  };

  if (agent) {
    return (
      <>
        <div
          onClick={openPopover}
          ref={anchorRef}
          className={styles.supportIcon}
        >
          <span className={styles.icon}>
            <RoleIcon />
          </span>
        </div>

        <Popover
          id="mouse-over-popover"
          anchorEl={anchorRef.current}
          open={open}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "center",
            horizontal: 10,
          }}
          onClose={closePopover}
          disableRestoreFocus
        >
          <Paper>
            <div className={styles.paper}>
              <SupportAccessConnectionContent agent={agent} />
              <MaterialButton
                text="Manage access"
                template={ButtonTemplate.Tertiary}
                size={ButtonSize.Tiny}
                onClick={() => {
                  closePopover();
                  handleEdit();
                }}
                dataTestid="edit-access"
                className={styles.editButton}
              />
              <button
                className={styles.closeButton}
                data-testid="close-modal-button"
              >
                <CloseIcon
                  className={styles.closeIcon}
                  onClick={closePopover}
                  data-testid="close-icon"
                />
              </button>
            </div>
          </Paper>
        </Popover>
      </>
    );
  }
  return null;
};
