import UserFriendlyAPIError from "services/environment/UserFriendlyAPIError";
import { systemEngineEnvironment } from "services/environment/systemEngine";
import { User } from "services/users/user.types";

import { getRolesStatement } from "./utils";

export interface UpdateUserInput {
  defaultDatabase?: string;
  defaultEngine?: string;
  roles: string[];
  initialRoles?: string[];
  userName?: string;
  loginName?: string;
  serviceAccountName?: string;
}

const getDatabasePart = (defaultDatabase?: string) => {
  if (defaultDatabase === undefined) {
    return "";
  }
  if (defaultDatabase === "") {
    return "DEFAULT_DATABASE = DEFAULT";
  }
  return `DEFAULT_DATABASE = "${defaultDatabase}"`;
};

const getEnginePart = (defaultEngine?: string) => {
  if (defaultEngine === undefined) {
    return "";
  }
  if (defaultEngine === "") {
    return "DEFAULT_ENGINE = DEFAULT";
  }
  return `DEFAULT_ENGINE = "${defaultEngine}"`;
};

function getAssocPart(parameterName: string, value: string | undefined) {
  if (value === undefined) {
    return "";
  }
  if (value) {
    return `${parameterName} = "${value}"`;
  }
  return `${parameterName} = DEFAULT`;
}

export const updateUser = async (
  accountId: string,
  userName: string,
  input: UpdateUserInput
): Promise<void> => {
  const {
    defaultDatabase,
    defaultEngine,
    userName: newUserName,
    loginName,
    serviceAccountName,
    roles,
    initialRoles,
  } = input;

  try {
    systemEngineEnvironment.setAccountContext(accountId);

    const database = getDatabasePart(defaultDatabase);

    const engine = getEnginePart(defaultEngine);

    const login = getAssocPart("LOGIN", loginName);
    const serviceAccount = getAssocPart("SERVICE_ACCOUNT", serviceAccountName);

    const hasUpdateChanges = database || engine || login || serviceAccount;

    if (hasUpdateChanges) {
      const updateCommand = `ALTER USER "${userName}" SET
${database}
${engine}
${login}
${serviceAccount};`;
      await systemEngineEnvironment.execute(updateCommand);
    }

    if (newUserName) {
      const renameCommand = `ALTER USER "${userName}" RENAME TO "${newUserName}";`;
      await systemEngineEnvironment.execute(renameCommand);
    }

    const userNameToQueryBy = newUserName || userName;
    const rolesStatement = getRolesStatement(
      userNameToQueryBy,
      initialRoles || [],
      roles
    );
    for (const statement of rolesStatement) {
      // eslint-disable-next-line no-await-in-loop
      await systemEngineEnvironment.execute(statement);
    }
  } catch (e) {
    if (e instanceof UserFriendlyAPIError) {
      throw e;
    }

    throw new Error("users.form.update.error");
  }
};

export const isUser = (user: User | {}): user is User => {
  return (
    Object.keys(user).includes("userName") ||
    Object.keys(user).includes("roles")
  );
};
