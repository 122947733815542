import React, { useCallback, useMemo, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

import { useCreditsFlag } from "featureFlags/hooks/useCreditsFlag";
import { setOrganizationOnboardingCompleted } from "services/organization/setOrganizationOnboarding";
import { isOrganizationApprovedQueryKey } from "services/organization/constants";

import { StatusMessageType } from "components/StatusMessageQueue/StatusMessageQueueProvider";
import useStatusMessageQueue from "components/StatusMessageQueue/hooks/useStatusMessageQueue";

import { WelcomeCreditsDescription } from "./CreditsWelcome/CreditsWelcome";
import { LoadData } from "./LoadData/LoadData";
import { Welcome } from "./Welcome/Welcome";
import { OnboardingContext } from "./context";
import { OnboardingStep, onboardingOrder } from "./types";

const Empty = () => {
  return null;
};

type PredicateParams = { showCredits?: boolean };

const onboardingMap: Record<
  OnboardingStep,
  {
    component: React.FC;
    filterPredicate?: (params: PredicateParams) => boolean;
  }
> = {
  [OnboardingStep.Empty]: {
    component: Empty,
  },
  [OnboardingStep.Welcome]: {
    component: Welcome,
  },
  [OnboardingStep.WelcomeCreditsDescription]: {
    component: WelcomeCreditsDescription,
    filterPredicate: (params: PredicateParams) => {
      return !!params.showCredits;
    },
  },
  [OnboardingStep.LoadData]: {
    component: LoadData,
  },
};

interface OnboardingProps extends React.PropsWithChildren {
  skip: boolean;
}

export const Onboarding = (props: OnboardingProps) => {
  const { children, skip } = props;
  const [step, setStep] = useState(OnboardingStep.Empty);
  const Component = onboardingMap[step].component;
  const { putStatusMessage } = useStatusMessageQueue();
  const navigate = useNavigate();
  const showCredits = useCreditsFlag();
  const queryClient = useQueryClient();

  const handleLastStep = useCallback(async () => {
    try {
      await setOrganizationOnboardingCompleted();
      queryClient.removeQueries({ queryKey: [isOrganizationApprovedQueryKey] });
    } catch (error: any) {
      putStatusMessage({
        message: error.response?.data?.detail || error.message,
        type: StatusMessageType.Error,
      });
    }
    setStep(OnboardingStep.Empty);
    navigate("/");
  }, [setStep, navigate, putStatusMessage, queryClient]);

  const nextStep = useCallback(() => {
    const currentIndex = onboardingOrder.indexOf(step);
    const availableSteps = onboardingOrder.filter(step => {
      const predicate = onboardingMap[step]?.filterPredicate;
      if (predicate) {
        return predicate({ showCredits });
      }
      return true;
    });
    const nextIndex = Math.min(currentIndex + 1, availableSteps.length);
    if (nextIndex === availableSteps.length) {
      handleLastStep();
    } else {
      const nextStep = onboardingOrder[nextIndex];
      setStep(nextStep);
    }
  }, [step, setStep, showCredits, handleLastStep]);

  const setOnboardingStep = useCallback((step: OnboardingStep) => {
    setStep(step);
  }, []);

  const context = useMemo(() => {
    return {
      step,
      onboardingOrder,
      nextStep,
      setOnboardingStep,
      skip
    };
  }, [nextStep, setOnboardingStep, step, skip]);

  return (
    <OnboardingContext.Provider value={context}>
      {children}
      {!skip && <Component />}
    </OnboardingContext.Provider>
  );
};
