import { useState } from "react";
import { useTranslation } from "react-i18next";

import { createUser } from "services/users/createUser";
import { CreateUserInput } from "services/users/user.types";

import { useCurrentAccount } from "components/Account/useCurrentAccount";
import { StatusMessageType } from "components/StatusMessageQueue/StatusMessageQueueProvider";
import useStatusMessageQueue from "components/StatusMessageQueue/hooks/useStatusMessageQueue";
import { queryClient } from "components/queryClient";

import { UserModal } from "../UserModal/UserModal";
import {usersQueryKey} from "../../../services/users/constants";

export const useCreateUser = () => {
  const [user, setCreateUser] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { getAccount } = useCurrentAccount();
  const account = getAccount();

  const { t } = useTranslation();

  const { putStatusMessage } = useStatusMessageQueue();

  const openCreateUser = () => {
    setCreateUser(true);
  };

  const closeCreateUser = () => {
    if (isLoading) {
      return;
    }
    setCreateUser(false);
  };

  const confirmCreate = async (user: CreateUserInput) => {
    try {
      setIsLoading(true);
      await createUser(account?.id as string, user);

      await queryClient.invalidateQueries({ queryKey: [usersQueryKey] });
      closeCreateUser();
      putStatusMessage({
        message: t("workspace.create_user.success"),
        type: StatusMessageType.Success,
      });
    } catch (error: any) {
      console.log(error);
      putStatusMessage({
        message: error.message,
        type: StatusMessageType.Error,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const createUserMarkup = user ? (
    <UserModal
      onClose={closeCreateUser}
      onSubmit={confirmCreate}
      isLoading={isLoading}
      title={t("workspace.create_user.title")}
      primaryButton="workspace.create_user.submit"
    />
  ) : null;

  return {
    createUserMarkup,
    openCreateUser,
  };
};
