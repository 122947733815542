import styles from "./styles.module.scss";

export const Cost = (props: { cost: number }) => {
  const { cost } = props;
  return (
    <div className={styles.cost}>
      <div>Cost per hour</div>
      <div className={styles.cost__value}>
        {cost}
        <span className={styles.cost__unit}> FBU</span>
      </div>
    </div>
  );
};
