import { zodResolver } from "@hookform/resolvers/zod";
import { Suspense } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { useCurrentAccount } from "components/Account/useCurrentAccount";
import { TextInput } from "components/TextInput/TextInput";
import Toggle from "components/Toggle";
import { Step } from "components/Wizard/Step/Step";

import { AuthSelector } from "../AuthSelector/AuthSelector";
import { SelectAWSStep, WizardDataType } from "../types";
import { Fields, validationSchema } from "./schema";
import { useEnsureEngineRunning } from "./useEnsureEngineRunning";

import styles from "./styles.module.scss";

const DOCS_URL =
  "https://docs.firebolt.io/godocs/Guides/loading-data/creating-access-keys-aws.html";

const getPlaygroundUrl = (region: string) => {
  return `s3://firebolt-sample-datasets-public-${region}/gaming/parquet/playstats/`;
};

const Subtitle = () => {
  const { t } = useTranslation();
  return (
    <div>
      {t("wizard.select_aws.subtitle")}
      <a
        className={styles.link}
        href={DOCS_URL}
        target="_blank"
        rel="noopener noreferrer"
        tabIndex={-1}
      >
        {t("wizard.select_aws.link")}
      </a>
    </div>
  );
};

type Props = {
  initialData: SelectAWSStep;
  onClose: () => void;
  onSubmit: (data: Fields) => void;
  onPrevStep: () => void;
  activeStepIndex: number;
  wizardData: WizardDataType;
};

const EnginePreload = (props: { wizardData: WizardDataType }) => {
  const { wizardData } = props;
  useEnsureEngineRunning(wizardData);
  return null;
};

export const SelectAWS = (props: Props) => {
  const {
    onClose,
    onSubmit,
    onPrevStep,
    initialData,
    activeStepIndex,
    wizardData,
  } = props;
  const { t } = useTranslation();
  const { getAccount } = useCurrentAccount();

  const form = useForm<Fields>({
    resolver: zodResolver(validationSchema),
    defaultValues: initialData,
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = form;

  const onExampleBucketToggle = (checked: boolean) => {
    if (checked) {
      const currentAccount = getAccount();
      const url = getPlaygroundUrl(currentAccount.region);
      setValue("playgroundBucket", true);
      setValue("key", "");
      setValue("secret", "");
      setValue("storageUrl", url);
    } else {
      setValue("playgroundBucket", false);
      setValue("storageUrl", "");
    }
  };

  const onFormSubmit = (data: Fields) => {
    onSubmit(data);
  };

  const playgroundBucket = watch("playgroundBucket");
  const body = (
    <div className={styles.rows}>
      <div className={styles.row}>
        <span className={styles.sample}>{t("wizard.select_aws.sample")}</span>
        <Toggle
          size="sm"
          dataTestId="sampe-bucket"
          checked={playgroundBucket}
          onChange={onExampleBucketToggle}
        />
      </div>
      <div className={styles.formControl}>
        <TextInput
          inputRootClassName={styles.inputRoot}
          placeholder={t("wizard.select_aws.storage_url_placeholder")}
          label={t("wizard.select_aws.storage_url")}
          testId="storage-url-field"
          {...register("storageUrl")}
          error={!!errors?.storageUrl}
          helperText={errors?.storageUrl?.message}
          disabled={playgroundBucket}
          required
        />
      </div>
      <AuthSelector
        form={form}
        playgroundBucket={playgroundBucket}
      />
      <Suspense fallback={null}>
        <EnginePreload wizardData={wizardData} />
      </Suspense>
    </div>
  );
  return (
    <Step
      title={t("wizard.select_aws.title")}
      subtitle={<Subtitle />}
      body={body}
      onClose={onClose}
      onSubmit={handleSubmit(onFormSubmit)}
      activeStepIndex={activeStepIndex}
      onPrevStep={onPrevStep}
    />
  );
};
