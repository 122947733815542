import classNames from "classnames";
import { useRef, useState } from "react";

import { ReactComponent as Minus } from "assets/icons/Minus.svg";
import { ReactComponent as PlusIcon } from "assets/icons/Plus.svg";

import styles from "./styles.module.scss";

type Props = {
  value: number;
  maxValue?: number;
  name: string;
  testId?: string;
  onChange: (value: number) => void;
};

const Ticker = (props: {
  disabled?: boolean;
  onDecrement: () => void;
  onIncrement: () => void;
}) => {
  const { disabled, onDecrement, onIncrement } = props;
  return (
    <div className={classNames(styles.ticker, { [styles.disabled]: disabled })}>
      <button
        className={classNames(styles.tickerItem)}
        onClick={event => {
          event.preventDefault();
          !disabled && onDecrement();
        }}
        data-testid="ticker-decrement"
      >
        <Minus />
      </button>
      <button
        className={styles.tickerItem}
        onClick={event => {
          event.preventDefault();
          !disabled && onIncrement();
        }}
        data-testid="ticker-increment"
      >
        <PlusIcon />
      </button>
    </div>
  );
};

export const NumberInput = (props: Props) => {
  const { value, maxValue = 128, onChange, name, testId } = props;
  const input = useRef<HTMLInputElement | null>(null);
  const [focus, setFocus] = useState(false);

  const handleChangeValue = (input: string | number) => {
    if (Number.isNaN(+input)) {
      return;
    }
    const clapped = input === "" ? 0 : Math.min(+input, maxValue);
    onChange(clapped);
  };

  const handleBlur = () => {
    const clapped = Math.max(Math.min(value, maxValue), 1);
    if (input.current) {
      input.current.value = `${clapped}`;
    }
    onChange(clapped);
    setFocus(false);
  };

  const handleFocus = () => {
    setFocus(true);
  };

  const handleDecrement = () => {
    const clapped = Math.max(value - 1, 1);
    if (input.current) {
      input.current.value = `${clapped}`;
    }
    handleChangeValue(clapped);
  };

  const handleIncrement = () => {
    const clapped = Math.min(value + 1, maxValue);
    if (input.current) {
      input.current.value = `${clapped}`;
    }
    handleChangeValue(clapped);
  };

  const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleChangeValue(event.target.value);
  };

  return (
    <div className={classNames(styles.wrapper, { [styles.focused]: focus })}>
      <input
        ref={input}
        type="text"
        autoComplete="off"
        defaultValue={value}
        name={name}
        onChange={handleChangeInput}
        onBlur={handleBlur}
        onFocus={handleFocus}
        className={styles.input}
        data-testid={testId}
      />
      <Ticker
        onDecrement={handleDecrement}
        onIncrement={handleIncrement}
      />
    </div>
  );
};
