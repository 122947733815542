import { InputAdornment, TextField } from "@mui/material";
import cn from "classnames";
import { useState } from "react";

import { ReactComponent as CrossIcon } from "assets/icons/Cross.svg";
import { ReactComponent as SearchIcon } from "assets/icons/SearchIcon.svg";

import styles from "./styles.module.scss";

type Props = {
  onChange: (val: string) => void;
  value: string;
  autoFocus?: boolean;
  noBorder?: boolean;
  size?: "mini" | "small" | "regular";
  placeholder?: string;
  testId?: string;
  className?: string;
};

export const useSearchHandlers = () => {
  const [search, setSearch] = useState("");

  const onChange = (value: string) => {
    setSearch(value);
  };
  return {
    search,
    onChange,
  };
};

export const Search = (props: Props) => {
  const {
    onChange,
    value,
    autoFocus,
    size = "regular",
    placeholder = "Search",
    testId,
    noBorder,
    className,
  } = props;

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = event => {
    const value = event.target.value;
    onChange(value);
  };

  const reset = () => {
    onChange("");
  };

  return (
    <div
      className={cn(styles.search, className, {
        [styles.small]: size === "small",
        [styles.mini]: size === "mini",
        [styles.noBorder]: noBorder,
      })}
    >
      <TextField
        classes={{
          root: styles.root,
        }}
        autoFocus={autoFocus}
        variant="outlined"
        size="small"
        placeholder={placeholder}
        onChange={handleChange}
        value={value}
        InputProps={{
          classes: {
            root: styles.inputContainer,
          },
          endAdornment: (
            <InputAdornment
              classes={{ root: styles.iconContainer }}
              position="end"
              disablePointerEvents={!value}
              onClick={reset}
            >
              {value ? <CrossIcon /> : <SearchIcon />}
            </InputAdornment>
          ),
        }}
        // eslint-disable-next-line react/jsx-no-duplicate-props
        inputProps={{
          "data-testid": testId,
        }}
      />
    </div>
  );
};
