import React, { useState } from "react";

import { ReactComponent as Add } from "assets/icons/Add.svg";
import { ReactComponent as Minus } from "assets/icons/Minus.svg";

import { ExecutionMetrics, Explain, Operator } from "../types";

import styles from "./styles.module.scss";

type Props = {
  explain: Explain;
  selectedNode: Operator | null;
};

const QuerySummary = (props: {
  explain: Explain;
  expanded: boolean;
  toggleExpand: () => void;
}) => {
  const { expanded, toggleExpand, explain } = props;
  return (
    <div
      className={styles.item}
      data-testid="query-summary"
    >
      <div className={styles.header}>
        <h2 className={styles.title}>Query summary</h2>
        {expanded ? (
          <Minus
            className={styles.expand}
            onClick={toggleExpand}
            data-testid="summary-collapse"
          />
        ) : (
          <Add
            className={styles.expand}
            onClick={toggleExpand}
            data-testid="summary-expand"
          />
        )}
      </div>
      {expanded && (
        <div className={styles.body}>
          <div className={styles.metric}>
            <div>
              Query id:
              <div className={styles.metricValue}>{explain.query_id}</div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const Metrics = (props: { metrics?: ExecutionMetrics }) => {
  const { metrics } = props;

  if (!metrics) {
    return null;
  }

  const { cpu_time_ms, thread_time_ms, output_cardinality } = metrics;
  return (
    <div className={styles.metrics}>
      {!Number.isNaN(cpu_time_ms) && (
        <div className={styles.metric}>
          CPU time: <span className={styles.metricValue}>{cpu_time_ms} ms</span>
        </div>
      )}
      {!Number.isNaN(thread_time_ms) && (
        <div className={styles.metric}>
          Thread time:
          <span className={styles.metricValue}>{thread_time_ms} ms</span>
        </div>
      )}
      <div className={styles.metric}>
        Output cardinality:
        <span className={styles.metricValue}>{output_cardinality}</span>
      </div>
    </div>
  );
};

const OutputTypes = (props: { output_types: string[] }) => {
  const { output_types } = props;
  return (
    <div className={styles.types}>
      Output types:
      <span className={styles.types__value}>[{output_types.join(", ")}]</span>
    </div>
  );
};

const OperationSummary = (props: {
  selectedNode: Operator | null;
  expanded: boolean;
}) => {
  const { selectedNode, expanded } = props;
  if (!selectedNode || !expanded) {
    return null;
  }
  const { annotations, operator_type, operator_id } = selectedNode;
  const { execution_metrics, output_types } = annotations;
  return (
    <div
      className={styles.item}
      data-testid="operation-summary"
    >
      <div className={styles.header}>
        <h2 className={styles.title}>{operator_type}</h2>
        <div className={styles.operator}>NODE {operator_id}</div>
      </div>
      <div className={styles.body}>
        <Metrics metrics={execution_metrics} />
        <OutputTypes output_types={output_types} />
      </div>
    </div>
  );
};

export const ExplainSummary = React.memo((props: Props) => {
  const { explain, selectedNode } = props;

  const [expanded, setExpanded] = useState(true);

  const toggleExpand = () => {
    setExpanded((expanded: boolean) => !expanded);
  };

  return (
    <div className={styles.sidebar}>
      <QuerySummary
        explain={explain}
        expanded={expanded}
        toggleExpand={toggleExpand}
      />
      <OperationSummary
        selectedNode={selectedNode}
        expanded={expanded}
      />
    </div>
  );
});
