import { Popover } from "@mui/material";
import cn from "classnames";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { ReactComponent as MenuIconHoriz } from "assets/icons/MenuIconHoriz.svg";
import { ReactComponent as TabCloseIcon } from "assets/icons/TabCloseIcon.svg";

import useActiveEditorView from "pages/DevelopWorkspace/contexts/ActiveEditorViewContext/hooks/useActiveEditorView";
import { getShortcutModifiers } from "pages/DevelopWorkspace/utils/shortcutModifiers";

import ContextMenu from "components/ContextMenu/ContextMenu";
import ContextMenuDivider from "components/ContextMenu/ContextMenuDivider";
import ContextMenuItem from "components/ContextMenu/ContextMenuItem";
import { exportScript } from "components/utils/scripts";

import HintTooltip from "../components/HintTooltip";
import UploadFileModal from "../components/UploadFileModal";

import styles from "./Tab.module.scss";

export enum TabStatus {
  Success = "success",
  Error = "error",
  Running = "running",
}

interface TabProps {
  title?: string;
  content?: string;
  isActive: boolean;
  onSelect: () => void;
  onClose: () => void;
  onDuplicate: () => void;
  onRenameSubmit: (newTitle: string) => void;
  isLoadingScript: boolean;
  isUnsaved: boolean;
  status?: TabStatus;
  onUpload: (files: File[]) => void;
}

const Tab = (props: TabProps) => {
  const {
    title,
    content,
    isActive,
    onSelect,
    onClose,
    onRenameSubmit,
    onDuplicate,
    isLoadingScript,
    isUnsaved,
    status,
    onUpload,
  } = props;

  const { formatText } = useActiveEditorView();
  const { t } = useTranslation();
  const anchorEl = useRef<HTMLDivElement | null>(null);

  const [importModalOpen, setImportModalOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [isRenaming, setIsRenaming] = useState(false);
  const [newTitle, setNewTitle] = useState(title || "");

  const startRenaming = () => {
    if (isLoadingScript) {
      return;
    }

    setIsRenaming(true);

    if (title) {
      setNewTitle(title);
    }
  };

  // TODO new title validation
  const handleRenameSubmit = () => {
    setIsRenaming(false);
    if (isLoadingScript) {
      return;
    }
    if (newTitle === title) {
      return;
    }

    onRenameSubmit(newTitle);
  };

  const renderRenamingBody = () => {
    return (
      <div className={styles.renamingModeBody}>
        <form
          onSubmit={e => {
            e.preventDefault();
            handleRenameSubmit();
          }}
        >
          <input
            type="text"
            // eslint-disable-next-line jsx-a11y/no-autofocus -- autofocus is ok here
            autoFocus
            onFocus={e => {
              e.target.select();
            }}
            className={styles.input}
            value={newTitle}
            onChange={e => setNewTitle(e.target.value)}
            data-testid="document-rename-input"
            onBlur={() => {
              // TODO check for double renaming as form has been submitted on enter
              handleRenameSubmit();
            }}
          />
        </form>
      </div>
    );
  };

  const renderBody = () => {
    const bodyContent = (
      <div
        className={styles.body}
        onDoubleClick={() => {
          startRenaming();
        }}
      >
        {isUnsaved && <div className={styles.unsavedIcon} />}

        <div className={styles.title}>{title}</div>

        <div className={styles.gradientBoundaryRight} />

        <div className={styles.iconWrapper}>
          {!isLoadingScript && (
            <div
              className={styles.menuIcon}
              onClick={e => {
                e.stopPropagation();
                setMenuOpen(true);
              }}
              data-testid="doc-tab-menu-icon"
            >
              <MenuIconHoriz />
            </div>
          )}

          <div
            className={styles.closeIcon}
            title="Close Tab"
            onClick={e => {
              e.stopPropagation();
              onClose();
            }}
            data-testid={`doc-tab-close-${title}`}
          >
            <TabCloseIcon />
          </div>
        </div>
      </div>
    );

    if (isActive) {
      return bodyContent;
    }

    return (
      <HintTooltip
        title={title || ""}
        content={content?.substring(0, 500) || ""}
      >
        {bodyContent}
      </HintTooltip>
    );
  };

  const formatShortcut = `${getShortcutModifiers().ctrlKey} + Shift + L`;

  return (
    <div
      className={cn(styles.root, {
        [styles.active]: isActive,
        [styles.menuOpened]: menuOpen,
      })}
      onClick={onSelect}
      ref={anchorEl}
    >
      {isRenaming ? renderRenamingBody() : renderBody()}

      <div
        className={cn(styles.statusLine, {
          [styles.success]: status === TabStatus.Success,
          [styles.error]: status === TabStatus.Error,
          [styles.running]: status === TabStatus.Running,
        })}
      />

      {menuOpen && (
        <Popover
          open={menuOpen}
          anchorEl={anchorEl.current}
          onClose={() => {
            setMenuOpen(false);
          }}
          onClick={e => {
            e.stopPropagation();
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <ContextMenu>
            {isActive && (
              <ContextMenuItem
                key="format"
                text="Format script"
                testId="format-script-item"
                onClick={e => {
                  e.stopPropagation();
                  setMenuOpen(false);

                  setTimeout(() => {
                    formatText();
                  }, 0);
                }}
                secondaryText={formatShortcut}
              />
            )}

            <ContextMenuItem
              key="rename"
              text="Rename script"
              testId="rename-script-item"
              onClick={e => {
                e.stopPropagation();
                setMenuOpen(false);

                setTimeout(() => {
                  startRenaming();
                }, 0);
              }}
            />

            <ContextMenuItem
              key="duplicate"
              text="Duplicate script"
              testId="duplicate-script-item"
              onClick={e => {
                e.stopPropagation();
                setMenuOpen(false);
                onDuplicate();
              }}
            />

            <ContextMenuItem
              key="export"
              disabled={!(content && title)}
              text="Export script"
              testId="export-script-item"
              onClick={() => {
                if (!(content && title)) {
                  return;
                }

                exportScript({
                  query: content,
                  name: title,
                });

                setMenuOpen(false);
              }}
            />

            <ContextMenuItem
              key="import"
              testId="import-script-item"
              text="Import script from file"
              onClick={() => {
                setImportModalOpen(true);
                setMenuOpen(false);
              }}
            />
            <ContextMenuDivider key="divider" />
            <ContextMenuItem
              key="delete"
              text="Delete script"
              testId="delete-script-item"
              onClick={e => {
                e.stopPropagation();
                setMenuOpen(false);
                onClose();
              }}
            />
          </ContextMenu>
        </Popover>
      )}

      {importModalOpen && (
        <UploadFileModal
          title={t("scripts.scripts_modal_title")}
          closeModal={() => setImportModalOpen(false)}
          handleUpload={files => {
            setImportModalOpen(false);
            onUpload(files);
          }}
        />
      )}
    </div>
  );
};

export default Tab;
