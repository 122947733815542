import React, { useCallback, useContext } from "react";

import CustomAccordion from "components/Accordion";
import {
  INCLUDE,
  SELECT_ALL,
  UNSELECT_ALL,
} from "components/Datagrid/Filters/actions";

import { Candidates } from "../Candidates";
import { FilterModalContext } from "../Context";
import { Search } from "../Search";
import { useCandidateSearch } from "../useCandidateSearch";
import { AccordionSummaryCollapsed } from "./AccordionSummary";

const accordionItemName = "SELECTORS_CONTENT";

type Props = {
  expandedItem: string;
  setExpandedItem: (itemName: string) => void;
};

export const SelectorsContent = (props: Props) => {
  const { expandedItem, setExpandedItem } = props;
  const { dispatch, allChecked, localFilters, items } =
    useContext(FilterModalContext);

  const { include } = localFilters;

  const { search, filteredItems, handleSearchChange } =
    useCandidateSearch(items);

  const allFilteredChecked = filteredItems.every(item =>
    include.itemsSet.has(item.value)
  );

  const handleSelectAll = useCallback(() => {
    if (allChecked) {
      dispatch({ type: UNSELECT_ALL });
      return;
    }

    if (filteredItems.length === items.length) {
      const type = allChecked ? UNSELECT_ALL : SELECT_ALL;

      dispatch({ type });
      return;
    }

    if (!allFilteredChecked) {
      filteredItems.forEach(item => {
        if (!include.itemsSet.has(item.value)) {
          dispatch({ type: INCLUDE, items, selectedItem: item });
        }
      });
      return;
    }

    filteredItems.forEach(item => {
      dispatch({ type: INCLUDE, items, selectedItem: item });
    });
  }, [allChecked, dispatch, filteredItems, items, include, allFilteredChecked]);

  const handleSelectItem = useCallback(
    (selectedItem: {}) => {
      dispatch({
        type: INCLUDE,
        items,
        selectedItem,
      });
    },
    [items, dispatch]
  );

  // todo: use this
  // const filteredItemsCount = filteredItems.reduce(
  //   (
  //     acc: number,
  //     item: {
  //       count: number;
  //     }
  //   ) => acc + item.count,
  //   0
  // );

  const expanded = expandedItem === accordionItemName;
  return (
    <CustomAccordion
      expanded={expanded}
      collapsedSummary={
        <AccordionSummaryCollapsed
          itemText="Selected"
          onClick={() => setExpandedItem(accordionItemName)}
          testId="selected-accordion-toggle"
        />
      }
      expandedSummary={
        <Search
          onChange={handleSearchChange}
          value={search}
        />
      }
      accordionDetails={
        <Candidates
          items={filteredItems}
          onSelect={handleSelectItem}
          filters={localFilters}
          allChecked={allChecked}
          search={search}
          checkAllChbChecked={
            filteredItems.length && include.itemsSet.size
              ? allFilteredChecked
              : allChecked
          }
          handleSelectAll={handleSelectAll}
        />
      }
    />
  );
};
