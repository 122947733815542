import classNames from "classnames";

import Checkbox from "components/Checkbox";
import Highlighter from "components/Highligher";

import styles from "./styles.module.scss";

interface ItemProps {
  style?: any;
  checked?: boolean;
  label: string;
  count?: number;
  onClick: () => void;
  search?: string;
  className?: string;
  labelClassName?: string;
  testId?: string;
}
export const Item = (props: ItemProps) => {
  const {
    style,
    checked,
    label,
    count,
    onClick,
    search,
    className,
    labelClassName,
    testId,
  } = props;

  return (
    <div
      className={classNames(styles.item, className)}
      onClick={onClick}
      style={style}
      data-testid={testId}
    >
      <span>
        <span className={classNames(styles.item__label, labelClassName)}>
          {search ? (
            <Highlighter
              searchWords={[search]}
              textToHighlight={label}
            />
          ) : (
            label
          )}
        </span>
        {count !== undefined && (
          <span className={styles.item__count}> [{count}]</span>
        )}
      </span>
      <Checkbox
        checked={!!checked}
        stopClickPropagation
        value="primary"
        testId="database-filters-item-checkbox"
        classes={{
          root: styles.checkboxRoot,
        }}
      />
    </div>
  );
};
