import { indentLess, insertTab } from "@codemirror/commands";
import { PostgreSQL, SQLDialect, sql } from "@codemirror/lang-sql";
import { ChangeSpec, Extension, StateEffect } from "@codemirror/state";
import { EditorView, keymap } from "@codemirror/view";
import { basicSetup } from "codemirror";
import debounce from "lodash/debounce";
import React, { useEffect, useMemo, useRef } from "react";
import { KeywordCase } from "sql-formatter";
import { createDebouncedSplitSQLWithDetails } from "utils/helpers/Query/splitSQLQuery";

import { CodeMirror } from "pages/DevelopWorkspace/Editor/CodeMirror/CodeMirror";
import { FIREBOLT_KEYWORDS } from "pages/DevelopWorkspace/Editor/Document/DocumentContent/DocumentEditor/constants";
import { SQLynxExtensions } from "pages/DevelopWorkspace/Editor/Document/DocumentContent/DocumentEditor/extensions/sqlynx_extension";
import {
  StatementGutterMarkerDecorator,
  StatementGutterMarkerEffect,
  StatementGutterMarkerState,
  findSliceAtPosition,
} from "pages/DevelopWorkspace/Editor/Document/DocumentContent/DocumentEditor/extensions/statementGutterMarker";
import { useSQLynx } from "pages/DevelopWorkspace/contexts/SQLynxContext/SQLynxLoader";
import { format } from "pages/DevelopWorkspace/utils/format-sql";

import { UpdateSQLynxScript } from "./extensions/sqlynx_processor";

interface DocumentEditorProps {
  initialContent: string;
  initialSelection: [number, number];
  onChange: (content: string, selection: [number, number]) => void;
  onCursorActivity: (selection: [number, number]) => void;
  onInit: (editor: EditorView) => void;
  onRun: (content: string) => void;
}

const asyncMemoizedSplitSQLWithDetails =
  createDebouncedSplitSQLWithDetails(100);

const DocumentEditor = (props: DocumentEditorProps) => {
  const {
    initialContent,
    initialSelection,
    onChange,
    onCursorActivity,
    onRun,
    onInit,
  } = props;

  const { script } = useSQLynx();

  // it's done to have relevant handler function as CM cannot update extentions on every component re-render
  const runHandler = useRef(onRun);
  const view = useRef<EditorView | null>(null);

  useEffect(() => {
    runHandler.current = onRun;
  }, [onRun]);

  useEffect(() => {
    return () => {
      view.current?.dispatch({
        changes: {
          from: 0,
          to: view.current.state.doc.toString().length,
          insert: "",
        },
      });
    };
  }, []);

  useEffect(() => {
    return () => {
      view.current?.destroy();
    };
  }, []);

  const handleViewWasCreated = (createdView: EditorView) => {
    view.current = createdView;

    if (!script) return;

    const changes: ChangeSpec[] = [];
    const effects: StateEffect<any>[] = [];

    effects.push(
      UpdateSQLynxScript.of({
        config: {
          showCompletionDetails: false,
        },
        scriptKey: 2,
        targetScriptVersion: 1,
        targetScript: script,
        scriptBuffers: {
          scanned: null,
          parsed: null,
          analyzed: null,
          destroy: () => {},
        },
        scriptCursor: null,
        focusedColumnRefs: null,
        focusedTableRefs: null,
        onUpdateScript: () => {},
        onUpdateScriptCursor: () => {},
      })
    );
    view.current.dispatch({ changes, effects });

    const selection = {
      anchor:
        initialSelection[0] > initialContent.length
          ? initialContent.length
          : initialSelection[0],
      head:
        initialSelection[1] > initialContent.length
          ? initialContent.length
          : initialSelection[1],
    };

    view.current.dispatch({
      changes: { from: 0, to: 0, insert: initialContent },
      selection,
      scrollIntoView: true,
    });

    createdView.focus();

    onInit(createdView);
  };

  const debouncedOnChange = useRef(
    debounce((content: string, selection: [number, number]) => {
      onChange(content, selection);
    }, 300)
  );

  const handleChangeExtension = useMemo(
    () =>
      EditorView.updateListener.of(update => {
        const hasStatementGutterEffect = update.transactions.some(tr =>
          tr.effects.some(e => e.is(StatementGutterMarkerEffect))
        );

        if (!hasStatementGutterEffect) {
          const text = update.state.doc;
          const sql = text.toString();
          const cursor = update.state.selection.ranges[0].from;

          asyncMemoizedSplitSQLWithDetails(sql).then(slices => {
            const slice = findSliceAtPosition(slices, cursor);

            if (
              view.current?.state.doc.eq(text) &&
              cursor === view.current?.state.selection.ranges[0].from
            ) {
              view.current?.dispatch({
                effects: StatementGutterMarkerEffect.of({
                  slice,
                }),
              });
            }
          });
        }

        if (update.docChanged) {
          const range = update.state.selection.ranges[0];

          debouncedOnChange.current(update.state.doc.toString(), [
            range.from,
            range.to,
          ]);
          return;
        }

        if (update && update.state.doc.toString()) {
          // only update the cursor position

          const prevRange = update.startState.selection.ranges[0];
          const range = update.state.selection.ranges[0];

          if (prevRange.from === range.from && prevRange.to === range.to) {
            return;
          }

          onCursorActivity([range.from, range.to]);
        }
      }),
    [onCursorActivity]
  );

  const extensions = useMemo<Extension[]>(
    () => [
      SQLynxExtensions,
      StatementGutterMarkerState,
      StatementGutterMarkerDecorator,
      handleChangeExtension,
      sql({
        dialect: SQLDialect.define({
          ...PostgreSQL.spec,
          keywords: FIREBOLT_KEYWORDS,
        }),
      }),
      EditorView.scrollMargins.of(() => {
        return {
          right: 32,
        };
      }),
      keymap.of([
        {
          key: "Tab",
          preventDefault: true,
          run: insertTab,
        },
        {
          key: "Shift-Tab",
          preventDefault: true,
          run: indentLess,
        },
        {
          key: "Alt-L", // Uppercase L means shift is pressed
          mac: "Cmd-L",
          run: view => {
            const text = view.state.doc.toString();

            format(text, {
              keywordCase: "preserve" as KeywordCase,
              linesBetweenQueries: 2,
            }).then(formatted => {
              if (typeof formatted === "string") {
                view.dispatch({
                  changes: { from: 0, to: text.length, insert: formatted },
                });
              }
            });

            return true;
          },
        },
        {
          key: "Ctrl-Shift-Enter",
          mac: "Cmd-Shift-Enter",
          run: view => {
            const slice = view.state.field(StatementGutterMarkerState).slice;

            if (slice) {
              runHandler.current(slice.text);
              // @ts-ignore
              window?.pendo?.track("shortcut_run_selected_query");
              return true;
            }

            return false;
          },
        },
        {
          key: "Ctrl-Enter",
          mac: "Cmd-Enter",
          run: view => {
            // get string from selection
            const range = view.state.selection.ranges[0];

            if (range.from === range.to) {
              runHandler.current(view.state.doc.toString());
              // @ts-ignore
              window?.pendo?.track("shortcut_run_document");
              return true;
            }

            const selectedText = view.state.doc.sliceString(
              range.from,
              range.to
            );
            runHandler.current(selectedText);
            // @ts-ignore
            window?.pendo?.track("shortcut_run_selected_text");
            return true;
          },
        },
      ]),
      basicSetup,
    ],
    [handleChangeExtension]
  );

  return (
    <CodeMirror
      extensions={extensions}
      viewWasCreated={handleViewWasCreated}
    />
  );
};

export default DocumentEditor;
