import { useQueryClient, useSuspenseQuery } from "@tanstack/react-query";

import { WorkspaceEngine } from "services/engines/engine.types";

import { Auth, File, getFilesByPath } from "./getFilesByPath";

export const useRootFiles = ({
  bucketName,
  bucketProtocol,
  rootFolder,
  engine,
  auth,
}: {
  engine: WorkspaceEngine | undefined;
  bucketName: string;
  bucketProtocol: string;
  rootFolder: string;
  auth: Auth;
}): File[] => {
  const { data } = useSuspenseQuery({
    queryKey: [
      "loadFiles",
      bucketName,
      rootFolder,
      auth.key,
      auth.secret,
      engine?.engineName,
    ],
    queryFn: () =>
      getFilesByPath({
        engine,
        bucketName,
        bucketProtocol,
        path: rootFolder,
        auth,
      }),
    gcTime: 0,
  });
  return data || [];
};

export const useFiles = ({
  engine,
  bucketName,
  bucketProtocol,
  auth,
}: {
  engine: WorkspaceEngine | undefined;
  bucketName: string;
  bucketProtocol: string;
  auth: Auth;
}) => {
  const queryClient = useQueryClient();

  const loadFiles = async (path: string) => {
    try {
      const data = await queryClient.fetchQuery({
        queryKey: [
          "loadFiles",
          bucketName,
          path,
          auth.key,
          auth.secret,
          engine?.engineName,
        ],
        queryFn: () =>
          getFilesByPath({ engine, bucketName, bucketProtocol, path, auth }),
      });
      return data;
    } catch (error) {
      console.log(error);
    }
  };

  return {
    loadFiles,
  };
};
