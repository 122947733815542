import classNames from "classnames";
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";

import { ReactComponent as Alert } from "assets/icons/Alert.svg";

import Checkbox from "components/Checkbox";
import ContextMenuItem from "components/ContextMenu/ContextMenuItem";
import OutlinedSelect from "components/OutlinedSelect";
import Toggle from "components/Toggle";
import Tooltip from "components/Tooltip";

import { EditingCell } from "./EditingCell";
import { WizardMapData } from "./types";

import styles from "../styles.module.scss";

type RowProps = {
  isParquet?: boolean;
  row: WizardMapData;
  testId: string;
  visibleColumns: string[];
  onChange: (rowIndex: number) => void;
  toggleNullable: (rowIndex: number) => void;
  togglePrimaryIndex: (rowIndex: number) => void;
  onChangeType: (rowIndex: number, type: string) => void;
  setEditingRow: Dispatch<SetStateAction<number | null>>;
  onChangeDestinationName: (row: number, name: string) => void;
  editingRow: number | null;
  index: number;
  data: WizardMapData[];
  indexes: number[];
};

const shouldShowColumn = (columnName: string, columns: string[]) => {
  return !columns.length || columns.includes(columnName);
};

const TYPES = [
  "text",
  "int",
  "bigint",
  "long",
  "real",
  "double",
  "bytea",
  "boolean",
  "numeric",
  "date",
  "timestamp",
  "timestamptz",
];

const NameCell = (props: {
  index: number;
  row: WizardMapData;
  setEditingRow: Dispatch<SetStateAction<number | null>>;
}) => {
  const { row, setEditingRow, index } = props;
  return (
    <div
      className={classNames(styles.column, styles.name, {
        [styles.error]: row.error,
      })}
      onClick={() => setEditingRow(index)}
      data-action="edit"
    >
      <span>{row.overrideName || row.name}</span>
      {row.error && (
        <Tooltip
          placement="top"
          title={row.error}
        >
          <span className={styles.error__icon}>
            <Alert />
          </span>
        </Tooltip>
      )}
    </div>
  );
};

export const Row = ({
  row,
  onChange,
  visibleColumns,
  toggleNullable,
  onChangeType,
  togglePrimaryIndex,
  isParquet,
  setEditingRow,
  onChangeDestinationName,
  testId,
  data,
  index,
  editingRow,
  indexes,
}: RowProps) => {
  const { t } = useTranslation();
  return (
    <div
      className={classNames(styles.row, {
        [styles.excluded]: !row.included,
      })}
    >
      <div className={styles.checkboxCol}>
        <Checkbox
          checked={row.included}
          onChange={() => onChange(index)}
          testId={`${testId}-checkbox`}
        />
      </div>
      {shouldShowColumn("name", visibleColumns) &&
        (editingRow === index ? (
          <EditingCell
            data={data}
            editingRow={editingRow}
            setEditingRow={setEditingRow}
            onChangeDestinationName={onChangeDestinationName}
          />
        ) : (
          <NameCell
            row={row}
            setEditingRow={setEditingRow}
            index={index}
          />
        ))}
      {shouldShowColumn("type", visibleColumns) && (
        <div className={classNames(styles.column, styles.type)}>
          <OutlinedSelect
            testId={`${testId}-select-type`}
            onSelect={([value]) => onChangeType(index, value as string)}
            initialSelected={[row.type.split(" ")[0]]}
            disabled={isParquet && !row.additionalColumn}
            className={styles.typeSelect}
            wrapperClassName={styles.typeSelectWrapper}
            noBorder
          >
            {TYPES.map(t => {
              const type = row.type.split(" ")[0];
              return (
                <ContextMenuItem
                  value={t}
                  key={t}
                  text={t}
                  checked={t === type}
                />
              );
            })}
          </OutlinedSelect>
        </div>
      )}
      {shouldShowColumn("nullable", visibleColumns) && (
        <div className={classNames(styles.column, styles.nullable)}>
          <Toggle
            checked={row.type.includes("null")}
            onChange={() => toggleNullable(index)}
            size="sm"
            dataTestId={`${testId}-toggle-nullable`}
          />
        </div>
      )}
      {shouldShowColumn("primaryIndex", visibleColumns) && (
        <div className={classNames(styles.column, styles.nullable)}>
          <Toggle
            checked={indexes.includes(index)}
            onChange={() => togglePrimaryIndex(index)}
            size="sm"
            dataTestId={`${testId}-toggle-primary-index`}
          />

          {indexes.length > 1 && indexes.indexOf(index) !== -1 && (
            <Tooltip
              arrow={false}
              enterDelay={500}
              enterNextDelay={500}
              placement="top"
              title={t("wizard.map_data.primary_index_tooltip")}
            >
              <span className={styles.order}>{indexes.indexOf(index) + 1}</span>
            </Tooltip>
          )}
        </div>
      )}
      {shouldShowColumn("dataPreview", visibleColumns) && (
        <div className={classNames(styles.column, styles.dataPreview)}>
          {row.dataPreview}
        </div>
      )}
    </div>
  );
};
