import React from "react";

import { HeaderName } from "./HeaderName/HeaderName";

import styles from "./styles.module.scss";

interface HeaderCollapsedProps {
  column: any;
  index: number;
  isDragging: boolean;
  dragOffset: number;
  onPointerDown: (event: React.PointerEvent<HTMLDivElement>) => void;
  handleDoubleClick: () => void;
}

const HeaderCollapsed = React.memo<any>((props: HeaderCollapsedProps) => {
  const {
    column,
    index,
    isDragging,
    dragOffset,
    onPointerDown,
    handleDoubleClick,
  } = props;
  return (
    <HeaderName
      index={index}
      column={column}
      isDragging={isDragging}
      dragOffset={dragOffset}
      onPointerDown={onPointerDown}
      handleDoubleClick={handleDoubleClick}
    />
  );
});

interface HeaderItemProps {
  isDragging: boolean;
  dragOffset: number;
  column: any;
  index: number;
  style: React.CSSProperties;
  onPointerDown: (event: React.PointerEvent<HTMLDivElement>) => void;
  onMouseDown: (event: React.MouseEvent<HTMLDivElement>) => void;
  handleResizeDoubleClick: (index: number, column: any) => void;
}

export const HeaderItem = (props: HeaderItemProps) => {
  const {
    isDragging,
    dragOffset,
    column,
    index,
    style,
    onPointerDown,
    onMouseDown,
    handleResizeDoubleClick,
  } = props;

  const handleDoubleClick = () => {
    handleResizeDoubleClick(index, column);
  };

  const resizeProps = {
    isDragging,
    dragOffset,
    onPointerDown,
    handleDoubleClick,
  };

  return (
    <div
      data-testid="datagrid-header-cell"
      className={styles.header__column}
      style={style}
      onMouseDown={onMouseDown}
    >
      <HeaderCollapsed
        column={column}
        index={index}
        {...resizeProps}
      />
    </div>
  );
};
