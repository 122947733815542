import { useEffect, useRef } from "react";

import { Database } from "services/databases/useDatabasesNames";
import { WorkspaceEngine } from "services/engines/engine.types";
import { SqlRbacAction } from "services/rbac/action";
import { useCurrentUser } from "services/users/useCurrentUser";

import { useDocuments } from "pages/DevelopWorkspace/contexts/DocumentsContext/hooks/useDocuments";
import { WorkspaceDocument } from "pages/DevelopWorkspace/workspace.types";

import { useAccessManager } from "components/App/accessManager";

import { NONE } from "./types";

export const useAutoSetContext = ({
  engines,
  databases,
  document,
}: {
  engines: WorkspaceEngine[];
  databases: Database[];
  document: WorkspaceDocument;
}) => {
  const { isAllowedTo } = useAccessManager();
  const currentUser = useCurrentUser();
  const {
    actions: { changeDocumentContext },
  } = useDocuments();

  const dependencies = useRef({
    id: document.id,
    engines,
    databases,
    engineName: document.context.engineName,
    databaseName: document.context.databaseName,
    changeDocumentContext,
    isAllowedTo,
    currentUser,
  });

  useEffect(() => {
    dependencies.current = {
      id: document.id,
      engines,
      databases,
      engineName: document.context.engineName,
      databaseName: document.context.databaseName,
      changeDocumentContext,
      isAllowedTo,
      currentUser,
    };
  });

  useEffect(() => {
    const {
      id,
      engines,
      databases,
      engineName,
      databaseName,
      changeDocumentContext,
      isAllowedTo,
      currentUser,
    } = dependencies.current;
    const withoutSystem = engines.filter(
      engine => engine.engineName !== "system"
    );
    const update = {};
    const getEngineContext = () => {
      if (currentUser?.defaultEngine) {
        const engine = engines.find(
          engine => engine.engineName === currentUser.defaultEngine
        );
        if (
          engine &&
          isAllowedTo(
            "engine",
            engine.engineName,
            SqlRbacAction.ENGINE_OPERATE,
            { owner: engine.engineOwner }
          )
        ) {
          return { engineName: engine.engineName };
        }
      }
      if (withoutSystem.length === 1) {
        const engine = withoutSystem[0];
        if (
          isAllowedTo(
            "engine",
            engine.engineName,
            SqlRbacAction.ENGINE_OPERATE,
            { owner: engine.engineOwner }
          )
        ) {
          return {
            engineName: engine.engineName,
          };
        }
      }
      return {};
    };

    const getDatabaseContext = () => {
      if (currentUser?.defaultDatabase) {
        const database = databases.find(
          database => database.catalogName === currentUser.defaultDatabase
        );
        if (
          database &&
          isAllowedTo(
            "database",
            database.catalogName,
            SqlRbacAction.DATABASE_USAGE
          )
        ) {
          return { databaseName: database.catalogName };
        }
      }
      if (databases.length === 1) {
        const database = databases[0];
        if (
          isAllowedTo(
            "database",
            database.catalogName,
            SqlRbacAction.DATABASE_USAGE
          )
        ) {
          return { databaseName: database.catalogName };
        }
      }
      return {};
    };

    if (!engineName || engineName === "system") {
      Object.assign(update, getEngineContext());
    }

    if (!databaseName || databaseName === NONE) {
      Object.assign(update, getDatabaseContext());
    }

    Object.keys(update).length && changeDocumentContext(id, update);
  }, []);
};
