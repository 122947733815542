import classNames from "classnames";
import { Suspense, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import ErrorMessage from "pages/DevelopWorkspace/Editor/Document/DocumentOutput/DocumentResults/ErrorMessage/ErrorMessage";

import { SimpleErrorBoundary } from "components/ErrorBoundary/SimpleErrorBoundary";
import LoadingOverlap from "components/LoadingOverlap";
import { Sidebar } from "components/Wizard/Sidebar/Sidebar";
import { Step } from "components/Wizard/Step/Step";
import { Wizard } from "components/Wizard/Wizard";

import { EngineSelector } from "./EngineSelector/EngineSelector";
import { PreviewScript } from "./PreviewScript/PreviewScript";
import stepsByType from "./Steps";
import { steps } from "./Steps/constants";
import { IngestionStep, InitialDataType, WizardDataType } from "./types";

import styles from "./styles.module.scss";

type Props = {
  onClose: () => void;
};

type ComponentTypeProps = {
  onClose: () => void;
  onSubmit: (data: any) => void;
  onPrevStep: () => void;
  initialData: any;
  wizardData: WizardDataType;
  activeStepIndex: number;
  updateEngine: (value: string) => void;
  toggleEngineSelectorEnabled: (value: boolean) => void;
};

export const IngestionWizard = (props: Props) => {
  const { onClose } = props;
  const { t } = useTranslation();
  const [showScript, setShowScript] = useState(false);
  const [activeStep, setActiveStep] = useState<IngestionStep>(
    IngestionStep.selectEngine
  );
  const [engineSelectorEnabled, setEngineSelectorEnabled] =
    useState<boolean>(true);
  const [wizardData, setWizardData] = useState<WizardDataType>({
    [IngestionStep.selectAWS]: {
      authType: "secret",
      key: "",
      secret: "",
      iamRole: "",
      storageUrl: "",
    },
    [IngestionStep.selectData]: {
      selectedFiles: new Set(),
      files: [],
    },
  });

  const activeStepIndex = steps.findIndex(step => step.id === activeStep);

  const handleNextStep = (data: InitialDataType) => {
    setWizardData(wizardData => ({ ...wizardData, [activeStep]: data }));
    const nextIndex = Math.min(activeStepIndex + 1, steps.length);
    setActiveStep(steps[nextIndex].id);
  };

  const handlePreviousStep = () => {
    const previousIndex = Math.max(0, activeStepIndex - 1);
    setActiveStep(steps[previousIndex].id);
  };

  const handleSelectStep = (step: IngestionStep) => {
    const stepIndex = steps.findIndex(s => s.id === step);
    if (stepIndex === undefined || stepIndex > activeStepIndex) {
      return;
    }
    if (step !== IngestionStep.mapData) {
      setWizardData(wizardData => ({
        ...wizardData,
        [IngestionStep.mapData]: undefined,
      }));
    }
    setActiveStep(step);
    setShowScript(false);
  };

  const updateEngine = (engineName: string) => {
    setWizardData(wizardData => {
      return {
        ...wizardData,
        [IngestionStep.selectEngine]: {
          engine: {
            engineName,
          },
        },
      };
    });
  };

  const toggleEngineSelectorEnabled = (value: boolean) => {
    setEngineSelectorEnabled(value);
  };

  const handleShowScript = () => {
    setShowScript(showScript => !showScript);
  };

  const StepComponent: React.ComponentType<ComponentTypeProps> = useMemo(
    () => stepsByType[activeStep],
    [activeStep]
  );

  return (
    <Wizard
      title={t("wizard.title")}
      subtitle={t("workspace.load_data.subtitle")}
      header={
        wizardData[IngestionStep.selectEngine]?.engine?.engineName && (
          <EngineSelector
            wizardData={wizardData}
            updateEngine={updateEngine}
            setActiveStep={setActiveStep}
            disabled={!engineSelectorEnabled}
          />
        )
      }
      body={
        <>
          <Sidebar<IngestionStep>
            activeStep={activeStep}
            activeStepIndex={activeStepIndex}
            handleSelectStep={handleSelectStep}
            handleShowScript={handleShowScript}
            showScript={showScript}
            steps={steps}
          />
          <div
            className={classNames(styles.content, {
              [styles.showScript]: showScript,
            })}
          >
            <SimpleErrorBoundary
              renderError={error => (
                <Step
                  title={t("wizard.select_aws.title")}
                  subtitle={null}
                  body={<ErrorMessage errorMessage={error.message} />}
                  bodyClassName={styles.errorBody}
                  onClose={onClose}
                  onSubmit={() => {}}
                  activeStepIndex={activeStepIndex}
                  onPrevStep={handlePreviousStep}
                  disabledSubmit
                />
              )}
              key={`step-component-${activeStep}`}
            >
              <Suspense fallback={<LoadingOverlap isLoading />}>
                <StepComponent
                  onClose={onClose}
                  onSubmit={handleNextStep}
                  onPrevStep={handlePreviousStep}
                  initialData={wizardData[activeStep]}
                  wizardData={wizardData}
                  activeStepIndex={activeStepIndex}
                  updateEngine={updateEngine}
                  toggleEngineSelectorEnabled={toggleEngineSelectorEnabled}
                />
              </Suspense>
            </SimpleErrorBoundary>
            {showScript && (
              <SimpleErrorBoundary
                renderError={error => (
                  <Step
                    title={t("wizard.select_aws.title")}
                    subtitle={null}
                    body={<ErrorMessage errorMessage={error.message} />}
                    onClose={onClose}
                    onSubmit={() => {}}
                    activeStepIndex={activeStepIndex}
                    onPrevStep={handlePreviousStep}
                    disabledSubmit
                    bodyClassName={styles.errorBody}
                  />
                )}
                key={`preview-script-${activeStep}`}
              >
                <Suspense fallback={<LoadingOverlap isLoading />}>
                  <PreviewScript
                    activeStepIndex={activeStepIndex}
                    wizardData={wizardData}
                  />
                </Suspense>
              </SimpleErrorBoundary>
            )}
          </div>
        </>
      }
      onClose={onClose}
    />
  );
};
