import { useEffect } from "react";
import { useQueryClient, useSuspenseQuery } from "@tanstack/react-query";

import { WorkspaceEngineStatus } from "services/engines/engine.types";
import { startWorkspaceEngine } from "services/engines/startWorkspaceEngine";
import { useWorkspaceEngines } from "services/engines/useWorkspaceEngines";
import { workspaceEnginesQueryKey } from "services/engines/constants";

import { StatusMessageType } from "components/StatusMessageQueue/StatusMessageQueueProvider";
import useStatusMessageQueue from "components/StatusMessageQueue/hooks/useStatusMessageQueue";

import { IngestionStep, WizardDataType } from "../types";

export const useEnsureEngineRunning = (wizardData: WizardDataType) => {
  const { engine } = wizardData[IngestionStep.selectEngine] || {};
  const { data: engines = [] } = useWorkspaceEngines();
  const selectedEngine = engines.find(e => e.engineName === engine?.engineName);
  const { putStatusMessage } = useStatusMessageQueue();
  const queryClient = useQueryClient();

  const { isError, error } = useSuspenseQuery({
    queryKey: ["ensureRunning", selectedEngine?.engineName],
    queryFn: async () => {
      if (
        selectedEngine &&
        selectedEngine.engineName &&
        selectedEngine.status === WorkspaceEngineStatus.STOPPED
      ) {
        await startWorkspaceEngine(selectedEngine.engineName);
      }
      return null;
    },
    gcTime: 0,
    retry: false,
  });

  useEffect(() => {
    if (isError) {
      putStatusMessage({
        message: error?.message,
        type: StatusMessageType.Error,
      });
      queryClient.invalidateQueries({ queryKey: [workspaceEnginesQueryKey] });
    }
  }, [isError, error, putStatusMessage, queryClient]);
};
