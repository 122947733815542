import { useTranslation } from "react-i18next";

import formatSql from "services/sqlFormat/formatter";

import { ReactComponent as ViewIcon } from "assets/icons/workspace/View.svg";

import useActiveEditorView from "pages/DevelopWorkspace/contexts/ActiveEditorViewContext/hooks/useActiveEditorView";
import { useDocuments } from "pages/DevelopWorkspace/contexts/DocumentsContext/hooks/useDocuments";

import { useMenu } from "components/ActionMenu/useMenu";
import { RecordLabelWithIcon } from "components/LeftSidebar/RecordLabelWithIcon";
import { Menu } from "components/LeftSidebar/RecordMenu/RecordMenu";
import { TreeNodeType } from "components/Tree/types";

import { DatabaseMenuActions } from "../types";

import styles from "./styles.module.scss";

type Props = {
  node: TreeNodeType;
  actions: DatabaseMenuActions;
};

export const ViewLabel = (props: Props) => {
  const { node, actions } = props;
  const { label, payload } = node;
  const { openViewDefinitions } = actions;
  const {
    state: { activeDocumentId },
  } = useDocuments();
  const { insertTextAtCursorPosition } = useActiveEditorView();
  const menu = useMenu();
  const { t } = useTranslation();

  const items = [
    [
      {
        key: "insert",
        text: t("workspace.view_menu.insert"),
        action: () => {
          if (activeDocumentId) {
            insertTextAtCursorPosition(label);
          }
        },
        testId: "insert-view-in-editor-item",
      },
      {
        key: "view",
        text: t("workspace.view_menu.view_definition"),
        action: () => {
          const value = formatSql(payload.viewDefinition);
          openViewDefinitions({
            value,
            title: "View definition",
          });
        },
        testId: "view-view-definition-item",
      },
    ],
  ];

  return (
    <>
      <RecordLabelWithIcon
        noIndent
        icon={<ViewIcon />}
        label={<span className={styles.columnLabel}>{label}</span>}
        testId={`view-label-${label}`}
      />
      <Menu
        testId="view-more-icon"
        items={items}
        menu={menu}
      />
    </>
  );
};
