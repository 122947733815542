import { useFlags } from "launchdarkly-react-client-sdk";

import { FeatureFlag } from "../constants";

const FEATURE_FLAG_DEFAULTS = {
  [FeatureFlag.FireboltAppEnableEditRunningScript]: true,
  [FeatureFlag.FireboltAppEnableContactSupportFormLoggedIn]: true,
  [FeatureFlag.FireboltAppBypassQueryQueue]: true,
  [FeatureFlag.FireboltAppQueryExplain]: true,
  [FeatureFlag.FireboltAppSpotInstances]: false,
  [FeatureFlag.FireboltAppDefaultHybridQueryExecution]: true,
};

export const useAllFlags = () => {
  const flags = useFlags<Partial<FlagsInterface>>();
  return {
    ...FEATURE_FLAG_DEFAULTS,
    ...flags,
  };
};

export interface FlagsInterface {
  [FeatureFlag.FireboltAppReadOnlyMaintenanceMode]: boolean | undefined;
  [FeatureFlag.FireboltAppFullMaintenanceMode]: boolean | undefined;
  [FeatureFlag.FireboltAppSpotInstances]: boolean | undefined;
  [FeatureFlag.FireboltAppQueryExplain]: boolean | undefined;
  [FeatureFlag.FireboltAppBypassQueryQueue]: boolean | undefined;
  [FeatureFlag.FireboltAppUseSpotInstancesByDefault]: boolean | undefined;
  [FeatureFlag.FireboltRBACEnabled]: boolean | undefined;
  [FeatureFlag.FireboltAppRegisterCustomOrgNameEnabled]: boolean | undefined;
  [FeatureFlag.FireboltAppEnableContactSupportFormLoggedIn]:
    | boolean
    | undefined;
  [FeatureFlag.ServiceIdentityBillingEnableRegisterWithCredits]:
    | boolean
    | undefined;
  [FeatureFlag.FireboltAppEnableQueryProfile]: boolean | undefined;
  [FeatureFlag.FireboltAppEnableEngineObservAndQueryHistory]:
    | boolean
    | undefined;
  [FeatureFlag.FireboltAppEnableEngineClusterProperty]: boolean | undefined;
  [FeatureFlag.FireboltAppEnableBillingV2]: boolean | undefined;
  [FeatureFlag.FireboltAppEnableIngestionWizard]: boolean | undefined;
  [FeatureFlag.FireboltAppEnableRoleWizard]: boolean | undefined;
  [FeatureFlag.FireboltAppEnableLargeInstanceTypes]: boolean | undefined;
  [FeatureFlag.FireboltAppShowRolesPage]: boolean | undefined;
  [FeatureFlag.FireboltAppEnableRegistrationPage]: boolean | undefined;
  [FeatureFlag.FireboltAppEnableReadCsvNewParams]: boolean | undefined;
  [FeatureFlag.FireboltAppEnableDemoTool]: boolean | undefined;
  [FeatureFlag.FireboltAppEnableIsCreditsForStorageBilling]:
    | boolean
    | undefined;
}
