import { Popover } from "@mui/material";
import classNames from "classnames";

import { ReactComponent as MoreVerticalIcon } from "assets/icons/MoreVertical.svg";

import { useMenu } from "components/ActionMenu/useMenu";
import ContextMenu from "components/ContextMenu/ContextMenu";
import ContextMenuDivider from "components/ContextMenu/ContextMenuDivider";
import ContextMenuItem from "components/ContextMenu/ContextMenuItem";

import styles from "../styles.module.scss";

const stopPropagation = (event: React.SyntheticEvent) =>
  event.stopPropagation();

type Props = {
  items: {
    key: string;
    text: string;
    action: () => void;
    disabled?: boolean;
    shortcut?: string;
    testId?: string;
  }[][];
  menu: ReturnType<typeof useMenu>;
  testId?: string;
};

export const Menu = (props: Props) => {
  const { items, menu, testId } = props;
  const { openMenu, closeMenu, menuElement, iconElement } = menu;

  if (!items.length) {
    return null;
  }

  const menuItems = items.map(group => {
    return group.map(item => {
      const { key, text, action, shortcut, disabled, testId } = item;

      const handleClick = () => {
        closeMenu();
        action();
      };

      return (
        <ContextMenuItem
          key={key}
          text={text}
          onClick={handleClick}
          secondaryText={shortcut}
          disabled={disabled}
          testId={testId}
        />
      );
    });
  });

  return (
    <div
      onClick={stopPropagation}
      onMouseOver={stopPropagation}
      className={styles.action}
    >
      <div
        className={styles.menuIconContainer}
        ref={iconElement}
        onClick={openMenu}
        data-testid={testId}
      >
        <MoreVerticalIcon
          className={classNames(styles.action__icon, {
            [styles.activeActionIcon]: !!menuElement,
          })}
        />
      </div>

      {Boolean(menuElement) && (
        <Popover
          onClose={closeMenu}
          open
          anchorEl={menuElement}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          role="menu"
        >
          <ContextMenu>
            {menuItems.reduce((acc, item, index) => {
              if (index > 0) {
                acc.push(<ContextMenuDivider key="divider" />, ...item);
              } else {
                acc.push(...item);
              }
              return acc;
            })}
          </ContextMenu>
        </Popover>
      )}
    </div>
  );
};
