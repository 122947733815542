import { useSuspenseQuery } from "@tanstack/react-query";

import { systemEngineEnvironment } from "services/environment/systemEngine";

type Table = {
  tableName: string;
  tableType: string;
};

export const useTableNames = ({ database }: { database: string }) => {
  const { data } = useSuspenseQuery<Table[], Error>({
    queryKey: ["tableNames", database],
    queryFn: async () => {
      const result = await systemEngineEnvironment.execute<Table>(
        `select tb.table_name, tb.table_type from information_schema.tables tb where tb.table_type IN ('BASE TABLE', 'FACT', 'DIMENSION')`,
        { database }
      );

      const [response] = result;
      return response.rows;
    }
  });
  return data || [];
};