import { useTranslation } from "react-i18next";

import formatSql from "services/sqlFormat/formatter";

import { ReactComponent as DimensionTableIcon } from "assets/icons/workspace/DimensionTable.svg";
import { ReactComponent as ExternalTableIcon } from "assets/icons/workspace/ExternalTable.svg";
import { ReactComponent as FactTableIcon } from "assets/icons/workspace/FactTable.svg";
import { ReactComponent as TableIcon } from "assets/icons/workspace/Table.svg";

import useActiveEditorView from "pages/DevelopWorkspace/contexts/ActiveEditorViewContext/hooks/useActiveEditorView";
import useActiveDocument from "pages/DevelopWorkspace/contexts/DocumentsContext/hooks/useActiveDocument";
import { useDocuments } from "pages/DevelopWorkspace/contexts/DocumentsContext/hooks/useDocuments";

import { useMenu } from "components/ActionMenu/useMenu";
import { RecordLabelWithIcon } from "components/LeftSidebar/RecordLabelWithIcon";
import { Menu } from "components/LeftSidebar/RecordMenu/RecordMenu";
import Spinner from "components/Spinner";
import { TreeNodeType } from "components/Tree/types";

import { DatabaseMenuActions } from "../types";

import styles from "./styles.module.scss";

type Props = {
  node: TreeNodeType;
  loading?: boolean;
  actions: DatabaseMenuActions;
};

const iconMap = {
  EXTERNAL: ExternalTableIcon,
  DIMENSION: DimensionTableIcon,
  "BASE TABLE": FactTableIcon,
  default: TableIcon,
};

export const TableLabel = (props: Props) => {
  const { node, loading, actions } = props;
  const { openViewDefinitions } = actions;
  const { label, payload } = node;
  const { columns } = payload;
  const menu = useMenu();
  const { insertTextAtCursorPosition } = useActiveEditorView();
  const { t } = useTranslation();
  const {
    actions: { createDocument },
  } = useDocuments();
  const activeDocument = useActiveDocument();

  const tableColumns = columns || [];
  const items = [
    [
      {
        key: "insert",
        text: t("workspace.table_menu.insert"),
        shortcut: "Shift + click",
        action: () => {
          if (activeDocument) {
            insertTextAtCursorPosition(node.label);
          }
        },
        testId: "insert-table-in-editor-item",
      },
      {
        key: "insert_columns",
        disabled: !tableColumns.length,
        text: t("workspace.table_menu.insert_columns"),
        action: () => {
          if (activeDocument && tableColumns.length) {
            insertTextAtCursorPosition(tableColumns.join(", "));
          }
        },
        testId: "insert-table-columns-in-editor-item",
      },
      {
        key: "view",
        text: t("workspace.table_menu.view_definition"),
        action: () => {
          const { payload } = node;
          const value = formatSql(payload.ddl);
          openViewDefinitions({
            title: "Table definition",
            value,
          });
        },
        testId: "view-table-definition-item",
      },
      {
        key: "preview",
        text: t("workspace.table_menu.preview"),
        action: () => {
          const databaseName = node.databaseName;

          if (activeDocument) {
            createDocument(
              {
                title: node.label,
                content: `select * from ${node.label} limit 100`,
                context: {
                  databaseName,
                  engineName: activeDocument.context.engineName,
                },
              },
              {
                autoRun: true,
              }
            );
          }
        },
        testId: "table-preview-item",
      },
    ],
  ];

  const IconComponent =
    iconMap[node.payload.tableType as keyof typeof iconMap] || iconMap.default;

  const icon = loading ? (
    <div className={styles.loading}>
      <Spinner size={12} />
    </div>
  ) : (
    <IconComponent />
  );

  return (
    <>
      <RecordLabelWithIcon
        noIndent
        icon={icon}
        label={<span className={styles.tableLabel}>{label}</span>}
        testId={`table-label-${label}`}
      />
      <Menu
        testId="table-more-icon"
        items={items}
        menu={menu}
      />
    </>
  );
};
