import { QueryClient } from "@tanstack/react-query";

import { databasesQueryKey } from "services/databases/constants";

export const updateDatabases = async ({
  queryClient,
}: {
  queryClient: QueryClient;
}): Promise<void> => {
  try {
    await queryClient.cancelQueries({ queryKey: [databasesQueryKey] });
    await queryClient.invalidateQueries({ queryKey: [databasesQueryKey] });
  } catch (e) {
    console.error("Failed to update databases from WS: ", e);
  }
};
