import classNames from "classnames";
import React, { useCallback } from "react";

import { ReactComponent as CopyIcon } from "assets/icons/CopyGray.svg";

import { useCopy } from "components/CopyProvider";
import CustomTooltip from "components/Tooltip";

import { TOOLTIP_EXIT_TIMEOUT } from "../constants";

import styles from "./styles.module.scss";

type Props = {
  className?: string;
  text: string;
  hidden?: boolean;
  testId?: string;
};

const stopPropagation = (event: React.SyntheticEvent) =>
  event.stopPropagation();

export const Copy: React.FC<Props> = props => {
  const { className, text, hidden = false, testId } = props;
  const { copied, handleCopy } = useCopy();

  const onCopy = useCallback(() => {
    handleCopy(text);
  }, [text, handleCopy]);

  if (hidden) {
    return null;
  }

  return (
    <CustomTooltip
      key={copied ? "copied" : "copy"}
      title={copied ? "Text copied" : "Copy"}
      placement="top"
      enterDelay={0}
      TransitionProps={{ timeout: { exit: TOOLTIP_EXIT_TIMEOUT } }}
    >
      <div
        className={classNames(styles.icon, className)}
        onClick={stopPropagation}
        data-testid={testId}
      >
        <div onClick={onCopy}>
          <CopyIcon />
        </div>
      </div>
    </CustomTooltip>
  );
};
