import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { TextInput } from "components/TextInput/TextInput";

import { Fields } from "../Steps/schema";

import styles from "./styles.module.scss";

type AuthPropsType = {
  register: UseFormReturn<Fields>["register"];
  errors: UseFormReturn<Fields>["formState"]["errors"];
  playgroundBucket: boolean;
};

const SecretAuthType = (props: AuthPropsType) => {
  const { register, errors, playgroundBucket } = props;
  const { t } = useTranslation();
  return (
    <>
      <div className={styles.formControl}>
        <TextInput
          inputRootClassName={styles.inputRoot}
          label={t("wizard.select_aws.key")}
          testId="key-field"
          {...register("key")}
          error={!!errors?.key}
          helperText={errors?.key?.message}
          disabled={playgroundBucket}
          required
        />
      </div>
      <div className={styles.formControl}>
        <TextInput
          inputRootClassName={styles.inputRoot}
          label={t("wizard.select_aws.secret")}
          testId="secret-field"
          {...register("secret")}
          error={!!errors?.secret}
          helperText={errors?.secret?.message}
          disabled={playgroundBucket}
          required
        />
      </div>
    </>
  );
};

/* const IAMRoleAuthType = (props: AuthPropsType) => {
 *   const { register, errors } = props;
 *   const { t } = useTranslation();
 *   return (
 *     <div className={styles.formControl}>
 *       <TextInput
 *         inputRootClassName={styles.inputRoot}
 *         label={t("wizard.select_aws.iam_role")}
 *         testId="aim-role-field"
 *         {...register("iamrole")}
 *         error={!!errors?.key}
 *         helperText={errors?.key?.message}
 *         required
 *       />
 *     </div>
 *   );
 * }; */

const authSettingsByType = {
  secret: SecretAuthType,
  // iam: IAMRoleAuthType,
};

export const AuthSelector = (props: {
  form: UseFormReturn<Fields>;
  playgroundBucket: boolean;
}) => {
  const { form, playgroundBucket } = props;
  const {
    register,
    formState: { errors },
    watch,
  } = form;

  const AuthSettingsComponent =
    authSettingsByType[watch("authType") as keyof typeof authSettingsByType];

  // todo: add testids to the actionable items, once we uncomment + ensure their uniqueness
  return (
    <>
      {/* <div className={styles.auth}>
          <div className={styles.label}>{t("wizard.select_aws.auth_label")}</div>
          <div className={styles.radioSelector}>
          <Controller
          control={control}
          name="authType"
          render={({ field: { onChange, value } }) => (
          <>
          <div
          onClick={() => {
          onChange("secret");
          }}
          className={styles.radioSelectorItem}
          >
          <RadioButton selected={value === "secret"} />
          <label className={styles.radioTitle}>
          {t("wizard.select_aws.auth_secret")}
          </label>
          </div>

          <div
          onClick={() => {
          onChange("iam");
          }}
          className={styles.radioSelectorItem}
          >
          <RadioButton selected={value === "iam"} />
          <label className={styles.radioTitle}>
          {t("wizard.select_aws.auth_iam")}
          </label>
          </div>
          </>
          )}
          />
          </div>
          </div> */}

      <AuthSettingsComponent
        register={register}
        errors={errors}
        playgroundBucket={playgroundBucket}
      />
    </>
  );
};
