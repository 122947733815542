import React from "react";

import { Column, ColumnWithProps, Row } from "./types";

type StickyGridType = {
  handleResize: (index: number, offset: number) => void;
  columns: ColumnWithProps[];
  columnLeftOffsets: number[];
  dropdownExpand: boolean;
  rowCount: number;
  getRawCell(
    rowIndex: number,
    columnIndex: number
  ): { row: Row | null; column: ColumnWithProps | null };
};

type IncludeFilter = {
  type?: "ALL";
  items: { value: any; count: number; label: string }[];
};

type ProjectionFilter = {
  items: { value: string; type: string }[];
};

export type FiltersType = {
  include: IncludeFilter;
  filters: ProjectionFilter;
};

type FilterContextType = {
  handleSort: (
    column: Column,
    destinationSortConfig: { sortOrder: "asc" | "desc" | "original" }
  ) => void;
  openFilters: (column: any) => void;
  closeFilters: () => void;
  clearFilters: () => void;
  getFilters: () => Record<string, FiltersType>;
  setColumnFilters: (column: Column, filters: Partial<FiltersType>) => void;
  getColumnFilters: (column: Column) => FiltersType;
  getColumnCandidates: (
    column: Column,
    filters: Partial<FiltersType>
  ) => {
    value: any;
    count: number;
    label: string;
  }[];
  filtersOpened: any;
};

export const StickyGridContext = React.createContext<StickyGridType>({
  handleResize: () => {},
  columns: [],
  columnLeftOffsets: [],
  dropdownExpand: false,
  rowCount: 0,
  getRawCell: () => {
    return {
      row: null,
      column: null,
    };
  },
});
StickyGridContext.displayName = "StickyGridContext";

export const HeightContext = React.createContext<number>(0);
HeightContext.displayName = "HeightContext";

export const InteractionsContext = React.createContext<any>({});
InteractionsContext.displayName = "InteractionsContext";
export const InteractionsActionsContext = React.createContext<{
  dispatch: (action: Object) => void;
  getCursorIndex: Function;
}>({
  dispatch: () => {},
  getCursorIndex: Function,
});
InteractionsActionsContext.displayName = "InteractionsActionsContext";

export const WidthContext = React.createContext<{
  width: number;
  getColumnWidth: (index: number) => number;
}>({
  width: 150,
  getColumnWidth: () => 150,
});

export const FiltersContext = React.createContext<FilterContextType>({
  handleSort: () => {},
  openFilters: () => {},
  closeFilters: () => {},
  clearFilters: () => {},
  getFilters: () => ({}),
  setColumnFilters: () => {},
  getColumnFilters: () => ({
    include: {
      items: [],
    },
    filters: {
      items: [],
    },
  }),
  getColumnCandidates: () => [],
  filtersOpened: false,
});
FiltersContext.displayName = "FiltersContext";
