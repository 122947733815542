import { useMutation, useQueryClient } from "@tanstack/react-query";

import {
  StatusMessagePosition,
  StatusMessageType,
} from "components/StatusMessageQueue/StatusMessageQueueProvider";
import useStatusMessageQueue from "components/StatusMessageQueue/hooks/useStatusMessageQueue";

import { WorkspaceEngine, WorkspaceEngineStatus } from "./engine.types";
import { stopEngineMutation } from "./stopEngine";
import { workspaceEnginesQueryKey } from "./constants";

export const useStopWorkspaceEngine = (mutation = stopEngineMutation) => {
  const queryClient = useQueryClient();
  const { putStatusMessage } = useStatusMessageQueue();
  const { mutate, isPending } = useMutation({
    mutationFn: mutation,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [workspaceEnginesQueryKey] });
    },
    onMutate: async (engineName: string) => {
      await queryClient.cancelQueries({queryKey: [workspaceEnginesQueryKey]});
      const previousEngines = queryClient.getQueryData([workspaceEnginesQueryKey]);

      queryClient.setQueryData<WorkspaceEngine[]>(
        [workspaceEnginesQueryKey],
        engines => {
          return (engines || []).map(engine => {
            if (engine.engineName === engineName) {
              return {
                ...engine,
                status: WorkspaceEngineStatus.STOPPING,
              };
            }
            return engine;
          });
        }
      );

      return { previousEngines };
    },
    onError: async (error: Error) => {
      putStatusMessage({
        type: StatusMessageType.Error,
        message: error.message,
        options: {
          id: "start",
          insertToPosition: StatusMessagePosition.Top,
          autoRemove: true,
        },
      });
      await queryClient.invalidateQueries({queryKey: [workspaceEnginesQueryKey]});
    },
  });

  return {
    mutate,
    isLoading: isPending,
  };
};
