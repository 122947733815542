import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, UseFormReturn, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import ContextMenuItem from "components/ContextMenu/ContextMenuItem";
import LoadingOverlap from "components/LoadingOverlap";
import { OutlinedSelect } from "components/OutlinedSelect/OutlinedSelect";
import { Step } from "components/Wizard/Step/Step";

import { useEnsureEngineRunning } from "../Steps/useEnsureEngineRunning";
import {
  FormatDataStep,
  IngestionStep,
  SUPPORTED_TYPES,
  WizardDataType,
} from "../types";
import { PreviewTable } from "./PreviewTable";
import { ErrorSettings } from "./Settings/ErrorSettings";
import { FormatSettings } from "./Settings/FormatSettings";
import {
  FormatingFields,
  defaultErrorSettings,
  defaultFormatingByType,
  formatingSchema,
} from "./schema";
import { usePreviewData } from "./usePreviewData";

import styles from "./styles.module.scss";

type Props = {
  onClose: () => void;
  onSubmit: (data: FormatDataStep) => void;
  wizardData: WizardDataType;
  onPrevStep: () => void;
  activeStepIndex: number;
  initialData: FormatDataStep;
};

const FileTypeSelector = (props: { form: UseFormReturn<FormatingFields> }) => {
  const { form } = props;
  const { control, watch, setValue } = form;
  const { t } = useTranslation();
  const fileType = watch("extension");
  const items = SUPPORTED_TYPES.map(item => {
    return (
      <ContextMenuItem
        value={item}
        key={item}
        checked={item === fileType}
        checkedIconPlaceholder
        testId={`filetype-${item}`}
        text={item}
      />
    );
  });
  return (
    <div className={styles.row}>
      <span className={styles.row__label}>
        {t("wizard.format_data.filetype")}
      </span>
      <Controller
        control={control}
        name="extension"
        render={({ field: { onChange, value } }) => (
          <OutlinedSelect
            testId="filetype-select"
            className={styles.inputRoot}
            initialSelected={[value]}
            onSelect={([name]) => {
              onChange(name);
              setValue("useDefaultFormatSettings", true);
              setValue("useDefaultErrorSettings", true);
              setValue(
                "formatSettings",
                defaultFormatingByType[
                  name as keyof typeof defaultFormatingByType
                ]
              );
            }}
          >
            {items}
          </OutlinedSelect>
        )}
      />
    </div>
  );
};

export const FormatData = (props: Props) => {
  const {
    onClose,
    onSubmit,
    wizardData,
    initialData,
    onPrevStep,
    activeStepIndex,
  } = props;
  const { t } = useTranslation();

  useEnsureEngineRunning(wizardData);
  // todo revisit ! at the end
  const { files } = wizardData[IngestionStep.selectData]!;
  const [file] = files;

  const extension = file.extension;
  const formatingForm = useForm<FormatingFields>({
    resolver: zodResolver(formatingSchema),
    defaultValues: (initialData
      ? {
          useDefaultErrorSettings: initialData.useDefaultErrorSettings,
          useDefaultFormatSettings: initialData.useDefaultFormatSettings,
          extension,
          formatSettings:
            initialData.extension !== extension
              ? defaultFormatingByType[
                  extension as keyof typeof defaultFormatingByType
                ]
              : initialData.format,
          errorSettings:
            initialData.extension !== extension
              ? defaultErrorSettings
              : initialData.error,
        }
      : {
          useDefaultErrorSettings: true,
          useDefaultFormatSettings: true,
          extension,
          formatSettings:
            defaultFormatingByType[
              extension as keyof typeof defaultFormatingByType
            ],
          errorSettings: defaultErrorSettings,
        }) as FormatingFields,
  });

  const { handleSubmit, watch } = formatingForm;

  const format = watch();

  const {
    data: previewData,
    isLoading,
    error,
  } = usePreviewData({
    format,
    wizardData,
  });

  const onFormSubmit = (format: FormatingFields) => {
    if (isLoading || error || previewData.error) {
      return;
    }
    onSubmit({
      useDefaultErrorSettings: format.useDefaultErrorSettings,
      useDefaultFormatSettings: format.useDefaultFormatSettings,
      extension: format.extension,
      format: format.formatSettings,
      error: format.errorSettings,
      previewData,
    });
  };

  const body = (
    <div className={styles.wrapper}>
      <div className={styles.settings}>
        <FileTypeSelector form={formatingForm} />
        <FormatSettings form={formatingForm} />
        <ErrorSettings form={formatingForm} />
      </div>
      {isLoading && (
        <div style={{ height: 278, position: "relative" }}>
          <LoadingOverlap isLoading />
        </div>
      )}
      {!isLoading && previewData && <PreviewTable previewData={previewData} />}
    </div>
  );

  return (
    <Step
      title={t("wizard.format_data.title")}
      subtitle={t("wizard.format_data.subtitle")}
      body={body}
      onClose={onClose}
      onSubmit={handleSubmit(onFormSubmit)}
      onPrevStep={onPrevStep}
      activeStepIndex={activeStepIndex}
      disabledSubmit={isLoading || error || previewData?.error}
    />
  );
};
