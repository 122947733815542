import { createContext, useEffect, useState } from "react";
import { getQueryList } from "utils/helpers/Query";
import { v4 as uuidv4 } from "uuid";

import createPersistedReducer from "hooks/usePersistedReducer";
import { MyAccount } from "services/account/account.types";
import { useDatabasesNames } from "services/databases/useDatabasesNames";
import { useWorkspaceEngines } from "services/engines/useWorkspaceEngines";

import { documentsReducer } from "pages/DevelopWorkspace/contexts/DocumentsContext/documentsReducer";
import { getExecutionStartError } from "pages/DevelopWorkspace/contexts/DocumentsContext/helpers/getExecutionStartError";
import normalizeDocumentsRawState from "pages/DevelopWorkspace/contexts/DocumentsContext/helpers/normalizeDocumentsRawState";
import { persistFilter } from "pages/DevelopWorkspace/contexts/DocumentsContext/helpers/persistFilter";
import {
  ScriptStatus,
  useScripts,
} from "pages/DevelopWorkspace/contexts/ScriptsContext/ScriptsContext";
import { getDocumentScript } from "pages/DevelopWorkspace/helpers/getDocumentScript";
import {
  CancellationStatus,
  DocumentLayout,
  DocumentOutputTab,
  DocumentsState,
  Execution,
  ExecutionContext,
  ExecutionType,
  QueryStatement,
  QueryStatementStatus,
  ScriptType,
  SortOrder,
  WorkspaceDocument,
} from "pages/DevelopWorkspace/workspace.types";

import { useLayoutToggles } from "components/LayoutToggles/context";

import { DocumentActionType } from "./actions.types";
import {
  SwitchActiveOutputPayload,
  SwitchActiveOutputTabCallback,
  SwitchDocumentActiveQueryStatementCallback,
  SwitchDocumentActiveQueryStatementPayload,
} from "./types";

type DocumentsContextType = {
  state: DocumentsState;
  actions: {
    changeSortOrder: (sortOrder: SortOrder) => void;
    createDocument: (
      {
        title,
        content,
        context,
      }: {
        title?: string;
        content?: string;
        context?: Partial<ExecutionContext>;
      },
      options?: {
        autoRun?: boolean;
      }
    ) => Promise<string>;
    setActiveDocument: (documentId: string) => void;
    removeDocument: (documentId: string) => void;
    removeAllDocuments: () => void;
    renameDocument: (documentId: string, newTitle: string) => void;
    updateDocumentContent: (documentId: string, newContent: string) => void;
    updateDocumentSelection: (
      documentId: string,
      selection: [number, number]
    ) => void;
    changeDocumentContext: (
      documentId: string,
      context: {
        engineName?: string;
        databaseName?: string | Symbol;
        settings?: { [key: string]: any };
      }
    ) => void;
    startDocumentExecution: (
      documentId: string,
      selectedContent: string,
      executionType: ExecutionType
    ) => void;
    updateDocumentQueryStatement: (
      documentId: string,
      id: string,
      queryStatement: Partial<QueryStatement>
    ) => void;
    cancelDocumentExecution: (documentId: string) => void;
    updateDocumentCancellationStatus: (
      documentId: string,
      queryStatementId: string,
      status: CancellationStatus
    ) => void;
    switchDocumentActiveQueryStatement: SwitchDocumentActiveQueryStatementCallback;
    switchActiveOutputTab: SwitchActiveOutputTabCallback;
    changeDocumentLayout: (
      documentId: string,
      layout: Partial<DocumentLayout>
    ) => void;
  };
};

interface DocumentsContextProviderProps {
  children: React.ReactNode;
  account: MyAccount;
}

export const DocumentsContext = createContext<DocumentsContextType | undefined>(
  undefined
);

export const INITIAL_STATE: DocumentsState = {
  documents: [],
  activeDocumentId: null,
  sortOrder: SortOrder.NewestLast,
};

export const DEFAULT_EXECUTION_CONTEXT = {
  engineName: "system",
  databaseName: "",
  settings: {},
};

export const DEFAULT_LAYOUT = {
  editorHeightPx: 300,
  activeOutputTab: DocumentOutputTab.Results,
};

const usePersistedReducer = createPersistedReducer();

export const DocumentsContextProvider = (
  props: DocumentsContextProviderProps
) => {
  const { children, account } = props;

  const { state: scriptsState, actions: scriptsActions } = useScripts();
  const { data: engines } = useWorkspaceEngines(true);
  const databases = useDatabasesNames();
  const { setLayout } = useLayoutToggles();
  const [documentsState, dispatch] = usePersistedReducer(
    `FIREBOLT_documents|${account.id}`,
    documentsReducer,
    INITIAL_STATE,
    persistFilter,
    normalizeDocumentsRawState
  );

  const [documentsIdsToRun, setDocumentsIdsToRun] = useState<string[]>([]);

  useEffect(() => {
    // remove document if script has error

    documentsState.documents.forEach(document => {
      const scriptState = scriptsState.localScripts[document.script.id];

      if (scriptState && scriptState.status === ScriptStatus.error) {
        dispatch({
          type: DocumentActionType.REMOVE_DOCUMENT,
          payload: document.id,
        });
      }
    });
  }, [scriptsState.localScripts, documentsState.documents, dispatch]);

  useEffect(() => {
    // check if selected engine is available, otherwise change to system
    if (engines) {
      documentsState.documents.forEach(document => {
        const engine = engines.find(
          engine => engine.engineName === document.context.engineName
        );

        if (!engine) {
          dispatch({
            type: DocumentActionType.CHANGE_DOCUMENT_CONTEXT,
            payload: {
              documentId: document.id,
              context: {
                engineName: "system",
              },
            },
          });
        }
      });
    }

    // check if selected database is available, otherwise change to none
    if (databases) {
      documentsState.documents.forEach(document => {
        const database = databases.find(
          database => database.catalogName === document.context.databaseName
        );

        if (!database) {
          dispatch({
            type: DocumentActionType.CHANGE_DOCUMENT_CONTEXT,
            payload: {
              documentId: document.id,
              context: {
                databaseName: "",
              },
            },
          });
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only depends on engines and databases
  }, [engines, databases]);

  useEffect(() => {
    documentsState.documents.forEach(document => {
      const scriptStorage =
        document.script.type === ScriptType.local
          ? scriptsState.localScripts
          : scriptsState.remoteScripts;

      if (!scriptStorage[document.script.id]) {
        scriptsActions.loadScript(document.script.id, document.script.type);
      }
    });
  }, [
    documentsState,
    scriptsActions,
    scriptsState.localScripts,
    scriptsState.remoteScripts,
  ]);

  const initDocumentExecution = async (
    documentId: string,
    selectedContent: string,
    executionType: ExecutionType
  ) => {
    const document = documentsState.documents.find(
      document => document.id === documentId
    );

    if (!document) {
      console.error("Document not found");
      return;
    }

    if (!engines) {
      console.error("Engines not found");
      return;
    }

    const engine = engines.find(
      engine => engine.engineName === document.context.engineName
    );

    if (!engine) {
      console.error("Engine not found");
      return;
    }

    const documentExecutionError = getExecutionStartError(engine);

    const sqlStatements = getQueryList(selectedContent);

    if (sqlStatements.length === 0) {
      // nothing to execute (probably only whitespace selected)
      return;
    }

    const queryStatements = sqlStatements.map(query => {
      return {
        content: query,
        status: QueryStatementStatus.pending,
        result: null,
        error: null,
        id: "query_statement." + uuidv4(),
        responseStatusCode: null,
        statistics: null,
        sourceDocLineNumber: 0,
      };
    });

    const execution: Execution = {
      executionType,
      queryStatements,
      userSelectedActiveQueryStatementIndexTimestamp: 0,
      activeQueryStatementIndex: 0,
      executionTimestamp: Date.now(),
      documentExecutionError,
    };

    dispatch({
      type: DocumentActionType.INIT_DOCUMENT_EXECUTION,
      payload: {
        documentId,
        execution,
      },
    });

    setLayout(layout => {
      return {
        ...layout,
        results: {
          ...layout.results,
          expanded: true,
        },
      };
    });
  };

  const startDocumentExecution = (
    documentId: string,
    selectedContent: string,
    executionType: ExecutionType = ExecutionType.Query
  ) => {
    initDocumentExecution(documentId, selectedContent, executionType);
  };

  useEffect(() => {
    /*
    Executes documents after their successful creation. If autoRun option was set.
     */
    if (documentsIdsToRun.length > 0) {
      documentsIdsToRun.forEach(documentId => {
        const document = documentsState.documents.find(
          document => document.id === documentId
        );
        if (document) {
          const script = getDocumentScript(document, scriptsState);

          if (!script) {
            return;
          }

          startDocumentExecution(
            documentId,
            script.content,
            ExecutionType.Query
          );
        }
      });
      setDocumentsIdsToRun([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentsIdsToRun]);

  // eslint-disable-next-line react-hooks/exhaustive-deps -- it's ok here
  const createDocument = async (
    {
      title,
      content,
      context,
    }: {
      title?: string;
      content?: string;
      context?: Partial<ExecutionContext>;
    },
    options?: {
      autoRun?: boolean;
    }
  ) => {
    const newScriptId = `account.${account.id}.script.` + uuidv4();

    const newDocumentId = "document." + uuidv4();

    const newDocContext = context
      ? {
          ...DEFAULT_EXECUTION_CONTEXT,
          ...context,
        }
      : DEFAULT_EXECUTION_CONTEXT;

    const newDocument: WorkspaceDocument = {
      id: newDocumentId,
      script: {
        id: newScriptId,
        type: ScriptType.local,
      },
      selection: [content?.length || 0, content?.length || 0],
      context: newDocContext,
      execution: null,
      createdAt: Date.now(),
      layout: DEFAULT_LAYOUT,
    };

    dispatch({
      type: DocumentActionType.ADD_DOCUMENT,
      payload: newDocument,
    });

    await scriptsActions.createScript(newScriptId, ScriptType.local, {
      title: title || `Script ${documentsState.documents.length + 1}`,
      content: content || "",
    });

    if (options?.autoRun) {
      setDocumentsIdsToRun(documentsIdsToRun => [
        ...documentsIdsToRun,
        newDocumentId,
      ]);
    }

    return newDocumentId;
  };

  useEffect(() => {
    // create new document if there are no documents
    if (documentsState.documents.length <= 0) {
      createDocument({
        title: "Script 1",
        content: "",
      });
    }
  }, [documentsState.documents.length, createDocument]);

  const changeDocumentLayout = (
    documentId: string,
    layout: Partial<DocumentLayout>
  ) => {
    dispatch({
      type: DocumentActionType.CHANGE_DOCUMENT_LAYOUT,
      payload: {
        documentId,
        layout,
      },
    });
  };

  const changeSortOrder = (sortOrder: SortOrder) => {
    dispatch({
      type: DocumentActionType.CHANGE_SORT_ORDER,
      payload: sortOrder,
    });
  };

  const updateDocumentSelection = (
    documentId: string,
    selection: [number, number]
  ) => {
    dispatch({
      type: DocumentActionType.UPDATE_DOCUMENT_SELECTION,
      payload: {
        documentId,
        selection,
      },
    });
  };

  /*
  IMPORTANT: Don't use this function to update the content of the ACTIVE document.
  Use useActiveEditorView hook instead. It's unidirectional data flow.
  All changes in active document editor should be dispatched to the CodeMirror view.
   */
  const updateDocumentContent = async (
    documentId: string,
    newContent: string
  ) => {
    const document = documentsState.documents.find(
      document => document.id === documentId
    );

    if (!document) {
      return;
    }

    await scriptsActions.updateScript(
      document.script.id,
      document.script.type,
      {
        content: newContent,
      }
    );
  };

  const renameDocument = async (documentId: string, newTitle: string) => {
    if (newTitle === "") {
      return;
    }

    const document = documentsState.documents.find(
      document => document.id === documentId
    );

    if (!document) {
      return;
    }

    await scriptsActions.updateScript(
      document.script.id,
      document.script.type,
      {
        title: newTitle,
      }
    );
  };

  const setActiveDocument = (documentId: string) => {
    dispatch({
      type: DocumentActionType.SET_ACTIVE_DOCUMENT,
      payload: documentId,
    });
  };

  const removeDocument = (documentId: string) => {
    const document = documentsState.documents.find(
      document => document.id === documentId
    );

    if (!document) {
      return;
    }

    dispatch({
      type: DocumentActionType.REMOVE_DOCUMENT,
      payload: documentId,
    });

    scriptsActions.removeScript(document.script.id, document.script.type);
  };

  const removeAllDocuments = () => {
    documentsState.documents.forEach(document => {
      scriptsActions.removeScript(document.script.id, document.script.type);
    });

    dispatch({
      type: DocumentActionType.REMOVE_ALL_DOCUMENTS,
      payload: undefined,
    });
  };

  const changeDocumentContext = (
    documentId: string,
    context: Partial<ExecutionContext>
  ) => {
    dispatch({
      type: DocumentActionType.CHANGE_DOCUMENT_CONTEXT,
      payload: {
        documentId,
        context,
      },
    });
  };

  const cancelDocumentExecution = (documentId: string) => {
    dispatch({
      type: DocumentActionType.CANCEL_DOCUMENT_EXECUTION,
      payload: documentId,
    });
  };

  const updateDocumentCancellationStatus = (
    documentId: string,
    queryStatementId: string,
    status: CancellationStatus
  ) => {
    dispatch({
      type: DocumentActionType.UPDATE_DOCUMENT_EXECUTION_CANCELLATION_STATUS,
      payload: {
        documentId,
        queryStatementId,
        cancellationStatus: status,
      },
    });
  };

  const updateDocumentQueryStatement = (
    documentId: string,
    id: string,
    queryStatement: Partial<QueryStatement>
  ) => {
    dispatch({
      type: DocumentActionType.UPDATE_DOCUMENT_QUERY_STATEMENT,
      payload: {
        documentId,
        id,
        queryStatement,
      },
    });
  };

  const switchDocumentActiveQueryStatement = ({
    documentId,
    queryStatementIndex,
    userSelectedActiveQueryStatementIndexTimestamp,
    activeOutputTab,
  }: SwitchDocumentActiveQueryStatementPayload) => {
    dispatch({
      type: DocumentActionType.SWITCH_DOCUMENT_ACTIVE_QUERY_STATEMENT,
      payload: {
        documentId,
        queryStatementIndex,
        userSelectedActiveQueryStatementIndexTimestamp,
        activeOutputTab,
      },
    });
  };

  const switchActiveOutputTab = ({
    documentId,
    activeOutputTab,
  }: SwitchActiveOutputPayload) => {
    dispatch({
      type: DocumentActionType.SWITCH_ACTIVE_OUTPUT_TAB,
      payload: {
        documentId,
        activeOutputTab,
      },
    });
  };

  // eslint-disable-next-line react/jsx-no-constructed-context-values -- it's ok here
  const contextValue: DocumentsContextType = {
    state: documentsState,
    actions: {
      createDocument,
      removeDocument,
      setActiveDocument,
      renameDocument,
      updateDocumentContent,
      updateDocumentSelection,
      changeDocumentContext,
      startDocumentExecution,
      updateDocumentQueryStatement,
      cancelDocumentExecution,
      updateDocumentCancellationStatus,
      switchDocumentActiveQueryStatement,
      switchActiveOutputTab,
      changeDocumentLayout,
      removeAllDocuments,
      changeSortOrder,
    },
  };

  return (
    <DocumentsContext.Provider value={contextValue}>
      {children}
    </DocumentsContext.Provider>
  );
};
