import { ReactComponent as ViewIcon } from "assets/icons/workspace/View.svg";

import useActiveEditorView from "pages/DevelopWorkspace/contexts/ActiveEditorViewContext/hooks/useActiveEditorView";
import useActiveDocument from "pages/DevelopWorkspace/contexts/DocumentsContext/hooks/useActiveDocument";
import { useDocuments } from "pages/DevelopWorkspace/contexts/DocumentsContext/hooks/useDocuments";

import { useMenu } from "components/ActionMenu/useMenu";
import { RecordLabelWithIcon } from "components/LeftSidebar/RecordLabelWithIcon";
import { Menu } from "components/LeftSidebar/RecordMenu/RecordMenu";
import Spinner from "components/Spinner";
import { TreeNodeType } from "components/Tree/types";

import styles from "./styles.module.scss";

type Props = {
  node: TreeNodeType;
  loading?: boolean;
};

export const InformationSchemaTableLabel = (props: Props) => {
  const { node, loading } = props;
  const { label } = node;
  const {
    actions: { createDocument },
  } = useDocuments();
  const activeDocument = useActiveDocument();
  const { insertTextAtCursorPosition } = useActiveEditorView();
  const menu = useMenu();

  const items = [
    [
      {
        key: "insert",
        text: "Insert view name to script",
        shortcut: "Shift + click",
        action: () => {
          if (activeDocument) {
            insertTextAtCursorPosition(label);
          }
        },
        testId: "insert-view-name-in-editor-item",
      },
      {
        key: "preview",
        text: "Preview data",
        action: () => {
          const value = `select * from information_schema.${node.label} limit 100`;
          if (activeDocument) {
            createDocument(
              {
                title: node.label,
                content: value,
                context: {
                  ...activeDocument.context,
                  databaseName: node.databaseName,
                  settings: {},
                },
              },
              {
                autoRun: true,
              }
            );
          }
        },
        testId: "preview-data-item",
      },
    ],
  ];

  const icon = loading ? (
    <div className={styles.loading}>
      <Spinner size={12} />
    </div>
  ) : (
    <ViewIcon />
  );

  return (
    <>
      <RecordLabelWithIcon
        noIndent
        icon={icon}
        label={<span className={styles.tableLabel}>{label}</span>}
        testId={`i-s-label-${label}`}
      />
      <Menu
        testId="i-s-more-icon"
        items={items}
        menu={menu}
      />
    </>
  );
};
