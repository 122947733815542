import { Auth0Client, User } from "@auth0/auth0-spa-js";
import jwt_decode from "jwt-decode";

import {
  OrganizationService,
  ResolveOrganizationResponse,
} from "services/organization";

import {
  AUTH0_CLIENT_ID,
  AUTH0_DOMAIN,
  AUTH0_TARGET_AUDIENCE,
  DEFAULT_ENVIRONMENT_LOGIN,
  ENV_TLD,
} from "./constants";

const LOGIN_HANDLE = "/login/handle";

export const ORGANIZATION_REGEX =
  /([a-zA-Z0-9-]+)\.([a-zA-Z0-9-]+)\.firebolt\.io(:.+)?/;

const auth0 = new Auth0Client({
  domain: AUTH0_DOMAIN,
  clientId: AUTH0_CLIENT_ID,
  authorizationParams: {
    redirect_uri: window.location.origin + LOGIN_HANDLE,
    audience: AUTH0_TARGET_AUDIENCE,
  },
  cookieDomain: `.firebolt.io`,
});

export class AuthService {
  organization?: ResolveOrganizationResponse;

  organizationName?: string;

  organizationSubdomain?: string;

  auth0client: Auth0Client;

  token: string | null;

  bc?: BroadcastChannel;

  constructor() {
    this.token = null;
    this.auth0client = auth0;
    if (window.BroadcastChannel) {
      this.bc = new BroadcastChannel("logout_channel");

      this.bc.onmessage = event => {
        const { data } = event;
        if (data && data.type === "logout") {
          this.redirectToLogout(true);
        }
      };
    }
  }

  async initialize() {
    const match = window.origin.match(ORGANIZATION_REGEX);
    if (match) {
      const [, organizationName] = match;

      if (organizationName === "ui") {
        return;
      }

      this.organizationSubdomain = organizationName;

      try {
        const organization = await OrganizationService.isSupported(
          organizationName
        );

        if (organization) {
          this.organization = organization;
          this.organizationName = organizationName;
        } else {
          await this.auth0client.logout({
            logoutParams: {
              returnTo: DEFAULT_ENVIRONMENT_LOGIN,
            },
          });
        }
      } catch (error) {
        await this.auth0client.logout({
          logoutParams: {
            returnTo: DEFAULT_ENVIRONMENT_LOGIN,
          },
        });
      }
    }
  }

  async handleRedirectCallback() {
    const state = await this.auth0client.handleRedirectCallback();
    const { appState } = state;
    return appState;
  }

  async redirectToLogout(terminated = false) {
    if (!terminated) {
      this.broadcastOtherTabs();
    }
    const token = await this.getToken();
    await this.auth0client.logout({
      logoutParams: {
        returnTo: DEFAULT_ENVIRONMENT_LOGIN,
      },
      openUrl: async url => {
        try {
          await fetch(
            `${process.env.REACT_APP_API_ENDPOINT_URL}/web/v3/logout`,
            {
              method: "GET",
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          localStorage.removeItem("accountName");
        } catch (error) {
          console.log(error);
        }
        window.location.assign(url);
      },
    });
  }

  broadcastOtherTabs() {
    if (this.bc) {
      this.bc.postMessage({
        type: "logout",
      });
    }
  }

  async redirectToLogin(redirectPath = window.location.pathname) {
    if (!this.organization) {
      return;
    }
    const { organizationID } = this.organization;

    const isAuthorized = await this.isLoggedIn();
    if (isAuthorized) {
      window.location.assign(
        `https://${this.organizationSubdomain}.${ENV_TLD}${redirectPath}`
      );
    } else {
      const uri = `https://${this.organizationSubdomain}.${ENV_TLD}${LOGIN_HANDLE}`;
      await this.auth0client.loginWithRedirect({
        authorizationParams: {
          organization: organizationID,
          redirect_uri: uri,
        },
        appState: { redirectPath },
      });
    }
  }

  async isLoggedIn() {
    try {
      await this.auth0client.getTokenSilently();
      return true;
    } catch (error) {
      return false;
    }
  }

  async getLoginId() {
    try {
      const token = await this.auth0client.getTokenSilently();
      const decoded: any = jwt_decode(token);
      return decoded["https://firebolt.io/claims"].login_id as string;
    } catch (error) {
      return null;
    }
  }

  async getToken() {
    return this.getTokenSilently();
  }

  async getFireboltClaims() {
    try {
      const token = await this.getToken();
      if (token) {
        const data = JSON.parse(atob(token.split(".")[1]));
        return data["https://firebolt.io/claims"];
      }
      return null;
    } catch (error) {
      return null;
    }
  }

  async getTokenSilently() {
    try {
      const token = await this.auth0client.getTokenSilently();
      return token;
    } catch (e) {
      return null;
    }
  }
}

export { User };

export const authService = new AuthService();
