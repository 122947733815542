import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { TextInput } from "components/TextInput/TextInput";

import { FormatingFields } from "../FormatData/schema";

import styles from "./styles.module.scss";

type AuthPropsType = {
  register: UseFormReturn<FormatingFields>["register"];
  errors: UseFormReturn<FormatingFields>["formState"]["errors"];
};

const SecretAuthType = (props: AuthPropsType) => {
  const { register, errors } = props;
  const { t } = useTranslation();
  return (
    <>
      <div className={styles.formControl}>
        <TextInput
          inputRootClassName={styles.inputRoot}
          label={t("wizard.select_aws.key")}
          testId="key-field"
          {...register(`errorSettings.key`)}
          error={!!errors?.errorSettings?.key}
          helperText={errors?.errorSettings?.key?.message}
          required
        />
      </div>
      <div className={styles.formControl}>
        <TextInput
          inputRootClassName={styles.inputRoot}
          label={t("wizard.select_aws.secret")}
          testId="secret-field"
          {...register(`errorSettings.secret`)}
          error={!!errors?.errorSettings?.secret}
          helperText={errors?.errorSettings?.secret?.message}
          required
        />
      </div>
    </>
  );
};

/* const IAMRoleAuthType = (props: AuthPropsType) => {
 *   const { register, errors } = props;
 *   const { t } = useTranslation();
 *   return (
 *     <div className={styles.formControl}>
 *       <TextInput
 *         inputRootClassName={styles.inputRoot}
 *         label={t("wizard.select_aws.iam_role")}
 *         testId="aim-role-field"
 *         {...register(`errorSettings.iamrole`)}
 *         error={!!errors?.errorSettings?.key}
 *         helperText={errors?.errorSettings?.key?.message}
 *         required
 *       />
 *     </div>
 *   );
 * }; */

const authSettingsByType = {
  secret: SecretAuthType,
  // iam: IAMRoleAuthType,
};

export const ErrorFileAuthSelector = (props: {
  form: UseFormReturn<FormatingFields>;
}) => {
  const { form } = props;
  const {
    register,
    formState: { errors },
    watch,
  } = form;

  const AuthSettingsComponent =
    authSettingsByType[
      watch("errorSettings.authType") as keyof typeof authSettingsByType
    ];
  return (
    <>
      <AuthSettingsComponent
        register={register}
        errors={errors}
      />
    </>
  );
};
