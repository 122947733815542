import { useSuspenseQuery } from "@tanstack/react-query";

import { OrganizationService, Region } from "services/organization";

export const useRegions = (): Region[] => {
  const { data } = useSuspenseQuery({
    queryKey: ["regions"],
    queryFn: async () => {
      const data = await OrganizationService.availableRegions();
      return data.regions;
    },
  });
  return data || [];
};
