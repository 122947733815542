import { useTranslation } from "react-i18next";

import {
  WorkspaceEngine,
  WorkspaceEngineStatus,
} from "services/engines/engine.types";
import { forceStopEngineMutation } from "services/engines/stopEngine";
import { useStartWorkspaceEngine } from "services/engines/useStartWorkspaceEngine";
import { useStopWorkspaceEngine } from "services/engines/useStopWorkspaceEngine";
import { SqlRbacAction } from "services/rbac/action";

import { useAccessManager } from "components/App/accessManager";

import { Chip } from "./Chip";

import styles from "./styles.module.scss";

type Props = {
  // eslint-disable-next-line react/no-unused-prop-types
  engine: WorkspaceEngine;
  inactive?: boolean;
  inline?: boolean;
  inMenu?: boolean;
  size?: "small" | "regular";
};

const StartingChip = (props: Props) => {
  const { engine, inactive, size = "regular", inMenu, inline } = props;
  const { mutate, isLoading } = useStopWorkspaceEngine();
  const { t } = useTranslation();
  const { isAllowedTo } = useAccessManager();

  const canOperateEngine = isAllowedTo(
    "engine",
    engine.engineName,
    SqlRbacAction.ENGINE_OPERATE,
    { owner: engine.engineOwner }
  );
  const items = [
    {
      title: t("workspace.engine_chip.cancel_start"),
      action: () => {
        if (!isLoading) {
          mutate(engine.engineName);
        }
      },
      disabled: !canOperateEngine,
      testId: "cancel-engine-start-item",
    },
  ];
  return (
    <Chip
      className={styles.primary}
      title={t("workspace.engine_chip.starting")}
      items={items}
      inline={inline}
      inMenu={inMenu}
      inactive={inactive}
      size={size}
    />
  );
};

const RunningChip = (props: Props) => {
  const { engine, inactive, size = "regular", inline, inMenu } = props;
  const { mutate, isLoading } = useStopWorkspaceEngine();
  const { mutate: stopByForce, isLoading: isStoppingEngineByForce } =
    useStopWorkspaceEngine(forceStopEngineMutation);
  const { isAllowedTo } = useAccessManager();
  const { t } = useTranslation();

  const canOperateEngine =
    engine.engineName !== "system"
      ? isAllowedTo("engine", engine.engineName, SqlRbacAction.ENGINE_OPERATE, {
          owner: engine.engineOwner,
        })
      : true;
  const items = [
    {
      title: t("workspace.engine_chip.stop"),
      action: () => {
        if (!isLoading && !isStoppingEngineByForce) {
          mutate(engine.engineName);
        }
      },
      testId: "engine-stop-item",
    },
    {
      title: t("workspace.engine_chip.force_stop"),
      action: () => {
        if (!isLoading && !isStoppingEngineByForce) {
          stopByForce(engine.engineName);
        }
      },
      testId: "engine-force-stop-item",
    },
  ];
  return (
    <Chip
      className={styles.primary}
      title={t("workspace.engine_chip.running")}
      items={items}
      inline={inline}
      inMenu={inMenu}
      inactive={inactive}
      disabled={!canOperateEngine}
      disableReason={
        !canOperateEngine
          ? {
              title: t("workspace.engine_chip.no_permission"),
              description: t("workspace.engine_chip.no_permission_desc"),
            }
          : undefined
      }
      size={size}
    />
  );
};

const ResizingChip = (props: Props) => {
  const { inactive, size = "regular", inline, inMenu } = props;
  const { t } = useTranslation();
  return (
    <Chip
      className={styles.primary}
      title={t("workspace.engine_chip.resizing")}
      items={[]}
      inline={inline}
      inMenu={inMenu}
      inactive={inactive}
      size={size}
    />
  );
};

const StoppingChip = (props: Props) => {
  const { inactive, size = "regular", inline, inMenu } = props;
  return (
    <Chip
      className={styles.secondary}
      title="Stopping"
      items={[]}
      inactive={inactive}
      inMenu={inMenu}
      inline={inline}
      size={size}
    />
  );
};

const DrainingChip = (props: Props) => {
  const { engine, inactive, size = "regular", inline, inMenu } = props;
  const { t } = useTranslation();
  const { mutate: stopByForce, isLoading: isStoppingEngineByForce } =
    useStopWorkspaceEngine(forceStopEngineMutation);
  const items = [
    {
      title: t("workspace.engine_chip.force_stop"),
      action: () => {
        if (!isStoppingEngineByForce) {
          stopByForce(engine.engineName);
        }
      },
      testId: "engine-force-stop-item",
    },
  ];
  return (
    <Chip
      className={styles.secondary}
      title={t("workspace.engine_chip.draining")}
      items={items}
      inactive={inactive}
      inMenu={inMenu}
      inline={inline}
      size={size}
    />
  );
};

const StoppedChip = (props: Props) => {
  const { engine, inactive, size = "regular", inline, inMenu } = props;
  const { mutate } = useStartWorkspaceEngine();
  const { isAllowedTo } = useAccessManager();
  const { t } = useTranslation();

  const canOperateEngine = isAllowedTo(
    "engine",
    engine.engineName,
    SqlRbacAction.ENGINE_OPERATE,
    { owner: engine.engineOwner }
  );

  const items = [
    {
      title: t("workspace.engine_chip.start"),
      action: () => {
        mutate(engine.engineName);
      },
      testId: "engine-start-item",
    },
  ];

  return (
    <Chip
      className={styles.secondary}
      title={t("workspace.engine_chip.stopped")}
      items={items}
      inactive={inactive}
      inline={inline}
      inMenu={inMenu}
      disabled={!canOperateEngine}
      disableReason={
        !canOperateEngine
          ? {
              title: t("workspace.engine_chip.no_permission"),
              description: t("workspace.engine_chip.no_permission_desc"),
            }
          : undefined
      }
      size={size}
    />
  );
};

export const chipMap = {
  [WorkspaceEngineStatus.STOPPING]: StoppingChip,
  [WorkspaceEngineStatus.DRAINING]: DrainingChip,
  [WorkspaceEngineStatus.STOPPED]: StoppedChip,

  [WorkspaceEngineStatus.STARTING]: StartingChip,
  [WorkspaceEngineStatus.RUNNING]: RunningChip,

  [WorkspaceEngineStatus.RESIZING]: ResizingChip,
};
