import { Popover } from "@mui/material";
import classNames from "classnames";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";

import { useWorkspaceEngines } from "services/engines/useWorkspaceEngines";

import { ReactComponent as ChevronDown } from "assets/icons/ChevronDown.svg";
import { ReactComponent as EngineIcon } from "assets/icons/EngineSelectorIcon.svg";

import ContextMenu from "components/ContextMenu/ContextMenu";
import ContextMenuItem from "components/ContextMenu/ContextMenuItem";
import ContextMenuItemsGroup from "components/ContextMenu/ContextMenuItemsGroup";
import { EngineStatusToggle } from "components/EngineStatusToggle/EngineStatusToggle";
import { Search } from "components/LeftSidebar/Search";

import commonStyles from "styles/common.module.scss";
import { IngestionStep, WizardDataType } from "../types";

import styles from "./styles.module.scss";

type Props = {
  wizardData: WizardDataType;
  updateEngine: (engineName: string) => void;
  setActiveStep: Dispatch<SetStateAction<IngestionStep>>;
  disabled: boolean;
};

export const EngineSelector = (props: Props) => {
  const { wizardData, updateEngine, setActiveStep, disabled } = props;
  const searchEngineRef = useRef<HTMLDivElement>(null);
  const [engineSelectorOpen, setEngineSelectorOpen] = useState(false);
  const [searchEngine, setSearchEngine] = useState("");

  const engineData = wizardData[IngestionStep.selectEngine] as {
    type?: string;
    engine: {
      engineName: string;
    };
  };

  const {
    engine: { engineName },
  } = engineData;

  const { data: engines = [] } = useWorkspaceEngines();

  useEffect(() => {
    const engine = engines.find(engine => engine.engineName === engineName);
    if (!engine) {
      updateEngine("");
      setActiveStep(IngestionStep.selectEngine);
    }
  }, [engineName, engines, updateEngine, setActiveStep]);

  const engine = engines.find(engine => engine.engineName === engineName);

  const foundEngines = engines.filter(engine => {
    return engine.engineName.toLowerCase().includes(searchEngine.toLowerCase());
  });

  return (
    <div className={styles.root}>
      <div
        className={classNames(commonStyles.contextSelector, {
          [commonStyles.active]: true,
          [commonStyles.disabled]: disabled,
        })}
        ref={searchEngineRef}
      >
        <div
          className={commonStyles.button}
          onClick={() => {
            if (disabled) return;
            setSearchEngine("");
            setEngineSelectorOpen(true);
          }}
          data-testid="engine-context-selector"
        >
          <div className={commonStyles.leftIcon}>
            <EngineIcon />
          </div>
          <div className={commonStyles.text}>{engineName}</div>

          {engine && (
            <EngineStatusToggle
              engine={engine}
              inactive={true}
              inline={true}
              size="small"
            />
          )}
          <div className={commonStyles.rightIcon}>
            <ChevronDown />
          </div>
        </div>

        {engineSelectorOpen && (
          <Popover
            open={engineSelectorOpen}
            anchorEl={searchEngineRef.current}
            classes={{ paper: styles.paper }}
            onClose={() => {
              setEngineSelectorOpen(false);
            }}
            onClick={e => {
              e.stopPropagation();
            }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <div>
              <Search
                testId="search-engine-context"
                onChange={setSearchEngine}
                value={searchEngine}
                autoFocus={true}
                size="small"
              />
            </div>
            <div className={styles.selectorItems}>
              {foundEngines.length === 0 ? (
                <div className={styles.notFound}>No matching engines found</div>
              ) : (
                <ContextMenu>
                  <ContextMenuItemsGroup
                    isGroup={true}
                    maxHeight={400}
                  >
                    {foundEngines.map(engine => (
                      <ContextMenuItem
                        key={engine.engineName}
                        checked={engine.engineName === engineName}
                        testId={`context-engine-item-${engine.engineName}`}
                        onClick={() => {
                          updateEngine(engine.engineName);
                          setEngineSelectorOpen(false);
                        }}
                        checkedIconPlaceholder={true}
                        text={engine.engineName}
                        content={
                          <EngineStatusToggle
                            size="small"
                            inMenu={true}
                            inactive={engine.engineName === "system"}
                            engine={engine}
                          />
                        }
                      />
                    ))}
                  </ContextMenuItemsGroup>
                </ContextMenu>
              )}
            </div>
          </Popover>
        )}
      </div>
    </div>
  );
};
