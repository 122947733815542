import { useTranslation } from "react-i18next";

import { getDatabaseDefinitions } from "services/databases/createWorkspaceDatabase";
import { SqlRbacAction } from "services/rbac/action";

import { ReactComponent as DatabaseIcon } from "assets/icons/workspace/Database.svg";

import useActiveEditorView from "pages/DevelopWorkspace/contexts/ActiveEditorViewContext/hooks/useActiveEditorView";
import { useDocuments } from "pages/DevelopWorkspace/contexts/DocumentsContext/hooks/useDocuments";

import { useMenu } from "components/ActionMenu/useMenu";
import { useAccessManager } from "components/App/accessManager";
import { RecordLabelWithIcon } from "components/LeftSidebar/RecordLabelWithIcon";
import { Menu } from "components/LeftSidebar/RecordMenu/RecordMenu";
import { TreeNodeType } from "components/Tree/types";

import { DatabaseMenuActions } from "../types";

import styles from "./styles.module.scss";

type Props = {
  node: TreeNodeType;
  actions: DatabaseMenuActions;
};

export const DatabaseLabel = (props: Props) => {
  const { node, actions } = props;
  const { openViewDefinitions, deleteDatabase } = actions;
  const {
    state: { activeDocumentId },
    actions: { changeDocumentContext },
  } = useDocuments();
  const { label, value, payload } = node;
  const menu = useMenu();
  const { t } = useTranslation();
  const { isAllowedTo } = useAccessManager();
  const { insertTextAtCursorPosition } = useActiveEditorView();

  const canDeleteDatabase = isAllowedTo(
    "database",
    value,
    SqlRbacAction.DATABASE_MODIFY,
    { owner: payload.catalogOwner }
  );

  const deleteItems = canDeleteDatabase
    ? [
        [
          {
            key: "delete",
            text: t("workspace.database_menu.delete"),
            action: () => {
              deleteDatabase(value);
            },
            testId: "delete-db-item",
          },
        ],
      ]
    : [];

  const items = [
    [
      {
        key: "context",
        text: t("workspace.database_menu.context"),
        action: () => {
          if (activeDocumentId) {
            changeDocumentContext(activeDocumentId, {
              databaseName: value,
            });
          }
        },
        testId: "set-db-document-context-item",
      },
      {
        key: "insert",
        text: t("workspace.database_menu.insert"),
        shortcut: "Shift + click",
        action: () => {
          if (activeDocumentId) {
            insertTextAtCursorPosition(value);
          }
        },
        testId: "insert-db-into-editor-item",
      },
      {
        key: "view",
        text: "View database definition",
        action: () => {
          const { description } = payload;
          const sql = getDatabaseDefinitions({
            databaseName: value,
            description,
          });
          openViewDefinitions({
            value: sql,
            title: "Database definitions",
          });
        },
        testId: "view-db-definition-item",
      },
    ],
    ...deleteItems,
  ];

  return (
    <>
      <RecordLabelWithIcon
        icon={<DatabaseIcon />}
        label={<span className={styles.databaseLabel}>{label}</span>}
        testId={`db-label-${label}`}
      />
      <Menu
        testId="database-more-icon"
        items={items}
        menu={menu}
      />
    </>
  );
};
