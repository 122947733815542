type Privilege = {
  resource: string;
  privilege: string;
};

export enum RolesWizardStep {
  name = "name",
  privileges = "privileges",
  assign = "assign",
}

export type NameStepType = {
  name: string;
  description: string;
};

export type AssignStepType = {
  grantee: string | null;
};

export type PrivilegesStepType = {
  privileges: Privilege[];
};

export type RolesDataType = {
  [RolesWizardStep.name]: NameStepType;
  [RolesWizardStep.privileges]: PrivilegesStepType;
  [RolesWizardStep.assign]: AssignStepType;
};
