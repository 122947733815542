import classNames from "classnames";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { Search } from "components/LeftSidebar/Search";

import styles from "./styles.module.scss";

const AccountResourceSection = (_props: {}) => {
  const { t } = useTranslation();
  return (
    <div className={styles.resource}>
      <div className={styles.resource__header}>
        {t("roles_wizard.privileges.column_accounts")}
      </div>
    </div>
  );
};

const DatabaseResourceSection = (_props: {}) => {
  const { t } = useTranslation();
  return (
    <div className={styles.resource}>
      <div className={styles.resource__header}>
        {t("roles_wizard.privileges.column_databases")}
      </div>
    </div>
  );
};

const TableHeader = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.row}>
      <div className={classNames(styles.column, styles.column__objects)}>
        {t("roles_wizard.privileges.column_objects")}
      </div>
      <div className={styles.column} />
      <div className={classNames(styles.column, styles.column__granted)}>
        {t("roles_wizard.privileges.column_granted")}
      </div>
      <div className={classNames(styles.column, styles.column__revoked)}>
        {t("roles_wizard.privileges.column_revoked")}
      </div>
    </div>
  );
};

export const PrivilegesTable = () => {
  const [search, setSearch] = useState("");

  const handleChangeSearch = (value: string) => {
    setSearch(value);
  };

  return (
    <div className={styles.table}>
      <div className={styles.table__header}>
        <TableHeader />
        <Search
          value={search}
          onChange={handleChangeSearch}
          placeholder="Search"
          testId="search-roles"
          className={styles.search}
        />
      </div>
      <AccountResourceSection />
      <DatabaseResourceSection />
    </div>
  );
};
