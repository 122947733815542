import classNames from "classnames";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { FixedSizeList } from "react-window";

import { formatNumber } from "components/Datagrid/utils/formatCell";

import { CandidatesEmptyState } from "./EmptyState";
import { Item } from "./Item";

import styles from "./styles.module.scss";

interface CandidatesProps {
  items: {
    label: string;
    value: string;
    count: number;
  }[];
  search: string;
  onSelect: (item: { label: string; value: string; count: number }) => void;
  filters: {
    include: {
      itemsSet: Set<string>;
    };
  };
  allChecked: boolean;
  handleSelectAll: () => void;
  checkAllChbChecked: boolean;
}

export const Candidates = (props: CandidatesProps) => {
  const {
    items,
    search,
    onSelect,
    filters,
    allChecked,
    handleSelectAll,
    checkAllChbChecked,
  } = props;
  const { include } = filters;
  const { t } = useTranslation();

  const handleSelectItem = useCallback(
    (item: { label: string; value: string; count: number }) => () => {
      onSelect(item);
    },
    [onSelect]
  );

  const Row = useCallback(
    ({ index, style }: any) => {
      if (index === 0) {
        return (
          <Item
            testId={`filter-item-${index}`}
            label={t("filters.show_hide_all")}
            onClick={handleSelectAll}
            checked={checkAllChbChecked}
            labelClassName={styles.item__label_default}
            style={style}
          />
        );
      }
      // to account for the dummy item of show/hide all item
      const actualIndex = index - 1;
      const item = items[actualIndex];
      const checked = allChecked || include.itemsSet.has(item.value);
      const count = formatNumber(item.count);
      return (
        <Item
          testId={`filter-item-${index}`}
          label={item.label}
          count={count}
          onClick={handleSelectItem(item)}
          checked={checked}
          style={style}
          search={search}
        />
      );
    },
    [
      handleSelectItem,
      items,
      include.itemsSet,
      allChecked,
      search,
      checkAllChbChecked,
      handleSelectAll,
      t,
    ]
  );

  if (!items.length) {
    const message = search ? t("filters.no_matches") : t("filters.no_data");
    return (
      <CandidatesEmptyState
        testId="filters-candidates-empty"
        message={message}
      />
    );
  }
  // to account for show/hide all item
  const totalItemsToBeRendered = items.length + 1;
  const isOverflowing = totalItemsToBeRendered > 4;
  return (
    <div
      className={classNames(styles.candidates, {
        [styles.candidates__overflowing]: isOverflowing,
      })}
      data-testid="filters-candidates"
    >
      <FixedSizeList
        height={128}
        itemCount={totalItemsToBeRendered}
        itemSize={32}
        width="100%"
      >
        {Row}
      </FixedSizeList>
    </div>
  );
};
