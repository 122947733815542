import {
  CLICK_MINIMAP,
  DRAG_FINISH,
  DRAG_MINIMAP,
  DRAG_MINIMAP_FINISH,
  FIT_CENTER,
  FIT_MINIMAP,
  INIT,
  RESTRICT_MINIMAP,
  SCROLL,
  TOGGLE_EXPAND,
  TOGGLE_MINIMAP,
  TRANSFORM,
  ZOOM,
} from "./actions";
import {
  MAX_ZOOM,
  MINIMAP_HEIGHT,
  MINIMAP_WIDTH,
  MIN_ZOOM,
  PADDING,
} from "./constants";

const getCenteredTransform = ({
  containerRect,
  draggableRect,
  k,
}: {
  containerRect: any;
  draggableRect: any;
  k: any;
}) => {
  const MIN_Y = 20;

  const x = containerRect.width / 2 - draggableRect.width / k / 2;

  const y = Math.max(
    containerRect.height / 2 - draggableRect.height / k / 2,
    MIN_Y
  );

  return { x, y, k: 1 };
};

export const minimapStartPosition = {
  x: 12,
  y: 20,
};

const restrictToContainer = ({
  container,
  sidebar,
  x,
  y,
}: {
  container: any;
  sidebar: any;
  x: number;
  y: number;
}) => {
  const containerRect = container.current.getBoundingClientRect();
  const sidebarRect = sidebar.current.getBoundingClientRect();
  return {
    x: Math.min(Math.max(x, 0), containerRect.width - sidebarRect.width),
    y: Math.min(Math.max(y, 0), containerRect.height - sidebarRect.height),
  };
};

export const interactionStateReducer = (state: any, action: any) => {
  switch (action.type) {
    case DRAG_FINISH: {
      return {
        ...state,
        cursor: "default",
        startPosition: state.transform,
      };
    }
    case INIT: {
      const { container, draggable, sidebar } = action;
      const containerRect = container.current.getBoundingClientRect();
      const draggableRect = draggable.current.getBoundingClientRect();

      const minimapScale =
        MINIMAP_WIDTH / Math.min(draggableRect.width, containerRect.width);

      const transform = getCenteredTransform({
        containerRect,
        draggableRect,
        k: 1,
      });

      return {
        ...state,
        initialized: true,
        fit: true,
        startPosition: transform,
        transform,
        minimap: {
          ...state.minimap,
          k: minimapScale,
          fit: false,
          collapsed: false,
        },
        container,
        draggable,
        sidebar,
      };
    }

    case ZOOM: {
      const { delta, ox, oy } = action;
      const k = state.transform.k;

      const zoom = Math.min(Math.max(k * (1 + delta), MIN_ZOOM), MAX_ZOOM);
      // TODO is it always one?
      // const d = (k - zoom) / (k - zoom);
      const d = 1;

      const transform = {
        ...state.transform,
        k: zoom,
      };

      if (d) {
        Object.assign(transform, {
          x: state.startPosition.x + ox * d,
          y: state.startPosition.y + oy * d,
        });
      }

      return {
        ...state,
        transform,
        startPosition: transform,
      };
    }

    case SCROLL: {
      const x = state.transform.x + action.transform.x;
      const y = state.transform.y + action.transform.y;
      return {
        ...state,
        transform: {
          ...state.transform,
          x,
          y,
        },
        startPosition: {
          ...state.startPosition,
          x,
          y,
        },
      };
    }
    case TRANSFORM: {
      return {
        ...state,
        cursor: "move",
        transform: {
          ...state.transform,
          x: state.startPosition.x + action.transform.x,
          y: state.startPosition.y + action.transform.y,
        },
      };
    }
    case CLICK_MINIMAP: {
      const { container } = state;
      const containerRect = container.current.getBoundingClientRect();

      const w = containerRect.width / 2;
      const h = containerRect.height / 2;

      const transform = {
        ...state.transform,
        x: action.transform.x + w,
        y: action.transform.y + h,
      };

      return {
        ...state,
        transform,
        startPosition: transform,
      };
    }
    case RESTRICT_MINIMAP: {
      const { sidebar, container } = state;
      const {
        transform: { x, y },
      } = state.minimap;
      const containerRect = container.current.getBoundingClientRect();

      if (x + MINIMAP_WIDTH + PADDING >= containerRect.width) {
        const transform = restrictToContainer({ container, sidebar, x, y });
        return {
          ...state,
          minimap: {
            ...state.minimap,
            transform,
            startPosition: transform,
          },
        };
      }
      return state;
    }
    case TOGGLE_MINIMAP: {
      const collapsed = state.minimap.collapsed;
      return {
        ...state,
        minimap: {
          ...state.minimap,
          collapsed: !collapsed,
          transform: minimapStartPosition,
          startPosition: minimapStartPosition,
        },
      };
    }
    case DRAG_MINIMAP: {
      const { sidebar, container } = state;
      const startX = state.minimap.startPosition.x;
      const startY = state.minimap.startPosition.y;

      const x = startX + action.transform.x;
      const y = startY + action.transform.y;

      return {
        ...state,
        minimap: {
          ...state.minimap,
          transform: restrictToContainer({ container, sidebar, x, y }),
        },
      };
    }
    case DRAG_MINIMAP_FINISH: {
      return {
        ...state,
        minimap: {
          ...state.minimap,
          startPosition: state.minimap.transform,
        },
      };
    }

    case FIT_MINIMAP: {
      const { draggable, transform } = state;
      const draggableRect = draggable.current.getBoundingClientRect();

      const largerDimention =
        Math.max(draggableRect.width, draggableRect.height) / transform.k;

      const minimapDimention =
        draggableRect.width > draggableRect.height
          ? MINIMAP_WIDTH
          : MINIMAP_HEIGHT;

      const minimapScale =
        minimapDimention / Math.max(largerDimention, minimapDimention);

      return {
        ...state,
        minimap: {
          ...state.minimap,
          k: minimapScale,
          fit: true,
        },
      };
    }
    case FIT_CENTER: {
      const { container, draggable } = state;
      const containerRect = container.current.getBoundingClientRect();
      const draggableRect = draggable.current.getBoundingClientRect();
      const transform = getCenteredTransform({
        containerRect,
        draggableRect,
        k: state.transform.k,
      });
      return {
        ...state,
        fit: true,
        transform,
        startPosition: transform,
      };
    }
    case TOGGLE_EXPAND: {
      const { id } = action;
      return {
        ...state,
        expandedNodes: {
          ...state.expandedNodes,
          [id]: !state.expandedNodes[id],
        },
      };
    }
    default:
      break;
  }
  return state;
};
