import { QueryClient } from "@tanstack/react-query";
import { organizationQueryKey } from "pages/configure/SSO/constants";

const organizationTrialBalanceQueryKey = "organizationTrialBalance";

export const updateOrganization = async ({
  queryClient,
}: {
  queryClient: QueryClient;
}): Promise<void> => {
  try {
    await Promise.all([
      queryClient.cancelQueries({ queryKey: [organizationQueryKey] }),
      queryClient.cancelQueries({ queryKey: [organizationTrialBalanceQueryKey] }),
    ]);
    await Promise.all([
      queryClient.invalidateQueries({ queryKey: [organizationQueryKey] }),
      queryClient.invalidateQueries({ queryKey: [organizationTrialBalanceQueryKey] }),
    ]);
  } catch (e) {
    console.error("Failed to update organization from WS: ", e);
  }
};
