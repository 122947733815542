import { Popover } from "@mui/material";
import { useTranslation } from "react-i18next";

import { FeatureFlag } from "featureFlags/constants";
import { useAllFlags } from "featureFlags/hooks/useAllFlags";
import { SqlRbacAction } from "services/rbac/action";

import { ReactComponent as PlusIcon } from "assets/icons/Plus.svg";

import { useCurrentAccount } from "components/Account/useCurrentAccount";
import { useMenu } from "components/ActionMenu/useMenu";
import { useAccessManager } from "components/App/accessManager";
import ContextMenu from "components/ContextMenu/ContextMenu";
import ContextMenuDivider from "components/ContextMenu/ContextMenuDivider";
import ContextMenuItem from "components/ContextMenu/ContextMenuItem";

import { HeaderIcon } from "../HeaderIcon/HeaderIcon";
import { useCreateDatabase } from "./useCreateDatabase";
import { useCreateEngine } from "./useCreateEngine";
import { useCreateRole } from "./useCreateRole";
import { useCreateUser } from "./useCreateUser";
import { useLoadData } from "./useLoadData";

import styles from "./styles.module.scss";

export const CreateMenu = () => {
  const { menuElement, openMenu, closeMenu } = useMenu();

  const { t } = useTranslation();
  const { openCreateEngine, createEngineMarkup } = useCreateEngine();
  const { openCreateDatabase, createDatabaseMarkup } = useCreateDatabase();
  const { openCreateUser, createUserMarkup } = useCreateUser();
  const { openCreateRole, createRoleMarkup } = useCreateRole();
  const { openLoadData, loadDataMarkup } = useLoadData();

  const { isAllowedTo } = useAccessManager();
  const { getAccount } = useCurrentAccount();
  const flags = useAllFlags();

  const account = getAccount();

  const canCreateEngine = isAllowedTo(
    "account",
    account?.accountName as string,
    SqlRbacAction.ENGINE_CREATE
  );

  const canCreateDatabase = isAllowedTo(
    "account",
    account?.accountName as string,
    SqlRbacAction.DATABASE_CREATE
  );

  const canCreateRole = isAllowedTo(
    "account",
    account?.accountName as string,
    SqlRbacAction.ROLE_CREATE
  );

  const canCreateUser = isAllowedTo(
    "account",
    account?.accountName as string,
    SqlRbacAction.USER_CREATE
  );

  const availableActions = [
    canCreateDatabase,
    canCreateEngine,
    canCreateRole,
    canCreateUser,
  ].filter(Boolean);

  const isWizardEnabled = flags[FeatureFlag.FireboltAppEnableIngestionWizard];
  const isRolesWizardEnabled = flags[FeatureFlag.FireboltAppEnableRoleWizard];

  if (!availableActions.length) {
    return null;
  }

  return (
    <>
      <button
        className={styles.create}
        onClick={openMenu}
        data-testid="create-object"
      >
        <HeaderIcon
          icon={<PlusIcon />}
          active={!!menuElement}
        />
      </button>

      <Popover
        onClose={closeMenu}
        open={Boolean(menuElement)}
        anchorEl={menuElement}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        classes={{ paper: styles.menu }}
      >
        <ContextMenu>
          {isWizardEnabled && (
            <ContextMenuItem
              text={t("workspace.create_menu.load_data")}
              testId="load-data-menu-item"
              onClick={() => {
                closeMenu();
                openLoadData();
              }}
            />
          )}
          {canCreateDatabase && (
            <ContextMenuItem
              text={t("workspace.create_menu.database")}
              testId="create-database-menu-item"
              onClick={() => {
                closeMenu();
                openCreateDatabase();
              }}
            />
          )}
          {canCreateEngine && <ContextMenuDivider />}
          {canCreateEngine && (
            <ContextMenuItem
              text={t("workspace.create_menu.engine")}
              testId="create-engine-menu-item"
              onClick={() => {
                closeMenu();
                openCreateEngine();
              }}
            />
          )}
          {(canCreateUser || canCreateRole) && <ContextMenuDivider />}
          {isRolesWizardEnabled && canCreateRole && (
            <ContextMenuItem
              text={t("workspace.create_menu.role")}
              testId="create-role-menu-item"
              onClick={() => {
                closeMenu();
                openCreateRole();
              }}
            />
          )}
          {canCreateUser && (
            <ContextMenuItem
              text={t("workspace.create_menu.user")}
              testId="create-user-menu-item"
              onClick={() => {
                closeMenu();
                openCreateUser();
              }}
            />
          )}
        </ContextMenu>
      </Popover>
      {createEngineMarkup}
      {createDatabaseMarkup}
      {createUserMarkup}
      {createRoleMarkup}
      {loadDataMarkup}
    </>
  );
};
