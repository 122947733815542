import { useCallback, useEffect } from "react";

import { formatCell } from "../utils/formatCell";
import { getTotalSelections } from "../utils/interactions";
import { getCopyText } from "../utils/tableUtils";

export const useCopy = ({
  state,
  getRawCell,
}: {
  state: {
    selections: any;
    activeSelection: any;
    activeCell: any;
    editingCell: any;
  };
  getRawCell: (
    rowIndex: number,
    columnIndex: number
  ) => {
    row: any;
    column: any;
  };
}) => {
  const handleCopy = useCallback(() => {
    const { selections, activeSelection, activeCell, editingCell } = state;

    if (!activeCell || editingCell) {
      return;
    }

    const totalSelections = getTotalSelections({
      activeSelection,
      activeCell,
      selections,
    });

    const getValue = (rowIndex: number, columnIndex: number) => {
      const { row, column } = getRawCell(rowIndex, columnIndex);
      const { value } = formatCell({row, column, shouldLimitValueLength: false});
      return value;
    };

    const text = getCopyText({
      selections: totalSelections,
      getValue,
    });

    navigator.clipboard.writeText(text);
  }, [state, getRawCell]);

  useEffect(() => {
    document.addEventListener("copy", handleCopy);
    return () => {
      document.removeEventListener("copy", handleCopy);
    };
  }, [handleCopy]);

  return {
    copySelection: handleCopy,
  };
};
