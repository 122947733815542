import { useContext, useMemo } from "react";

import MaterialButton, { ButtonSize, ButtonTemplate } from "components/Button";
import { FILTER_INCLUDE_ALL } from "components/Datagrid/Filters/actions";
import { FiltersContext } from "components/Datagrid/context";
import { formatNumber } from "components/Datagrid/utils/formatCell";

import styles from "./styles.module.scss";

interface Props {
  applyFilters: () => void;
  localFilters: any;
  itemsCount: number;
}
export const Footer = (props: Props) => {
  const { applyFilters, localFilters, itemsCount } = props;
  const { closeFilters } = useContext(FiltersContext);
  const { include } = localFilters;

  const countToApply = useMemo(() => {
    if (include?.type === FILTER_INCLUDE_ALL) {
      return itemsCount;
    }
    return include?.items?.reduce((acc: number, item: any) => {
      return acc + item.count;
    }, 0);
  }, [include, itemsCount]);

  // todo add rule for disabled
  return (
    <div className={styles.footer}>
      <MaterialButton
        text={`Apply (${formatNumber(countToApply)})`}
        disabled={false}
        onClick={applyFilters}
        size={ButtonSize.Medium}
        template={ButtonTemplate.Primary}
        dataTestid="filters-apply"
      />
      <MaterialButton
        text="Cancel"
        onClick={closeFilters}
        template={ButtonTemplate.Secondary}
        size={ButtonSize.Medium}
        dataTestid="filters-cancel"
      />
    </div>
  );
};
