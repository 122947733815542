import camelCase from "lodash/camelCase";
import { useState } from "react";

import DialogComponent from "components/DialogComponent";
import ReadOnlyEditor from "components/ReadOnlyEditor";
import { exportScript } from "components/utils/scripts";

import styles from "./styles.module.scss";

type Props = {
  close: () => void;
  definition: {
    title: string;
    value: string;
  } | null;
};

const ViewDefinitionModal = (props: Props) => {
  const { definition, close } = props;
  const { title, value = "" } = definition || {};

  const handleDownload = () => {
    exportScript({ query: value, name: camelCase(title) });
  };

  const handleCopy = async () => {
    await navigator.clipboard.writeText(value);
  };

  return (
    <DialogComponent
      openModal={!!definition}
      showCloseIcon
      contentMaxWidth="630px"
      confirmButtonOptions={{
        text: "Download",
        onConfirm: handleDownload,
      }}
      secondaryButtonOptions={{
        text: "Copy to clipboard",
        onClick: handleCopy,
      }}
      closeModal={close}
      title={title || ""}
    >
      <div className={styles.root}>
        <ReadOnlyEditor value={value || ""} />
      </div>
    </DialogComponent>
  );
};

export const useViewDefinitions = () => {
  const [definition, setDefinition] = useState<{
    title: string;
    value: string;
  } | null>(null);

  const openViewDefinitions = ({
    title,
    value,
  }: {
    title: string;
    value: string;
  }) => {
    setDefinition({ title, value });
  };

  const closeViewDefinitions = () => {
    setDefinition(null);
  };

  const viewDefinitionsMarkup = (
    <ViewDefinitionModal
      close={closeViewDefinitions}
      definition={definition}
    />
  );

  return {
    viewDefinitionsMarkup,
    openViewDefinitions,
    closeViewDefinitions,
  };
};
