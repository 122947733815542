import z from "zod";

const schema = z.object({
  playgroundBucket: z.boolean().default(false),
  storageUrl: z
    .string()
    .regex(/^s3:\/\/.+/, "Storage url should start with s3://"),
});

const schemaWithSecret = z.object({
  authType: z.literal("secret"),
  key: z.string().optional(),
  secret: z.string().optional(),
});

const schemaWithRole = z.object({
  authType: z.literal("iam"),
  iamRole: z.string().min(1),
  key: z.string().optional(),
  secret: z.string().optional(),
});

const schemaConditions = z.discriminatedUnion("authType", [
  schemaWithSecret,
  schemaWithRole,
]);

export const validationSchema = z.intersection(schemaConditions, schema);

export type Fields = z.infer<typeof validationSchema>;
