export enum FeatureFlag {
  FireboltAppReadOnlyMaintenanceMode = "fireboltAppReadOnlyMaintenanceMode",
  FireboltAppFullMaintenanceMode = "fireboltAppFullMaintenanceMode",
  FireboltAppSpotInstances = "fireboltAppSpotInstances",
  FireboltAppQueryExplain = "fireboltAppQueryExplain",
  FireboltAppBypassQueryQueue = "fireboltAppBypassQueryQueue",
  FireboltAppUseSpotInstancesByDefault = "fireboltAppUseSpotInstancesByDefault",
  FireboltAppEnableContactSupportFormLoggedIn = "fireboltAppEnableContactSupportFormLoggedIn",
  FireboltAppEnableEditRunningScript = "fireboltAppEnableEditRunningScript",
  FireboltAppDefaultHybridQueryExecution = "fireboltAppDefaultHybridQueryExecution",
  FireboltAppRegisterCustomOrgNameEnabled = "fireboltAppRegisterCustomOrgNameEnabled",
  FireboltAppEnableQueryProfile = "fireboltAppEnableQueryProfile",
  FireboltRBACEnabled = "enableRbac",
  ServiceIdentityBillingEnableRegisterWithCredits = "serviceIdentityBillingEnableRegisterWithCredits",
  FireboltAppEnableEngineObservAndQueryHistory = "fireboltAppEnableEngineObservAndQueryHistory",
  FireboltAppEnableEngineClusterProperty = "fireboltAppEnableEngineClusterProperty",
  FireboltAppEnableBillingV2 = "fireboltAppEnableBillingV2",
  FireboltAppEnableIngestionWizard = "fireboltAppEnableIngestionWizard",
  FireboltAppEnableRoleWizard = "fireboltAppEnableRoleWizard",
  FireboltAppEnableLargeInstanceTypes = "fireboltAppEnableLargeInstanceTypes",
  FireboltAppShowRolesPage = "fireboltAppShowRolesPage",
  FireboltAppEnableRegistrationPage = "fireboltAppEnableRegistrationPage",
  FireboltAppEnableReadCsvNewParams = "fireboltAppEnableReadCsvNewParams",
  FireboltAppEnableIsCreditsForStorageBilling = "fireboltAppEnableIsCreditsForStorageBilling",
  FireboltAppEnableDemoTool = "fireboltAppEnableDemoTool",
}

export const FEATURE_FLAG_INFO_MESSAGE = {
  [FeatureFlag.FireboltAppReadOnlyMaintenanceMode]:
    "Application is in read-only mode",
};
