import { QueryFormatter } from "firebolt-sdk/build/src/formatter";
import { JSONParser } from "firebolt-sdk/build/src/statement/stream/parser";
import camelCase from "lodash/camelCase";
import { QUERY_OUTPUT } from "types/outputFormat";

import { MyAccount } from "services/account/account.types";
import { privateApi } from "services/api/private";

import { authService } from "../auth";
import { normalizeSystemEngineURL, throwErrorMessage } from "./helpers";
import JSONError from "./JsonError";

type Columns<T> = { name: keyof T; type: string }[];

type Result<T extends Record<string, unknown>> = {
  rows: T[];
  columns: Columns<T>;
};

type Parameter = unknown;

type Options<T> = {
  parameters?: Parameter[];
  transformRow?: (row: T, columns: Columns<T>) => typeof row;
  namedParameters?: Record<string, unknown>;
  token?: string;
  database?: string;
  endpoint?: string;
  async?: boolean;
  signal?: AbortSignal;
};

export class SystemEngineEnvironment {
  apiEndpoint: string;

  formatter: QueryFormatter;

  account_id?: string; // account context

  constructor() {
    this.apiEndpoint = "";
    this.formatter = new QueryFormatter();
  }

  setAccountContext = (account_id: string) => {
    this.account_id = account_id;
  };

  resetAccountContext = () => {
    this.account_id = undefined;
  };

  initializeDefaultApiEndpoint = async () => {
    const url = `/engineUrl`;
    const response = await privateApi.get<{ engineUrl: string }>(url);
    const data = response.data;
    const { engineUrl } = data;
    this.apiEndpoint = `${engineUrl}`;
  };

  initializeApiEndpoint = async (account: MyAccount) => {
    const url = `/account/${account.accountName}/engineUrl`;
    const response = await privateApi.get<{ engineUrl: string }>(url);
    const data = response.data;
    const { engineUrl } = data;
    const normalizedSystemEngineUrl = normalizeSystemEngineURL(engineUrl);
    this.apiEndpoint = `${normalizedSystemEngineUrl}`;
  };

  setApiEndpoint = (apiEndpoint: string) => {
    this.apiEndpoint = apiEndpoint;
  };

  getApiEndpoint = () => {
    return this.apiEndpoint;
  };

  buildRequestUrl = <T>(options?: Options<T>) => {
    const searchParams = new URLSearchParams({
      output_format: QUERY_OUTPUT.JSON,
    });

    if (options?.database) {
      searchParams.append("database", options.database);
    }

    if (options?.async) {
      searchParams.append("async", "true");
    }

    const queryString = searchParams ? `?${searchParams.toString()}` : "";
    const path = "/query";

    if (options?.endpoint) {
      return options.endpoint + queryString;
    }
    return this.apiEndpoint + path + queryString;
  };

  execute = async <T extends Record<string, unknown>>(
    sql: string,
    options?: Options<T>
  ): Promise<Result<T>[]> => {
    const token = options?.token || (await authService.getToken());
    if (!token) {
      await authService.redirectToLogout();
      return [];
    }
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      "Firebolt-Machine-Query": "1"
    };

    const formated = this.formatter.formatQuery(
      sql,
      options?.parameters,
      options?.namedParameters
    );

    const requestOptions = {
      method: "POST",
      headers,
      body: formated,
      signal: options?.signal,
    };

    const url = this.buildRequestUrl<T>(options);

    try {
      const response = await fetch(url, requestOptions);
      const contentType = response.headers.get("content-type");

      if (response.status !== 200 && response.status !== 201) {
        try {
          const error = await response.clone().json();
          const errorMessage = error?.errors?.[0]?.description;
          throw new JSONError(errorMessage);
        } catch (e) {
          if (e instanceof JSONError) {
            return throwErrorMessage(response.status, e.message)
          }

          const text = await response.text();

          return throwErrorMessage(response.status, text)
        }
      }
      if (contentType && contentType.match(/text/)) {
        const error = await response.text();
        const message = "Query failed with resason: " + error;
        throw new Error(message);
      }

      if (contentType && contentType.match(/application\/json/)) {
        const body = await response.text();
        const parser = new JSONParser({});
        parser.processBody(body);
        const results = parser.results.map(result =>
          this.transformResult(result as Result<T>, options)
        );
        return results as Result<T>[];
      }
      return [];
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  transformResult = <T extends Record<string, unknown>>(
    result: Result<T>,
    options?: Options<T>
  ): Result<T> => {
    const { columns, rows, ...rest } = result;

    const transformedColumns = columns.map(column => {
      const name = camelCase(column.name as string) as keyof T;
      return { ...column, name };
    });

    const transformedRows = rows.map(row => {
      const transformedRow: Record<string, unknown> = {};

      for (const columnName in row) {
        const value = row[columnName];
        const name = camelCase(columnName);
        transformedRow[name] = value;
      }

      return this.transformRow(
        transformedRow as T,
        transformedColumns,
        options
      );
    });

    return { columns: transformedColumns, rows: transformedRows, ...rest };
  };

  transformRow = <T extends Record<string, unknown>>(
    row: T,
    columns: Columns<T>,
    options?: Options<T>
  ) => {
    return options?.transformRow ? options.transformRow(row, columns) : row;
  };
}

export const systemEngineEnvironment = new SystemEngineEnvironment();
