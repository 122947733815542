import { PostgreSQL, SQLDialect, sql } from "@codemirror/lang-sql";
import { highlightCode, tagHighlighter, tags } from "@lezer/highlight";
import { Divider, TooltipProps } from "@mui/material";
import cn from "classnames";
import React from "react";

import { FIREBOLT_KEYWORDS } from "pages/DevelopWorkspace/Editor/Document/DocumentContent/DocumentEditor/constants";

import Tooltip from "components/Tooltip";

import styles from "./styles.module.scss";

type Props = {
  title: string;
  content: string;
  children: React.ReactElement<any, any>;
  placement?: TooltipProps["placement"];
  tooltipClassName?: string;
  open?: boolean;
  controlledProps?: {
    open?: boolean;
    onOpen?: () => void;
    onClose?: () => void;
  };
  footer?: React.ReactElement<any, any>;
  footerHidden?: boolean;
};

export const cutContent = (content: string) =>
  content
    .trim()
    .split(/\r\n|\r|\n/)
    .splice(0, 10)
    .join("\n");

const highlighter = tagHighlighter(
  [
    {
      tag: tags.keyword,
      class: "ͼb",
    },
    {
      tag: tags.string,
      class: "ͼf",
    },
    {
      tag: tags.comment,
      class: "ͼm",
    },
    {
      tag: tags.number,
      class: "ͼd",
    },
  ],
  {}
);

function getHighlightedHTML(content: string) {
  let html = "";

  highlightCode(
    content,
    sql({
      dialect: SQLDialect.define({
        ...PostgreSQL.spec,
        keywords: FIREBOLT_KEYWORDS,
      }),
    }).language.parser.parse(content),
    highlighter,
    (text, classes) => {
      html += `<span class="${classes}">${text}</span>`;
    },
    () => {
      html += "<br/>";
    }
  );

  return html;
}

export const HintTooltip: React.FC<Props> = props => {
  const {
    children,
    footer = null,
    footerHidden = false,
    title,
    content,
    placement,
    tooltipClassName,
    controlledProps = {},
  } = props;

  const element = title ? (
    <div
      data-testid="script-hint"
      className={styles.wrapper}
    >
      <div className={styles.title}>{title}</div>
      {content && (
        <>
          <Divider
            key="divider"
            classes={{ root: cn(styles.divider, styles.forced) }}
          />
          <div
            data-testid="script-hint-content"
            className={cn(styles.query)}
            // eslint-disable-next-line react/no-danger -- html is sanitized
            dangerouslySetInnerHTML={{
              __html: getHighlightedHTML(cutContent(content)),
            }}
          />
        </>
      )}
      {footer && !footerHidden && (
        <>
          <Divider
            key="divider"
            classes={{ root: cn(styles.divider, styles.forced) }}
          />
          {footer}
        </>
      )}
    </div>
  ) : null;

  return (
    <Tooltip
      classes={{
        arrow: cn(styles.arrow, styles.forced),
        tooltip: cn(styles.tooltip, styles.forced, tooltipClassName),
      }}
      enterDelay={500}
      enterNextDelay={500}
      title={element}
      placement={placement}
      {...controlledProps}
      arrow
    >
      {children}
    </Tooltip>
  );
};
