import { useEffect } from "react";

import { systemEngineEnvironment } from "services/environment/systemEngine";

import useActiveDocument from "pages/DevelopWorkspace/contexts/DocumentsContext/hooks/useActiveDocument";
import {
  SchemaDescriptor,
  SchemaTable,
  useSQLynx,
} from "pages/DevelopWorkspace/contexts/SQLynxContext/SQLynxLoader";

import { NONE } from "../Editor/Document/DocumentContextSelector/types";

const SCHEMA_PUBLIC = "public";
const SCHEMA_INFORMATION = "information_schema";

interface Row {
  tableSchema: string;
  tableName: string;
  columnName: string;
}

const fetchSchemaDescriptor = async (
  databaseName: string,
  schemaName: string
): Promise<SchemaDescriptor | undefined> => {
  try {
    const response = await systemEngineEnvironment.execute(
      `select
        column_name,
        table_schema,
        table_name
      from
        information_schema.columns
      where
        table_schema = '${schemaName}';`,
      { database: databaseName }
    );

    const rows = response?.[0]?.rows
      ? (response[0].rows as unknown as Row[])
      : undefined;
    if (rows) {
      return {
        databaseName,
        schemaName,
        tables: rows.reduce((acc, row) => {
          if (row.tableSchema !== schemaName) return acc;
          const { tableName, columnName } = row;
          const schema = acc.find(s => s.tableName === tableName);
          if (schema) {
            schema.columns.push(columnName);
          } else {
            acc.push({ tableName, columns: [columnName] });
          }
          return acc;
        }, [] as SchemaTable[]),
      };
    }
  } catch (error) {
    console.error(
      `Error fetching schema descriptor for schema ${databaseName}.${schemaName}:`,
      error
    );
  }
  return undefined;
};

const useSchemaUpdater = (): void => {
  const { addDescriptor, descriptors } = useSQLynx();
  const activeDocument = useActiveDocument();

  useEffect(() => {
    const databaseName = activeDocument?.context.databaseName;

    if (!databaseName || databaseName === NONE) return;

    // Check if the information schema descriptor is missing
    const informationSchemaDescriptor = descriptors.find(
      d => d.databaseName === "" && d.schemaName === SCHEMA_INFORMATION
    );

    if (!informationSchemaDescriptor) {
      fetchSchemaDescriptor(databaseName as string, SCHEMA_INFORMATION).then(
        schemaDescriptor => {
          if (schemaDescriptor) {
            addDescriptor(schemaDescriptor);
          }
        }
      );
    }

    // Check if the public schema descriptor is missing
    const publicSchemaDescriptor = descriptors.find(
      d => d.databaseName === databaseName && d.schemaName === SCHEMA_PUBLIC
    );

    if (!publicSchemaDescriptor) {
      fetchSchemaDescriptor(databaseName as string, SCHEMA_PUBLIC).then(
        schemaDescriptor => {
          if (schemaDescriptor) {
            addDescriptor(schemaDescriptor);
          }
        }
      );
    }
  }, [activeDocument?.context.databaseName, descriptors, addDescriptor]);
};

export default useSchemaUpdater;
