import { RolesWizardStep } from "../types";
import { AssignStep } from "./AssignStep";
import { NameStep } from "./NameStep";
import { PrivilegesStep } from "./PrivilegesStep";

export const stepsByType = {
  [RolesWizardStep.name]: NameStep,
  [RolesWizardStep.privileges]: PrivilegesStep,
  [RolesWizardStep.assign]: AssignStep,
};
