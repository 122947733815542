import classNames from "classnames";
import React from "react";

import { useDocuments } from "pages/DevelopWorkspace/contexts/DocumentsContext/hooks/useDocuments";
import { QueryStatement } from "pages/DevelopWorkspace/workspace.types";

import StatisticsHeader from "./StatisticsHeader";
import StatisticsRow from "./StatisticsRow";

import styles from "./QueryStatementsTable.module.scss";

type QueryStatementsTableProps = {
  queryStatements: QueryStatement[];
  documentId: string;
};

const StatisticsHeaderValues = [
  {
    tCode: "statistics.header_no",
    className: classNames(styles.statementNr, styles.statementNrHeader),
  },
  { tCode: "statistics.header_statement", className: styles.statement },
  { tCode: "statistics.header_status", className: styles.status },
  { tCode: "statistics.header_duration", className: styles.duration },
  { tCode: "statistics.header_rows", className: styles.rows },
  { tCode: "statistics.header_scanned", className: styles.scanned },
  { tCode: "statistics.header_rowsPerSec", className: classNames(styles.rowsPerSec, styles.rowsPerSecHeader) },
];

const QueryStatementsTable = (props: QueryStatementsTableProps) => {
  const { queryStatements, documentId } = props;
  const {
    actions: { switchDocumentActiveQueryStatement },
  } = useDocuments();

  const queryStatementsReversed = [...queryStatements].reverse();

  return (
    <div className={styles.statisticsContainer}>
      <StatisticsHeader headers={StatisticsHeaderValues} />
      <div>
        {queryStatementsReversed.map((queryStatement, index, arr) => {
          return (
            <StatisticsRow
              key={queryStatement.id}
              queryStatement={queryStatement}
              switchDocumentActiveQueryStatement={
                switchDocumentActiveQueryStatement
              }
              documentId={documentId}
              rowNr={arr.length - index}
            />
          );
        })}
      </div>
    </div>
  );
};

export default QueryStatementsTable;
