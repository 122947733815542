import { useSuspenseQuery } from "@tanstack/react-query";

import { authService } from "./index";

export const useUser = () => {
  const { data } = useSuspenseQuery({
    queryKey: ["user"],
    queryFn: () => {
      return authService.auth0client.getUser();
    },
  });
  return data || {};
};
