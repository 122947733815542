import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import classNames from "classnames";
import React from "react";

import styles from "./Accordion.module.scss";

type Customizations = {
  noBorder?: boolean;
};

type Props = {
  expanded: boolean;
  collapsedSummary: React.ReactNode;
  expandedSummary?: React.ReactNode;
  accordionDetails: React.ReactNode;
  customizations?: Customizations;
};

const CustomAccordion = (props: Props) => {
  const {
    expanded,
    collapsedSummary,
    expandedSummary,
    accordionDetails,
    customizations,
  } = props;

  const fallbackExpandedSummary = expandedSummary ?? collapsedSummary;

  return (
    <>
      <Accordion
        classes={{
          root: classNames(styles.accordionRoot, {
            [styles.expanded]: expanded,
            [styles.noBorder]: customizations?.noBorder,
          }),
        }}
        expanded={expanded}
      >
        <AccordionSummary
          classes={{
            root: classNames(styles.accordionSummary, {
              [styles.expanded]: expanded,
            }),
            content: styles.accordionContent,
          }}
          focusVisibleClassName={styles.focused}
        >
          {!expanded && collapsedSummary}
          {expanded && fallbackExpandedSummary}
        </AccordionSummary>
        <AccordionDetails classes={{ root: styles.accordionDetails }}>
          {accordionDetails}
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default CustomAccordion;
