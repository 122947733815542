import { useSuspenseQuery } from "@tanstack/react-query";

import { format } from "pages/DevelopWorkspace/utils/format-sql";

import { steps } from "../Steps/constants";
import { WizardDataType } from "../types";

export const getPreviewScript = (
  activeStepIndex: number,
  wizardData: WizardDataType
) => {
  const usedSteps = steps.slice(0, activeStepIndex);

  const script = usedSteps
    .filter(step => step.generateScript)
    .map(step => {
      const sqlPart = step.generateScript!(wizardData);
      return sqlPart;
    })
    .join("\n");

  return format(script, {
    keywordCase: "preserve",
    linesBetweenQueries: 2,
  }).then(formatted => {
    if (typeof formatted === "string") {
      return formatted;
    }
    return "Something went wrong";
  });
};

export const usePreviewScript = ({
  activeStepIndex,
  wizardData,
}: {
  activeStepIndex: number;
  wizardData: WizardDataType;
}) => {
  const { data } = useSuspenseQuery({
    queryKey: ["previewScript"],
    queryFn: () => getPreviewScript(activeStepIndex, wizardData),
    retry: false,
    gcTime: 0,
  });
  return data || "";
};
