import { Suspense, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import ErrorMessage from "pages/DevelopWorkspace/Editor/Document/DocumentOutput/DocumentResults/ErrorMessage/ErrorMessage";

import { SimpleErrorBoundary } from "components/ErrorBoundary/SimpleErrorBoundary";
import LoadingOverlap from "components/LoadingOverlap";
import { Sidebar } from "components/Wizard/Sidebar/Sidebar";
import { Step } from "components/Wizard/Step/Step";
import { Wizard } from "components/Wizard/Wizard";
import { useActiveStep } from "components/Wizard/useActiveStep";

import { stepsByType } from "./steps";
import { RolesDataType, RolesWizardStep } from "./types";

import styles from "./styles.module.scss";

const steps = [
  {
    id: RolesWizardStep.name,
    title: "roles_wizard.name.title",
  },
  {
    id: RolesWizardStep.privileges,
    title: "roles_wizard.privileges.title",
  },
  {
    id: RolesWizardStep.assign,
    title: "roles_wizard.assign.title",
  },
];

export const RolesWizard = (props: {
  onClose: () => void;
  onCreateRole: (role: any) => void;
}) => {
  const { onClose, onCreateRole } = props;
  const { t } = useTranslation();

  const [rolesData, setRolesData] = useState<RolesDataType>({
    [RolesWizardStep.name]: {
      name: "",
      description: "",
    },
    [RolesWizardStep.privileges]: {
      privileges: [],
    },
    [RolesWizardStep.assign]: {
      grantee: null,
    },
  });

  const {
    activeStepIndex,
    activeStep,
    handleNextStep,
    handlePreviousStep,
    handleSelectStep,
  } = useActiveStep<RolesWizardStep>({
    onNextStep: (data: any) => {
      setRolesData(wizardData => ({ ...wizardData, [activeStep]: data }));
    },
    initialStep: RolesWizardStep.name,
    steps,
  });

  const StepComponent: React.ComponentType<any> = useMemo(
    () => stepsByType[activeStep],
    [activeStep]
  );

  return (
    <Wizard
      onClose={onClose}
      title={t("roles_wizard.title")}
      subtitle={t("roles_wizard.subtitle")}
      body={
        <>
          <Sidebar<RolesWizardStep>
            activeStep={activeStep}
            activeStepIndex={activeStepIndex}
            handleSelectStep={handleSelectStep}
            steps={steps}
          />
          <div className={styles.content}>
            <SimpleErrorBoundary
              renderError={error => (
                <Step
                  title={t("roles_wizard.title")}
                  subtitle={null}
                  body={<ErrorMessage errorMessage={error.message} />}
                  bodyClassName={styles.errorBody}
                  onClose={onClose}
                  onSubmit={() => {}}
                  activeStepIndex={activeStepIndex}
                  onPrevStep={handlePreviousStep}
                  disabledSubmit
                />
              )}
              key={`step-component-${activeStep}`}
            >
              <Suspense fallback={<LoadingOverlap isLoading />}>
                <StepComponent
                  onClose={onClose}
                  onSubmit={handleNextStep}
                  onPrevStep={handlePreviousStep}
                  initialData={rolesData[activeStep]}
                  rolesData={rolesData}
                  activeStepIndex={activeStepIndex}
                  onCreateRole={onCreateRole}
                />
              </Suspense>
            </SimpleErrorBoundary>
          </div>
        </>
      }
    />
  );
};
