import { Suspense, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { DatabaseType } from "services/databases/types";
import { useDatabasesNames } from "services/databases/useDatabasesNames";
import { RbacActionObject, SqlRbacAction } from "services/rbac/action";

import { databaseActions } from "pages/govern/Roles/RbacAction";
import { usePrivilegesState } from "pages/govern/Roles/usePrivilegesContext";
import { useReadOnly } from "pages/govern/Roles/useReadOnly";

import { useCurrentParamsAccount } from "components/Account/useCurrentParamsAccount";
import Checkbox from "components/Checkbox";
import { EmptyDatabasesIcon } from "components/Icons";
import LoadingOverlap from "components/LoadingOverlap";
import SearchInput from "components/SearchInput/SearchInput";
import { Cell, Table } from "components/Table";
import { highlightText } from "components/helpers";

import { HeaderLabel } from "../HeaderLabel";
import {
  ADD_DATABASE_RESOURCE_PRIVILEGE,
  ADD_DATABASE_RESOURCE_PRIVILEGE_BULK,
  DELETE_DATABASE_RESOURCE_PRIVILEGE,
  DELETE_DATABASE_RESOURCE_PRIVILEGE_BULK,
} from "../privilegesReducer";
import { getHeaderTooltip } from "../utils";

import styles from "../styles.module.scss";

const DatabasesTable = (props: { searchQuery: string }) => {
  const { searchQuery } = props;
  const { privilegesState, privilegesDispatch } = usePrivilegesState();
  const { readOnly, affectedOnly, isActionDisabled } = useReadOnly();
  const data = useDatabasesNames();
  const currentAccount = useCurrentParamsAccount();
  const { t } = useTranslation();

  const { database, account } = privilegesState;

  const accountPrivileges = account[currentAccount.accountName] || {};
  const wildCardPrivileges = account["*"] || {};

  const databasePrivileges = Object.entries<
    Record<string, Record<SqlRbacAction, unknown>>
  >(database).reduce<Record<SqlRbacAction, unknown>[]>((acc, entry) => {
    const [key, privilege] = entry;
    if (key === "*") {
      return acc;
    }
    return [...acc, privilege];
  }, []);

  const getDatabases = (): { catalogName: string }[] => {
    if (affectedOnly) {
      return data.filter(db => {
        const privileges = database[db.catalogName] || {};
        return databaseActions.some(action => {
          return (
            privileges[action] ||
            accountPrivileges[action] ||
            wildCardPrivileges[action]
          );
        });
      });
    }
    return data;
  };

  const databases = getDatabases();

  const sortedDatabases = useMemo(
    () =>
      [...databases].sort((a, b) => {
        return a.catalogName.localeCompare(b.catalogName);
      }),
    [databases]
  );

  const usagePrivileges = databasePrivileges.filter(
    privilege => privilege[SqlRbacAction.DATABASE_USAGE]
  );

  const modifyPrivileges = databasePrivileges.filter(
    privilege => privilege[SqlRbacAction.DATABASE_MODIFY]
  );

  const checkedByType = {
    [SqlRbacAction.DATABASE_USAGE]: usagePrivileges.length === databases.length,
    [SqlRbacAction.DATABASE_MODIFY]:
      modifyPrivileges.length === databases.length,
  };

  const checkedAllByType = {
    [SqlRbacAction.DATABASE_USAGE]: Boolean(
      accountPrivileges[SqlRbacAction.DATABASE_USAGE_ANY] ||
        wildCardPrivileges[SqlRbacAction.DATABASE_USAGE_ANY]
    ),
    [SqlRbacAction.DATABASE_MODIFY]: Boolean(
      accountPrivileges[SqlRbacAction.DATABASE_MODIFY_ANY] ||
        wildCardPrivileges[SqlRbacAction.DATABASE_MODIFY_ANY]
    ),
  };

  const indeterminateByType = {
    [SqlRbacAction.DATABASE_USAGE]:
      !checkedByType[SqlRbacAction.DATABASE_USAGE] &&
      !checkedAllByType[SqlRbacAction.DATABASE_USAGE] &&
      Boolean(usagePrivileges.length),
    [SqlRbacAction.DATABASE_MODIFY]:
      !checkedByType[SqlRbacAction.DATABASE_MODIFY] &&
      !checkedAllByType[SqlRbacAction.DATABASE_MODIFY] &&
      Boolean(modifyPrivileges.length),
  };

  const filtered = sortedDatabases.filter(db => {
    return db.catalogName.toLowerCase().includes(searchQuery.toLowerCase());
  });

  const isDisabled = (action: SqlRbacAction) => {
    return (
      readOnly ||
      isActionDisabled("database", action) ||
      checkedAllByType[action as keyof typeof checkedAllByType]
    );
  };

  const handleSelectBulk = (action: SqlRbacAction) => () => {
    const disabled = isDisabled(action);
    if (disabled) {
      return;
    }
    const indeterminate =
      indeterminateByType[action as keyof typeof indeterminateByType];
    const checked = checkedByType[action as keyof typeof checkedByType];
    const resources = filtered.map(db => db.catalogName);

    const type =
      indeterminate || checked
        ? DELETE_DATABASE_RESOURCE_PRIVILEGE_BULK
        : ADD_DATABASE_RESOURCE_PRIVILEGE_BULK;

    privilegesDispatch({
      type,
      privilege: action,
      resources,
    });
  };

  const databaseColumns = [
    {
      id: "modify",
      label: t("roles.databases.header.modify"),
      action: SqlRbacAction.DATABASE_MODIFY,
    },
    {
      id: "usage",
      label: t("roles.databases.header.usage"),
      action: SqlRbacAction.DATABASE_USAGE,
    },
  ];

  const columns = [
    {
      id: "name",
      name: "Databases",
      width: 300,
      fillAvailableWidth: true,
      className: styles.cell__header,
    },
    ...databaseColumns.map(({ id, label, action }) => {
      return {
        id,
        name: (
          <HeaderLabel
            label={label}
            checked={
              indeterminateByType[action as keyof typeof indeterminateByType] ||
              checkedByType[action as keyof typeof checkedByType] ||
              checkedAllByType[action as keyof typeof checkedAllByType]
            }
            indeterminate={
              indeterminateByType[action as keyof typeof indeterminateByType]
            }
            handleSelect={handleSelectBulk(action)}
            action={action}
            disabled={isDisabled(action)}
            tooltip={getHeaderTooltip(t, RbacActionObject.DATABASE, action)}
          />
        ),

        width: 110,
        className: styles.cell__header,
      };
    }),
  ];

  if (!filtered.length) {
    const message = searchQuery
      ? t("roles.databases.empty_state")
      : t("roles.databases.affected_empty_state");
    return (
      <div className={styles.emptyState}>
        <EmptyDatabasesIcon />
        <div className={styles.emptyState__message}>{message}</div>
      </div>
    );
  }

  const rowRenderer = ({ row }: { row: unknown }) => {
    const { catalogName } = row as DatabaseType;
    const databasePrivileges = database[catalogName] || {};

    const handleClick = (action: SqlRbacAction) => () => {
      const disabled = isDisabled(action);
      if (disabled) {
        return;
      }

      const checked = databasePrivileges[action];

      const type = checked
        ? DELETE_DATABASE_RESOURCE_PRIVILEGE
        : ADD_DATABASE_RESOURCE_PRIVILEGE;

      privilegesDispatch({
        type,
        privilege: action,
        resource: {
          id: catalogName,
        },
      });
    };

    return (
      <div
        key={catalogName}
        className={styles.row}
      >
        <Cell
          id="name"
          className={styles.cell}
          fillAvailableWidth
        >
          <span
            title={catalogName}
            className={styles.cell__name}
          >
            {highlightText({
              searchWord: searchQuery,
              textToHighlight: catalogName,
            })}
          </span>
        </Cell>
        {databaseColumns.map(({ id, action }) => {
          return (
            <Cell
              id={id}
              className={styles.cell}
              key={id}
            >
              <Checkbox
                checked={
                  !!databasePrivileges[action] ||
                  checkedAllByType[action as keyof typeof checkedAllByType]
                }
                onClick={handleClick(action)}
                disabled={isDisabled(action)}
                testId={`db-specific-role-${action}`}
              />
            </Cell>
          );
        })}
      </div>
    );
  };

  return (
    <Table
      rowRenderer={rowRenderer}
      rows={filtered as unknown[]}
      columns={columns}
      disableResize
    />
  );
};

export const DatabasesList = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const { t } = useTranslation();

  const handleSearchInput = (value: string) => {
    setSearchQuery(value);
  };

  return (
    <>
      <div className={styles.search}>
        <SearchInput
          onChange={handleSearchInput}
          placeholder={t("roles.databases.search")}
          defaultValue={searchQuery}
          testId="database-search-input"
          inputClassName={styles.search__input}
          debounceMs={300}
        />
      </div>
      <div
        className={styles.table}
        data-testid="databases-list"
      >
        <Suspense fallback={<LoadingOverlap isLoading />}>
          <DatabasesTable searchQuery={searchQuery} />
        </Suspense>
      </div>
    </>
  );
};
