import React, { useCallback, useEffect } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { useLocation, useNavigate } from "react-router-dom";

import { setOrganizationOnboardingCompleted } from "services/organization/setOrganizationOnboarding";
import { useOrganizationApproved } from "services/organization/useOrganizationApproved";
import { isOrganizationApprovedQueryKey } from "services/organization/constants";

import WelcomeV2 from "components/Onboarding/WelcomeV2/WelcomeV2";
import { StatusMessageType } from "components/StatusMessageQueue/StatusMessageQueueProvider";
import useStatusMessageQueue from "components/StatusMessageQueue/hooks/useStatusMessageQueue";

export const OnboardingV2 = (props: React.PropsWithChildren) => {
  const { children } = props;
  const approved = useOrganizationApproved();
  const [open, setOpen] = React.useState(false);

  const location = useLocation();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { putStatusMessage } = useStatusMessageQueue();

  const { is_approved, welcome_completed } = approved || {};

  useEffect(() => {
    if (is_approved && !welcome_completed) {
      setOpen(true);
    }
  }, [is_approved, welcome_completed]);

  const setOnboardingCompleted = useCallback(async () => {
    try {
      await setOrganizationOnboardingCompleted();
      queryClient.removeQueries({ queryKey: [isOrganizationApprovedQueryKey] });
    } catch (error: any) {
      putStatusMessage({
        message: error.response?.data?.detail || error.message,
        type: StatusMessageType.Error,
      });
    }
  }, [putStatusMessage, queryClient]);

  const handleLoadData = useCallback(
    async (dontShowAgain: boolean) => {
      if (dontShowAgain) {
        setOnboardingCompleted();
      }

      setOpen(false);

      const accountName = location.pathname.split("/")[1];
      const path = `/${accountName}/develop?init=loadData`;
      navigate(path);
    },
    [navigate, location.pathname, setOnboardingCompleted]
  );

  const handleOnClose = async (dontShowAgain: boolean) => {
    setOpen(false);

    if (dontShowAgain) {
      setOrganizationOnboardingCompleted();
    }
  };

  return (
    <>
      {open && (
        <WelcomeV2
          onClose={handleOnClose}
          onLoadDataClick={handleLoadData}
        />
      )}
      {children}
    </>
  );
};
