import { SqlRbacAction } from "services/rbac/action";

export const engineActions = [
  SqlRbacAction.ENGINE_CREATE,
  SqlRbacAction.ENGINE_USAGE,
  SqlRbacAction.ENGINE_OPERATE,
  SqlRbacAction.ENGINE_MODIFY,

  SqlRbacAction.ENGINE_USAGE_ANY,
  SqlRbacAction.ENGINE_MODIFY_ANY,
  SqlRbacAction.ENGINE_OPERATE_ANY,
];

export const databaseActions = [
  SqlRbacAction.DATABASE_CREATE,
  SqlRbacAction.DATABASE_MODIFY,
  SqlRbacAction.DATABASE_USAGE,

  SqlRbacAction.DATABASE_USAGE_ANY,
  SqlRbacAction.DATABASE_MODIFY_ANY,
];
